import React from "react";
import * as Styled from "./styled";

const Tabs = ({ rows, courseType, setCourseType }) => {
  return (
    <Styled.Tabs>
      {rows.map((row) => (
        <Styled.Button
          key={row.id}
          className={courseType === row.id && "active"}
          onClick={() => {
            setCourseType(row.id);
          }}
        >
          {row.name}
        </Styled.Button>
      ))}
    </Styled.Tabs>
  );
};

export default Tabs;
