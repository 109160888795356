import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const MenuButton = styled.div`
  color: var(--color-text);
  margin-left: 14px;
  cursor: pointer;
`;

export const MenuPoupup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 30px;
  right: 30;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 10px;

  > a {
    padding: 8px 24px;
    font-family: var(--font-lexend-regular);
    color: var(--color-text);
    font-size: 0.8rem;
  }

  > a + a {
    border-top: 1px solid #ececec;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  margin: auto 120px;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    margin: auto 50px;
  }
`;
