import styled from "styled-components";

export const Content = styled.div`
  text-align: center;
`;

export const Error = styled.div`
  display: flex;
  margin: 10px;

  > div {
    color: var(--color-text);
    margin: 5px 0;
    white-space: normal;
    font-size: 12px;
    align-items: center;
  }

  img {
    margin-right: 12px;
  }
`;
