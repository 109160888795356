import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ListCard from "../../../Presentational/Cards/List";
import CardCourse from "../../../Presentational/Cards/Course";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import { getTrails } from "../../../../services/Graphql/Trails";
import { SHIMMER_SIZE } from "../../../../constants";
import { logAnalyticsEvent } from "../../../../services/Analytics";

const ContainerCourseListCardTrail = ({ screenName = "" }) => {
  let history = useHistory();
  const [loader, setLoader] = useState(true);
  const [trail, setTrail] = useState([]);

  const getTrail = useCallback(async () => {
    const response = await getTrails();

    if (!response.status) {
      setLoader(false);
      return;
    }

    setTrail(response.trails ?? []);
    setLoader(false);
  }, []);

  useEffect(() => {
    getTrail();
  }, [getTrail]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <ListCard>
      {trail.map((item, index) => (
        <CardCourse
          key={index}
          title={item.title}
          subTitle={`${item.courses.length} módulos`}
          image={item.imageUrl}
          onPlay={() => {
            logAnalyticsEvent("trilha_abrir", {
              trilha_id: item.id,
              trilha_nome: item.title,
              screen_name: screenName,
            });
            history.push(`/knowledgetrail/${item.id}`);
          }}
        />
      ))}
    </ListCard>
  );
};

export default ContainerCourseListCardTrail;
