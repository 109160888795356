import styled from "styled-components";
import { H3 } from "../../../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;

export const Content = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Button = styled.div`
  margin-top: 15px;
  text-align: center;
`;

export const Title = styled(H3)`
  margin-bottom: 20px;
  text-align: center;
`;

export const Section = styled.section`
  padding: 20px 0;
`;
