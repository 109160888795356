import styled from "styled-components";

export const Button = styled.button`
  height: 48px;
  width: 40%;
  align-self: center;
  border-radius: 24px;
  font-family: var(--font-lexend-medium);
  background-color: ${(props) => props.theme.color};
  color: #fff;
  margin: 16px 0;
  cursor: pointer;
  margin-left: 10px;
`;

export const Span = styled.span`
  font-family: var(--font-lexend-semi-bold);
  color: #ffffff;
  font-size: 0.8rem;
  padding-left: 5px;
`;
