import React, { useEffect } from "react";
import Layout from "../../layout/External";
import SplashScreen from "../../components/Presentational/SplashScreen";
import BannerLearning from "../../components/Presentational/Banner/Learning";
import BannerNewWayLearning from "../../components/Presentational/Banner/NewWayLearning";
import BannerLeverageCareer from "../../components/Presentational/Banner/LeverageCareer";
import BannerCategoryCourse from "../../components/Presentational/Banner/CategoryCourse";
import BannerDownloadApp from "../../components/Presentational/Banner/DownloadApp";
import { NATIVE_APP } from "../../constants";

const Home = () => {
  useEffect(() => {
    if (window.location.pathname === "/ativar") {
      window.location.href = `/#/ativar${window.location.search}`;
    }

    if (window.location.pathname === "/static/termsconditions") {
      window.location.href = "/#/static/termsconditions";
    }
  });

  if (NATIVE_APP) {
    return <SplashScreen />;
  }

  return (
    <Layout>
      <BannerLearning />
      <BannerNewWayLearning />
      <BannerLeverageCareer />
      <BannerCategoryCourse />
      <BannerDownloadApp />
    </Layout>
  );
};

export default Home;
