import React, { useState, useCallback } from "react";
import Layout from "../../../layout/Inner";
import Tabs from "../../../components/Presentational/Tabs";
import CoursesListCardCourseEnrolled from "../../../components/Containers/Courses/ListCardCourseEnrolled";
import CoursesListCardCourseSaved from "../../../components/Containers/Courses/ListCardCourseSaved";
import CoursesListCertificateCard from "../../../components/Containers/Courses/ListCertificateCard";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../services/Analytics";
import * as Styled from "./styled";

const MyCourses = () => {
  const [courseType, setCourseType] = useState(3);
  const [titleTabs] = useState([
    {
      id: 3,
      name: "Iniciados",
    },
    {
      id: 6,
      name: "Minha Lista",
    },
    {
      id: 9,
      name: "Finalizado",
    },
  ]);

  const coursesList = useCallback(() => {
    switch (courseType) {
      case 3:
        logAnalyticsEvent("CoursesStarted", {
          user_id: getParamsStorage("gaid"),
          skin: getParamsStorage("skin"),
        });

        return <CoursesListCardCourseEnrolled screenName="MyCourses" />;
      case 6:
        logAnalyticsEvent("CoursesAddedToMyList", {
          user_id: getParamsStorage("gaid"),
          skin: getParamsStorage("skin"),
        });

        return <CoursesListCardCourseSaved screenName="MyCourses" />;
      case 9:
        logAnalyticsEvent("CoursesAddedToMyList", {
          user_id: getParamsStorage("gaid"),
          skin: getParamsStorage("skin"),
        });

        return <CoursesListCertificateCard />;
      default:
        return;
    }
  }, [courseType]);

  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>Meus cursos</Styled.Title>
        <Tabs
          setCourseType={setCourseType}
          courseType={courseType}
          rows={titleTabs}
        />
        {coursesList()}
      </Styled.Container>
    </Layout>
  );
};

export default MyCourses;
