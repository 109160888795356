import React from "react";
import * as Styled from "./styled";
import reactStringReplace from "react-string-replace";
import { ICON_SUCCESS } from "../../../../images";

export default ({ message }) => {
  return (
    <Styled.Success>
      <Styled.Image src={ICON_SUCCESS} alt="success" />
      <Styled.Content>
        {reactStringReplace(message, /(https?:\/\/\S+)/g, (match, i) => (
          <Styled.A
            key={match + i}
            rel="noopener noreferrer"
            target="_blank"
            href={match}
          >
            aqui
          </Styled.A>
        ))}
      </Styled.Content>
    </Styled.Success>
  );
};
