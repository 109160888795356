import SETTINGS from "../settings";

/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SEND_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_URL = process.env.REACT_APP_FIREBASE_URL;

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV;
export const THEMES = SETTINGS;
export const PROJECT_URL = process.env.REACT_APP_PROJECT_URL;
export const NATIVE_APP = window.cordova;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const BRAND_ID = process.env.REACT_APP_BRAND_ID;
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME;
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;
export const URL_BACKEND_UPLOAD = process.env.REACT_APP_URL_BACKEND_UPLOAD;
export const TOKEN_BACKEND = process.env.REACT_APP_TOKEN_BACKEND;
export const URL_BACKEND_BFF = process.env.REACT_APP_URL_BACKEND_BFF;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const URL_FIREBASE = process.env.REACT_APP_URL_FIREBASE;
export const URL_FIREBASE_AUTH = process.env.REACT_APP_URL_FIREBASE_AUTH;
export const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE;
export const URL_AGREGADOR = process.env.REACT_APP_URL_AGREGADOR;
export const URL_CHAT = process.env.REACT_APP_URL_CHAT;
export const URL_CONTACT_US = process.env.REACT_APP_URL_CONTACT_US;
export const URL_DOWNLOAD_APP_ANDROID =
  process.env.REACT_APP_URL_DOWNLOAD_APP_ANDROID;
export const URL_DOWNLOAD_APP_IOS = process.env.REACT_APP_URL_DOWNLOAD_APP_IOS;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const URL_GRAPH_QL = process.env.REACT_APP_URL_GRAPH_QL;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
export const TERMS_OF_USE = process.env.REACT_APP_TERMS_OF_USE;
export const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY;
export const URL_CHATBOT = process.env.REACT_APP_URL_CHATBOT;
export const SHIMMER_SIZE = process.env.REACT_APP_SHIMMER_SIZE;
export const URL_CLOUD_FUNCTION = process.env.REACT_APP_URL_CLOUD_FUNCTION;
export const URL_CLOUD_STORAGE = process.env.REACT_APP_URL_CLOUD_STORAGE;
export const URL_VIMEO = process.env.REACT_APP_URL_VIMEO;
