import React, { useState } from "react";
import * as Styled from "./styled";
import Input from "../../../Presentational/Input";
import { ICON_VISIBILITY_ON, ICON_VISIBILITY_OFF } from "../../../../images";
import { TERMS_OF_USE } from "../../../../constants";
import { PRIVACY_POLICY } from "../../../../constants";

const FormSignUp = ({
  handleSubmitForm = () => { },
  handleChangeForm = () => { },
  onClickBackForm = () => { },
  title = "Olá!",
  subTitle = "Vamos criar sua conta!",
  email,
  password,
  firstName,
  lastName,
  confirmPassword,
  children,
  errorMessage,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const isValidInput = () => {
    if (
      email &&
      password &&
      firstName &&
      lastName &&
      confirmPassword &&
      termsAndCondition &&
      password === confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Styled.Form onSubmit={handleSubmitForm}>
      <Styled.Paragraph>
        {title} <br /> {subTitle}
      </Styled.Paragraph>
      <Input
        label="Seu nome"
        name="firstName"
        type="text"
        value={firstName}
        required={true}
        onChange={handleChangeForm}
      />
      <Input
        label="Seu sobrenome"
        name="lastName"
        type="text"
        value={lastName}
        required={true}
        onChange={handleChangeForm}
      />
      <Input
        label="Seu email"
        name="email"
        type="email"
        value={email}
        required={true}
        onChange={handleChangeForm}
      />
      <Input
        label="Sua senha"
        name="password"
        type={passwordVisibility ? "text" : "password"}
        value={password}
        required={true}
        onChange={handleChangeForm}
        rightIconAction={() => {
          passwordVisibility
            ? setPasswordVisibility(false)
            : setPasswordVisibility(true);
        }}
        rightIcon={
          passwordVisibility ? (
            <img src={ICON_VISIBILITY_OFF} alt="icon" />
          ) : (
            <img src={ICON_VISIBILITY_ON} alt="icon" />
          )
        }
      />
      <Input
        label="Confirme sua senha"
        name="confirmPassword"
        type={confirmPasswordVisibility ? "text" : "password"}
        value={confirmPassword}
        required={true}
        onChange={handleChangeForm}
        rightIconAction={() => {
          confirmPasswordVisibility
            ? setConfirmPasswordVisibility(false)
            : setConfirmPasswordVisibility(true);
        }}
        rightIcon={
          confirmPasswordVisibility ? (
            <img src={ICON_VISIBILITY_OFF} alt="icon" />
          ) : (
            <img src={ICON_VISIBILITY_ON} alt="icon" />
          )
        }
      />
      <div className="accept-terms">
        <div className="toggle-btn">
          <input
            type="checkbox"
            name="termsAndCondition"
            value={termsAndCondition}
            onChange={(e) => setTermsAndCondition(e.target.checked)}
          />
          <span></span>
        </div>
        &nbsp;
        <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer"><span>Li e aceito</span> os Termos de Uso</a>
        <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer"><span>&nbsp;e a </span> Política de Privacidade.</a>
      </div>
      <Styled.ContentButtons
        style={{
          border: errorMessage ? "solid 1px #dadce0" : "none",
        }}
      >
        {children}
        <button
          disabled={isValidInput() ? false : true}
          style={{ opacity: isValidInput() ? 1 : 0.5 }}
        >
          Criar conta
        </button>
        <p
          style={{
            alignSelf: "center",
            cursor: "pointer",
            fontFamily: `var(--font-lexend-medium)`,
            color: "var(--color-primary)",
            fontSize: 12,
          }}
          onClick={() => onClickBackForm()}
        >
          Já tem uma conta?
        </p>
      </Styled.ContentButtons>
    </Styled.Form>
  );
};

export default FormSignUp;
