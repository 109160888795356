import React, { useState, useCallback } from "react";
import Modal from "../../../Presentational/Modal";
import Form from "../../../Presentational/Forms/Template";
import ButtonPrimary from "../../../Presentational/Button/Default";
import ErrorMessage from "../../../Presentational/Alerts/Danger";
import Input from "../../../Presentational/Input";
import { sendPasswordResetEmail } from "../../../../services";
import { logAnalyticsEvent } from "../../../../services/Analytics";

const ModalForgotPassword = ({
  display = false,
  setDisplay = () => {},
  setLoading = () => {},
}) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const onCloseModal = () => {
    setDisplay(false);
    setEmail("");
    setSuccessMessage(false);
  };

  const sendResetPasswordMail = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setErrorMessage(false);
      setSuccessMessage(false);

      const response = await sendPasswordResetEmail(email);

      if (!response.status) {
        setLoading(false);
        setErrorMessage(response.message);
        return;
      }

      logAnalyticsEvent("LoginRequestRecoveryFeedback");
      setSuccessMessage(true);
      setLoading(false);
    },
    [email, setLoading]
  );

  return (
    <>
      {display && (
        <Modal isClose={onCloseModal}>
          {!successMessage && (
            <Form
              textPrimary="Redefina sua senha"
              textSecondary="Digite o email que usou para realizar seu cadastro. <br />Enviaremos um email para você redefinir sua senha."
              onSubmit={sendResetPasswordMail}
            >
              <Input
                label="Seu email"
                name="email"
                value={email}
                required={true}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage(false);
                }}
                autoFocus={true}
                containerStyle={{ margin: "16px 0" }}
              />
              <ErrorMessage
                errorMessage={errorMessage}
                successMessage={successMessage}
              >
                <ButtonPrimary>Redefinir senha</ButtonPrimary>
              </ErrorMessage>
            </Form>
          )}
          {successMessage && (
            <Form
              textPrimary="Email de redefinição de senha enviado"
              textSecondary={`Um email foi enviado para ${email}. <br />Siga as instruções para redefinir sua senha.`}
            >
              <ErrorMessage
                errorMessage={errorMessage}
                successMessage={successMessage}
              >
                <ButtonPrimary onClick={onCloseModal}>Continuar</ButtonPrimary>
              </ErrorMessage>
            </Form>
          )}
        </Modal>
      )}
    </>
  );
};

export default ModalForgotPassword;
