import styled from "styled-components";
import { P, H2 } from "../../../../styles";

export const Section = styled.section`
  padding: 40px 0 60px;
  max-width: 1000px;
  width: 100vw;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 20px 14px;
  }
`;

export const Header = styled.header`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #616161;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 28px;
    color: var(--color-text);
  }
`;

export const Content = styled.div`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: start;
    flex-wrap: wrap;

    li {
      width: 33.33%;
      margin-bottom: 24px;
      padding-right: 32px;

      img {
        margin-bottom: 16px;
      }
    }
  }

  section {
    display: grid;
    gap: 26px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin-top: 3em;
    width: 100%;
  }
`;

export const Paragraph = styled(P)`
  font-size: 0.9rem;
  font-family: var(--font-lexend-medium);
`;

export const Title = styled(H2)``;
