import { makeRequest } from "../../../services/Graphql";
import { GET_TRAILS, GET_TRAILS_BY_ID } from "./queries";

export async function getTrails() {
  const response = await makeRequest(GET_TRAILS);
  console.log("[success-getTrails]: ", response.trails);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    trails: response.trails,
  };
}

export async function getTrailsById(trailsId) {
  const response = await makeRequest(GET_TRAILS_BY_ID, { trailsId });
  console.log("[success-getTrailsById]: ", response.trails);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    trails: response.trails,
  };
}
