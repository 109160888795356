import React, { useEffect, useState, useCallback } from "react";
import { getNewAchievements } from "../../../../services/Graphql/Achievements";
import ModalTemplate from "../../../Presentational/Modal";
import ListCard from "../../../Presentational/Cards/List";
import CardAchievements from "../../../Presentational/Cards/Achievements";
import ButtonPrimary from "../../../Presentational/Button/Primary";
import * as Styled from "./styled";

const NewAchievements = () => {
  const [display, setDisplay] = useState(false);
  const [achievements, setAchievements] = useState([]);

  const getAllAchievements = useCallback(async () => {
    let getAchievementsResponse = await getNewAchievements();

    if (!getAchievementsResponse.status) {
      return;
    }

    if (getAchievementsResponse.newAchievements.length > 0) {
      setDisplay(true);
      setAchievements(getAchievementsResponse.newAchievements);
    }
  }, []);

  useEffect(() => {
    getAllAchievements();
  }, [getAllAchievements]);

  return (
    <>
      {display && (
        <ModalTemplate isClose={() => window.location.reload()}>
          <Styled.Container>
            <Styled.Section>
              <Styled.Title>
                Parabéns! <br />
                Você acabou de <br /> ganhar uma conquista!
              </Styled.Title>
            </Styled.Section>
            <ListCard>
              {achievements.map((item, index) => (
                <CardAchievements
                  key={index}
                  imageUrl={item.imageUrl}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </ListCard>
            <Styled.Button>
              <ButtonPrimary onClick={() => window.location.reload()}>
                Continuar
              </ButtonPrimary>
            </Styled.Button>
          </Styled.Container>
        </ModalTemplate>
      )}
    </>
  );
};

export default NewAchievements;
