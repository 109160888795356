import styled from "styled-components";

export const Success = styled.div`
  color: var(--color-text);
  font-family: var(--font-lexend-medium);
  display: flex;
  margin: 5px 0;
  white-space: normal;
  font-size: 12px;
  align-items: center;
`;

export const Image = styled.img`
  margin-right: 12px;
  width: 25px;
`;

export const Content = styled.div``;

export const A = styled.a``;
