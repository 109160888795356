import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { validateToken } from "../../../../utils";
import { logAnalyticsEvent } from "../../../../services/Analytics";

const ModalLogin = ({
  title = "Já sou cliente",
  style = { alignSelf: "center" },
}) => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (validateToken()) {
      setIsLoggedIn(true);
    }
  }, []);

  const onStartLoggedIn = (e) => {
    e.preventDefault();
    logAnalyticsEvent("fluxo_cliente_entrar_inicio", {
      screen_name: "Login",
    });
    isLoggedIn ? history.push("/dashboard") : history.push("/signin");
  };

  return (
    <>
      <Link to="#" onClick={onStartLoggedIn} style={style}>
        {title}
      </Link>
    </>
  );
};

export default ModalLogin;
