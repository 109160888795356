import React from "react";
import PinInput from "react-pin-input";
import "./styles.css";

const InputPincode = ({
  length = 6,
  value = "",
  name = "pincode",
  secret = false,
  onChange = () => {},
  onComplete = () => {},
  autoSelect = true,
}) => {
  return (
    <PinInput
      length={length}
      initialValue={value}
      secret={secret}
      name={name}
      onChange={onChange}
      type={"numeric"}
      inputMode="number"
      inputStyle={{ borderColor: "#dadce0" }}
      inputFocusStyle={{ borderColor: "var(--color-primary)" }}
      onComplete={onComplete}
      autoSelect={autoSelect}
      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
    />
  );
};

export default InputPincode;
