import styled from "styled-components";

export const Chapters = styled.ul`
  display: flex;
  flex-direction: column;

  .icon-active {
    color: ${(props) => props.theme.color};
  }
`;

export const List = styled.li`
  align-items: center;
  background-color: hsla(0, 0%, 94%, 1);
  border-radius: 0 !important;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  display: flex;
  font-family: var(--font-lexend-regular, Verdana, Geneva, Tahoma);
  padding: 20px;
`;

export const Description = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 10px;
`;

export const Info = styled.div`
  margin: 0 30px;
  p {
    font-size: 0.9rem;
    margin-bottom: -2px;
  }
  small {
    font-size: 0.7rem;
  }
`;

export const Status = styled.span`
  font-size: 0.8rem;
  font-family: var(--font-lexend-semi-bold, Verdana, Geneva, Tahoma);
  color: ${(props) => props.theme.color};
`;
