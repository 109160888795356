import React from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
    borderRadius: 10,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 10,
    width: "100%",
    minHeight: 160,
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  circle: {
    height: 45,
    width: 200,
    borderRadius: 24,
    margin: "13px 54px 12px 55px",
  },
  cardLine: {
    width: 250,
    height: 15,
    alignSelf: "center",
    marginBottom: 10,
    borderRadius: 8,
  },
  line: {
    width: "100%",
    height: 15,
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 8,
  },
  image: {
    width: "100%",
    height: 100,
    alignSelf: "center",
    borderRadius: 8,
  },
};

const ShimmerEffect = ({ classes, size = 1, type }) => {
  const shimmerCard = (row) => {
    return (
      <div className={classes.container} key={row}>
        <div className={classes.content}>
          <Shimmer>
            <div className={classes.cardLine} />
            <div className={classes.cardLine} />
            <div className={classes.circle} />
          </Shimmer>
        </div>
      </div>
    );
  };

  const shimmerListLine = (row) => {
    return (
      <div key={row}>
        <Shimmer>
          <div className={classes.line} />
        </Shimmer>
      </div>
    );
  };

  const shimmerListImage = (row) => {
    return (
      <div key={row}>
        <Shimmer>
          <div className={classes.image} />
        </Shimmer>
      </div>
    );
  };

  let rows = [];
  for (let i = 0; i < size; i++) {
    rows.push(i);
  }

  switch (type) {
    case "image":
      return rows.map(shimmerListImage);

    case "line":
      return rows.map(shimmerListLine);

    default:
      return (
        <div
          style={{
            display: "grid",
            gridGap: 24,
            gap: 24,
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            marginBottom: 40,
          }}
        >
          {rows.map(shimmerCard)}
        </div>
      );
  }
};

export default injectSheet(StyleSheet)(ShimmerEffect);
