import styled from "styled-components";

export const P = styled.p`
  font-size: 14px;
  color: var(--color-text);
  font-family: var(--font-lexend-light);
`;

export const PBold = styled.p`
  font-size: 14px;
  color: var(--color-text);
  font-family: var(--font-lexend-bold);
`;

export const H1 = styled.h1`
  font-size: 2rem;
  color: var(--color-text);
  font-family: var(--font-lexend-semi-bold);
`;

export const H2 = styled.h2`
  font-size: 1.5rem;
  color: var(--color-text);
  font-family: var(--font-lexend-semi-bold);
`;

export const H3 = styled.h3`
  font-size: 1.2rem;
  color: var(--color-text);
  font-family: var(--font-lexend-semi-bold);
`;

export const Section = styled.section`
  padding: 30px;
`;

export const ContainerStyle = styled.div`
  padding: 30px;
  border-left: 1px solid var(--color-line-division, hsla(0, 3%, 90%, 1));
  border-right: 1px solid var(--color-line-division, hsla(0, 3%, 90%, 1));

  @media screen and (max-width: 768px) {
    border: none;
    padding: 0px;
  }
`;

export const ContentStyle = styled.div`
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 160px;
    bottom: 60px;
    overflow: auto;
    padding: 30px;
  }
`;

export const HeaderStyle = styled.div`
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 80px;
    padding: 30px 0 0 30px;
  }
`;
