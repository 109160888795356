import styled from "styled-components";

export const Button = styled.button`
  color: ${(props) => (props.color ? "var(--color-primary)" : "#ffffff")};
  padding: ${(props) => (props.size ? "7px 24px 9px" : "13px 54px 12px 55px")};
  width: ${(props) => (props.width ? "100%" : "")};
  margin: 10px 0;
  border-radius: 24px;
  background-color: ${(props) =>
    props.background ? "#ffffff" : "var(--color-primary)"};
  border: ${(props) =>
    props.border ? "none" : "solid 2px var(--color-primary)"};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: var(--color-primary);
    color: #ffffff;
    border: solid 2px var(--color-primary);
  }
`;
