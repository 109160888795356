import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { translateMessage } from "../../../../utils/TranslateMessage";
import Loader from "../../../Presentational/Loader";
import FormSignUp from "../../../Presentational/Forms/SignUp";
import FormSendPincode from "../../../Presentational/Forms/Signin/Phone/SendPincode";
import FormValidatePincode from "../../../Presentational/Forms/Signin/Phone/ValidatePincode";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import { saveSessionTokens, validatorPhoneNumber } from "../../../../utils";
import { getSignature } from "../../../../services/Signature";
import {
  createUserProfile,
  getUserProfile,
} from "../../../../services/Graphql/User";
import { createUserWithEmailAndPassword } from "../../../../services/Firebase";
import {
  logAnalyticsEvent,
  logLetsCooeeEvent,
} from "../../../../services/Analytics";

const DISPLAY = {
  sendPincode: true,
  validatePincode: false,
  signUp: false,
};

const GATEWAY = "px";
const TITLE = "Legal!";
const SUBTITLE = "Para finalizar, vamos criar sua conta!";

const AuthPhoneContainer = ({ onClickBackForm = () => {} }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [displayForm, setDisplayForm] = useState(DISPLAY);
  const [errorMessage, setErrorMessage] = useState("");

  const setUpRecaptcha = () => {
    setTimeout(async () => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }, 1000);
  };

  const getUserSignature = async (
    token,
    refreshToken,
    isFirstLogin,
    gateway = GATEWAY
  ) => {
    let response = await getSignature(token, gateway);

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    if (isFirstLogin) {
      setDisplayForm((data) => ({
        ...data,
        validatePincode: false,
        signUp: true,
      }));

      setLoader(false);
    }

    const userProfileResponse = await getUserProfile(token);

    if (!userProfileResponse.status) {
      setLoader(false);
      setErrorMessage(userProfileResponse.message);
      return;
    }

    localStorage.setItem("gaid", userProfileResponse.profile[0].id);
    localStorage.setItem(
      "skin",
      userProfileResponse.profile[0].campaign.skin.title
    );
    localStorage.setItem(
      "color",
      userProfileResponse.profile[0].campaign.skin.color
    );
    localStorage.setItem(
      "logo",
      userProfileResponse.profile[0].campaign.skin.logoUrl
    );
    localStorage.setItem(
      "transparentLogoUrl",
      userProfileResponse.profile[0].campaign.skin.transparentLogoUrl
    );

    saveSessionTokens(token, refreshToken);

    logAnalyticsEvent("fluxo_cliente_entrar_msisdn", {
      screen_name: "LoginTelephoneNumber",
      user_id: formData.phone,
    });

    logLetsCooeeEvent([
      "Log In",
      {
        item: {
          id: formData.phone,
          msisdn: formData.phone,
        },
      },
    ]);

    history.push("/dashboard");
    return;
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmitFormSendPincode = (e) => {
    e.preventDefault();
    setLoader(true);

    const validator = validatorPhoneNumber(formData.phone);

    if (!validator.status) {
      setLoader(false);
      setErrorMessage(validator.message);
      return;
    }

    firebase
      .auth()
      .signInWithPhoneNumber(`${validator.phone}`, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        setDisplayForm((data) => ({
          ...data,
          sendPincode: false,
          validatePincode: true,
        }));

        setFormData((data) => ({
          ...data,
          phone: validator.phone,
        }));

        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setErrorMessage(translateMessage(error.message));
        return;
      });
  };

  const handleChangeFormValidatePincode = (value) => {
    setErrorMessage("");
    setFormData((data) => ({
      ...data,
      pincode: value,
    }));
  };

  const handleSubmitFormValidatePincode = async (e) => {
    e.preventDefault();
    setLoader(true);

    window.confirmationResult
      .confirm(formData.pincode)
      .then((result) => {
        getUserSignature(
          result.user.ya,
          result.user.refreshToken,
          result.additionalUserInfo.isNewUser
        );
      })
      .catch((error) => {
        setLoader(false);
        setErrorMessage(translateMessage(error.message));
        return;
      });
  };

  const handleSubmitFormSignUp = async (e) => {
    e.preventDefault();
    setLoader(true);

    const createUserResponse = await createUserWithEmailAndPassword(
      formData.email,
      formData.password
    );

    if (!createUserResponse.status) {
      setLoader(false);
      setErrorMessage(createUserResponse.message);
      return;
    }

    saveSessionTokens(
      createUserResponse.sessionToken,
      createUserResponse.refreshToken
    );

    const createProfileResponse = await createUserProfile(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.phone,
      "ENSINA-BETA",
      "ensina-beta"
    );

    if (!createProfileResponse.status) {
      setLoader(false);
      setErrorMessage(createProfileResponse.message);
      return;
    }

    history.push("/dashboard");
  };

  const handleClickFormBack = () => {
    setErrorMessage("");
    setDisplayForm((data) => ({
      ...data,
      sendPincode: true,
      validatePincode: false,
    }));
    setFormData((data) => ({
      ...data,
      phone: "",
      pincode: "",
    }));

    setUpRecaptcha();
  };

  useEffect(() => {
    setUpRecaptcha();
  }, []);

  useEffect(() => {
    logAnalyticsEvent("fluxo_cliente_entrar_msisdn", {
      screen_name: "LoginTelephoneNumber",
    });
  }, []);

  return (
    <>
      <Loader display={loader} />
      {displayForm.sendPincode && (
        <FormSendPincode
          phone={formData.phone}
          handleChangeForm={handleChangeForm}
          handleSubmitForm={handleSubmitFormSendPincode}
          errorMessage={errorMessage}
          onClickBackForm={onClickBackForm}
        >
          <div id="recaptcha-container"></div>
          <ErrorMessage
            display={!!errorMessage}
            onCloseErrorMessage={() => setErrorMessage(null)}
            message={errorMessage}
          />
        </FormSendPincode>
      )}

      {displayForm.validatePincode && (
        <FormValidatePincode
          phone={formData.phone}
          pincode={formData.pincode}
          handleClickFormBack={handleClickFormBack}
          handleChangeForm={handleChangeFormValidatePincode}
          handleSubmitForm={handleSubmitFormValidatePincode}
          errorMessage={errorMessage}
        >
          <ErrorMessage
            display={!!errorMessage}
            onCloseErrorMessage={() => setErrorMessage(null)}
            message={errorMessage}
          />
        </FormValidatePincode>
      )}

      {displayForm.signUp && (
        <FormSignUp
          title={TITLE}
          subTitle={SUBTITLE}
          phone={formData.phone}
          pincode={formData.pincode}
          email={formData.email}
          password={formData.password}
          firstName={formData.firstName}
          lastName={formData.lastName}
          confirmPassword={formData.confirmPassword}
          handleChangeForm={handleChangeForm}
          handleSubmitForm={handleSubmitFormSignUp}
          errorMessage={errorMessage}
        >
          <ErrorMessage
            display={!!errorMessage}
            onCloseErrorMessage={() => setErrorMessage(null)}
            message={errorMessage}
          />
        </FormSignUp>
      )}
    </>
  );
};

export default AuthPhoneContainer;
