import React from "react";
import NumberFormat from "react-number-format";

const header = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "2rem",
};

const p = {
  alignItems: "center",
  color: "hsla(0, 0%, 30%, 1)",
  display: "flex",
  fontSize: ".9rem",
  flexDirection: "column",
  justifyContent: "center",
  letterSpacing: ".06rem",
  marginTop: ".4rem",
  textAlign: "center",
};

export default (props) => {
  return (
    <div>
      <header style={header}>
        <p style={p}>
          Você está bem perto de ativar seu <strong>ENSINA by FS</strong>
        </p>
      </header>
      <form className="frm-pin" onSubmit={props.handleSubmit}>
        <div className="inputs">
          <NumberFormat
            value={props.pincode}
            onChange={props.handleChange}
            name="pincode"
            id="codigo"
            placeholder="*Digite o código recebido"
            required
            format="######"
            mask=""
            autoFocus={true}
          />
        </div>
        <input type="submit" className="anime" value="Ativar" />
      </form>
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <p
          style={{
            fontSize: 12,
            textAlign: "center",
            textDecoration: "underline",
            cursor: "pointer",
            color: "rgba(105,105,105,1)",
          }}
          onClick={props.handleResendPincode}
        >
          Reenviar código
        </p>
      </div>
    </div>
  );
};
