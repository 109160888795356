import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0%,
  80%,
  100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }

  40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  margin: 30px auto 0;
  width: 70px;
  text-align: center;
`;

export const Content = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  display: inline-block;
  animation: ${animation} 1.4s infinite ease-in-out both;
`;
