import React, { useState } from "react";
import ModalTemplate from "../../../Presentational/Modal";
import ButtonPrimary from "../../../Presentational/Button/Primary";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import * as Styled from "./styled";

const ConfirmationModal = ({
  display = false,
  setDisplay = () => {},
  message = "",
  confirmText = "",
  cancelText = "",
  confirmAction = () => {},
  cancelAction = () => {},
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <>
      {display && (
        <ModalTemplate isClose={() => setDisplay(false)}>
          <Styled.Container>
            <Styled.Content>
              <p>{message}</p>
            </Styled.Content>
            <ErrorMessage
              display={!!errorMessage}
              onCloseErrorMessage={() => setErrorMessage(null)}
              message={errorMessage}
            />
            <ButtonPrimary onClick={confirmAction}>{confirmText}</ButtonPrimary>
            <Styled.Button onClick={cancelAction}>{cancelText}</Styled.Button>
          </Styled.Container>
        </ModalTemplate>
      )}
    </>
  );
};

export default ConfirmationModal;
