import { gql } from "graphql-request";

export const GET_COURSES = gql`
  query ($limit: Int!, $skip: Int!) {
    courses(limit: $limit, skip: $skip) {
      id
      title
      imageUrl
      duration
      categories {
        id
        title
      }
    }
  }
`;

export const GET_COURSES_BY_TITLE = gql`
  query ($title: String!) {
    courses(title: $title) {
      id
      title
      imageUrl
      duration
    }
  }
`;

export const GET_COURSES_BY_CATEGORIES = gql`
  query ($categories: [ID!]) {
    courses(categories: $categories) {
      id
      title
      imageUrl
      duration
      categories {
        id
        title
      }
    }
  }
`;

export const GET_COURSES_BY_ID = gql`
  query ($id: ID) {
    courses(id: $id) {
      id
      title
      description
      imageUrl
      duration
      chapters {
        id
        index
        title
        lessons {
          id
          index
          title
          content
          duration
          url
        }
        homeworks {
          id
          index
          title
          questions {
            explanation
            answer
            options
          }
        }
      }
    }
  }
`;
