import React, { useEffect, useState, useCallback } from "react";
import ListCard from "../../../Presentational/Cards/List";
import CategoryCard from "../../../Presentational/Cards/Category";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import { getCategories } from "../../../../services/Graphql/Categories";
import { SHIMMER_SIZE } from "../../../../constants";

const ContainerCourseListCardCategories = () => {
  const [loader, setLoader] = useState(true);
  const [categories, setCategories] = useState([]);

  const getCategoriesInfo = useCallback(async () => {
    const response = await getCategories(true);

    if (!response.status) {
      setLoader(false);
      return;
    }

    setCategories(response.categories ?? []);
    setLoader(false);
  }, []);

  useEffect(() => {
    getCategoriesInfo();
  }, [getCategoriesInfo]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <ListCard>
      {categories.map((item, index) => (
        <CategoryCard
          key={index}
          categoryId={item.id}
          name={item.title}
          image={item.imageUrl}
        />
      ))}
    </ListCard>
  );
};

export default ContainerCourseListCardCategories;
