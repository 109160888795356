import styled from "styled-components";
import { BANNER_3 } from "../../../../images";
import { H1, P } from "../../../../styles";

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  padding: 30px 0px 30px;
  align-items: center;
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.4)
    ),
    url(${BANNER_3});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 600px;
  height: 350px;
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 20px 14px;
  }
`;

export const Title = styled(H1)`
  color: #fff;
`;

export const Paragraph = styled(P)`
  color: #fff;
  margin: 30px 0;
`;
