import React from "react";
import * as Styled from "./styled";
import Loader from "../../components/Presentational/Loader";
import Header from "../../components/Presentational/Header/External";
import Footer from "../../components/Presentational/Footer";
import Chatbot from "../../components/Presentational/Chatbot";
import { NATIVE_APP } from "../../constants";



export default ({ children, loader }) => {
  const url = window.location.href;
  const verifyParams = url.split('?').includes('show-bot');
  
  if (verifyParams) {
    console.log('chat bot click');
    setTimeout(() => document.getElementById('chat-image').click(), 60);
  }
  return (
    <Styled.Main>
      <Loader display={loader} />
      <Header />
      {children}
      {!NATIVE_APP && <Footer />}
      {!NATIVE_APP && <Chatbot />}
    </Styled.Main>
  );
};
