/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  saveFavoriteUserCourse,
  removeFavoriteUserCourse,
  getUserCourseFavoritesByCourse,
} from "../../../../services/Graphql/Profile";
import Spinner from "../../../Presentational/Spinner";
import { logAnalyticsEvent } from "../../../../services/Analytics";
import * as Styled from "./styled";

const ButtonFavorite = ({ courseId, screenName = "" }) => {
  const [loader, setLoader] = useState(true);
  const [isFavoriteCourse, setIsFavoriteCourse] = useState(false);

  const getUserCourseFavorites = async (courseId) => {
    const response = await getUserCourseFavoritesByCourse(courseId);

    if (!response.status) {
      return;
    }

    setIsFavoriteCourse(response.isFavoriteCourse);
    setLoader(false);
  };

  const saveCourse = async (courseId) => {
    const response = await saveFavoriteUserCourse(courseId);

    if (!response.status) {
      return;
    }

    logAnalyticsEvent("curso_adicionar_lista", {
      categoria_id: "",
      categoria_nome: "",
      curso_id: courseId,
      curso_nome: "",
      screen_name: screenName,
    });

    setIsFavoriteCourse(true);
  };

  const removeCourseFavorite = async (courseId) => {
    const response = await removeFavoriteUserCourse(courseId);

    if (!response.status) {
      return;
    }

    logAnalyticsEvent("curso_remover_lista", {
      categoria_id: "",
      categoria_nome: "",
      curso_id: courseId,
      curso_nome: "",
      screen_name: screenName,
    });

    setIsFavoriteCourse(false);
  };

  const onClick = (courseId) => {
    if (isFavoriteCourse) {
      removeCourseFavorite(courseId);
      return;
    }

    saveCourse(courseId);
  };

  useEffect(() => {
    if (courseId.length > 0) {
      getUserCourseFavorites(courseId);
    }
  }, [courseId]);

  if (loader) {
    return <Spinner />;
  }

  return (
    <Styled.Button
      className={isFavoriteCourse ? "active" : ""}
      onClick={() => onClick(courseId)}
    >
      <i className="fa fa-list" />
      <span>{!isFavoriteCourse ? "Minha Lista" : "Remover"} </span>
    </Styled.Button>
  );
};

export default ButtonFavorite;
