import React from "react";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../../../utils/WindowDimensions";
import { URL_VIMEO } from "../../../../constants";

const VimeoPlayer = React.forwardRef((props, ref) => {
  const url = props.videoRef.replace("-", "?h=");
  const vpc = localStorage.getItem("play");
  const isMobile = useWindowDimensions();

  return (
    <ReactPlayer
      ref={ref}
      width={isMobile ? "100%" : "640px"}
      url={`${URL_VIMEO}/video/${url}`}
      controls={true}
      playing={vpc === "true" ? true : false}
      config={{
        vimeo: {
          playerOptions: {
            byline: false,
            portrait: false,
            title: false,
          },
        },
      }}
      onReady={props.onReady}
      onStart={props.onStart}
      onPlay={props.onPlay}
      onProgress={props.onProgress}
      onPause={props.onPause}
      onEnded={() => {
        props.onEndedChapterVideo(
          localStorage.getItem("courseId"),
          localStorage.getItem("chapterId"),
          localStorage.getItem("lessonId")
        );
      }}
    />
  );
});

export default VimeoPlayer;
