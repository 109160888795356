/**
 * Function Validator Chapters
 * @param {int} value
 */
export function validatorChapters(userProgressChapters, chapterData) {
  const [userProgress] = userProgressChapters.filter(
    (user) => user.id === chapterData.id
  );

  if (!!userProgress) {
    const lessonStatus =
      userProgress.lessons.filter((item) => item.finished).length ===
      chapterData.lessons.length;

    const homeworkStatus =
      userProgress.homeworks.filter((item) => item.finished).length ===
      chapterData.homeworks.length;
    return lessonStatus && homeworkStatus
      ? {
          backgroundColor: "#555EC0",
          color: "#FFFFFF",
        }
      : null;
  }
}

/**
 * Function Validator Chapters
 * @param {obj} userProgressChapters
 * @param {obj} lesson
 * @returns
 */
export function validatorLesson(userProgressChapters, lesson) {
  const userProgressLesson = userProgressChapters
    .map(({ lessons }) => lessons)
    .flat()
    .filter(({ finished }) => finished)
    .filter(({ id }) => id === lesson.id);

  return userProgressLesson.length > 0;
}

/**
 * Function Validator Homeworks
 * @param {obj} userProgressChapters
 * @param {obj} homework
 * @returns
 */
export function validatorHomework(userProgressChapters, homework) {
  const userProgressLesson = userProgressChapters
    .map(({ homeworks }) => homeworks)
    .flat()
    .filter(({ finished }) => finished)
    .filter(({ id }) => id === homework.id);

  return userProgressLesson.length > 0;
}
