import styled from "styled-components";
import { P } from "../../../../styles";

export const Badges = styled.div`
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 6px;
  color: #717171;
  display: flex;
  padding: 20px;
`;

export const Image = styled.img`
  width: 50px;
`;

export const Content = styled.div`
  margin: 0 20px;
`;

export const Paragraph = styled(P)`
  font-size: 0.9rem;
  margin-bottom: -2px;
`;

export const Small = styled.small`
  font-size: 0.7rem;
`;
