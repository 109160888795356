import React from "react";
import NumberFormat from "react-number-format";

const header = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "2rem",
};

const p = {
  alignItems: "center",
  color: "hsla(0, 0%, 30%, 1)",
  display: "flex",
  fontSize: ".9rem",
  flexDirection: "column",
  justifyContent: "center",
  letterSpacing: ".06rem",
  marginTop: ".4rem",
  textAlign: "center",
};

export default (props) => {
  return (
    <div>
      <header style={header}>
        <p style={p}>
          Você está bem perto de ativar seu <strong>ENSINA by FS</strong>
        </p>
      </header>
      <form className="frm-degustacao" onSubmit={props.handleSubmit}>
        <div className="inputs">
          <NumberFormat
            className="input-send-pincode"
            value={props.phone}
            onChange={props.handleChange}
            type="text"
            name="phone"
            placeholder="*Digite o seu celular"
            required
            format="(##) #####-####"
            mask="_"
            autoFocus={true}
          />
          <input
            className="input-send-pincode"
            value={props.serial}
            onChange={props.handleChange}
            name="serial"
            id="codigo"
            placeholder="*Digite o seu código de ativação"
            required
          />
        </div>
        <input type="submit" className="anime" value="Validar" />
      </form>
    </div>
  );
};
