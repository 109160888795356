import styled from "styled-components";
import { Section } from "../../../styles";

export const Container = styled(Section)`
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Video = styled.div`
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    background-color: #000000;
  }
`;

export const Description = styled.div`
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Buttons = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row-reverse;

  a {
    display: flex;
    color: #ffffff;
    align-items: center;
  }
`;

export const H1 = styled.h1`
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  font-family: var(
    --font-lexend-semi-bold,
    Verdana,
    Geneva,
    Tahoma,
    sans-serif
  );
  font-size: 1.2rem;
`;

export const Paragraph = styled.p`
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;

export const Strong = styled.p`
  font-family: var(
    --font-lexend-semi-bold,
    Verdana,
    Geneva,
    Tahoma,
    sans-serif
  );
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;

export const Small = styled.small`
  font-size: 0.7rem;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    display: block;
    align-items: center;
    margin-top: 30px;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  box-shadow: 6px 6px 16px 0 hsl(0deg 0% 0% / 10%);
  text-align: center;
  padding: 15px 0;
  flex: 1 1;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const ItemBox = styled.div`
  margin: 0 30px;
`;

export const ChapterContainer = styled.div`
  @media screen and (max-width: 768px) {
    display: block;
    margin: 15px;
  }
`;

export const ChapterHeader = styled.div`
  margin: 20px auto;
`;

export const DescriptionMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin: 15px;
  }
`;

export const ContentMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
    margin: 15px;
  }
`;
