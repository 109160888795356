import React from "react";
import { Link } from "react-router-dom";
import StringReplace from 'react-string-replace';
import Layout from "../../layout/External";
import Collapsible from "react-collapsible";
import FAQ from "./faq.json";
import "./styles.css";

const Help = () => {
  const renderHelp = () =>
    FAQ.map((response) => (
      <Collapsible
        triggerTagName="li"
        triggerClassName="inactive-accordion"
        triggerOpenedClassName="active-accordion"
        trigger={response.question}
      >
        <div className="info-container" style={{ whiteSpace: "pre-line" }}>
          <p>
            {StringReplace(
              response.answer,
              /(https?:\/\/\S+)/g,
              (match, i) => (
                <a key={match + i} rel="noopener noreferrer" target='_blank' href={match}>
                  clique aqui
                </a>
              )
            )}
          </p>
        </div>
      </Collapsible>
    ));

  const chatBot = () => {
    setTimeout(() => document.getElementById("chat-image").click(), 60);
  };

  return (
    <Layout>
      <section className="box-help center">
        <header
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 120,
          }}
        >
          <h2>
            <strong>Aprenda no seu tempo!</strong>
          </h2>
          <br />
          <p>
            Com Ensina by FS você consegue aprender tudo sobre o universo
            financeiro de forma rápida e prática, com os melhores professores do
            mercado, em qualquer lugar e no seu tempo livre!{" "}
          </p>
          <br />
          <h1>
            <strong>Perguntas Frequentes</strong>
          </h1>
        </header>
        <ul className="list-help">{renderHelp()}</ul>

        <div style={{ marginTop: "50px" }}>
          <h1>
            Caso não ache o que está procurando, entre em contato com a gente.
          </h1>
        </div>

        <section className="contact-container">
          <header>
            <h1>
              <Link to="#" rel="noopener noreferrer" onClick={() => chatBot()}>
                OBTER AJUDA NO CHAT DO SUPORTE
              </Link>
            </h1>
          </header>
        </section>
      </section>
    </Layout>
  );
};

export default Help;
