/**
 * Function Authorize Token
 */
export const isAuthorizated = () => {
  if (
    localStorage.getItem("ensina:sessionToken") === null ||
    localStorage.getItem("ensina:sessionToken") === "undefined"
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Function Create Token
 * @param {obj}     sessionToken
 * @param {obj}     refreshToken
 */
export function saveSessionTokens(sessionToken, refreshToken) {
  localStorage.setItem("ensina:sessionToken", sessionToken);
  localStorage.setItem("ensina:refreshToken", refreshToken);
}

/**
 * Function Validate Token
 */
export function validateToken() {
  const token = localStorage.getItem("ensina:sessionToken");
  return token;
}

/**
 * Function Remove Token
 */
export function removeToken() {
  localStorage.removeItem("ensina:sessionToken");
  localStorage.removeItem("ensina:refreshToken");
  localStorage.removeItem("color");
  localStorage.removeItem("logo");
  localStorage.removeItem("transparentLogoUrl");
  localStorage.removeItem("gaid");
  localStorage.removeItem("skin");
  localStorage.removeItem("courseId");
  localStorage.removeItem("videoRef");
  localStorage.removeItem("chapterId");
  localStorage.removeItem("lessonId");
  localStorage.removeItem("play");
  localStorage.removeItem("nextVideoIndex");
  localStorage.removeItem("isGuest");
}
