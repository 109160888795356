import React from "react";
import Collapsible from "react-collapsible";
import * as Styled from "./styled";
import {
  maskToTime,
  validatorChapters,
  validatorLesson,
  validatorHomework,
  parseSplitUrl,
} from "../../../../utils";

const Chapters = ({
  chapters,
  userProgressChapters,
  chapterId,
  courseId,
  videoRef,
  onClickLesson,
  onClickHomeWork,
}) => {
  return (
    <>
      {chapters.map((chapter, chapterDisplayOrder) => (
        <React.Fragment key={chapterDisplayOrder}>
          <Collapsible
            triggerStyle={validatorChapters(userProgressChapters, chapter)}
            triggerTagName="li"
            triggerClassName="inactive-accordion"
            triggerOpenedClassName="active-accordion"
            trigger={chapter.title}
            open={chapterId === chapter.id}
            className="accordion-list"
          >
            <Styled.Chapters>
              {chapter.lessons.map((lesson) => {
                return (
                  <Styled.List
                    key={lesson.id}
                    onClick={() => onClickLesson(lesson, chapter, courseId)}
                  >
                    <Styled.Description>
                      {validatorLesson(userProgressChapters, lesson) ? (
                        <i className="fa fa-check-circle fa-2x icon-active" />
                      ) : (
                        <i
                          className={
                            videoRef === parseSplitUrl(lesson.url)
                              ? `fa fa-pause-circle fa-2x icon-active`
                              : "fa fa-play-circle fa-2x"
                          }
                        />
                      )}
                      <Styled.Info>
                        <p>{lesson.title}</p>
                        <small>Video - {maskToTime(lesson.duration)}</small>
                      </Styled.Info>
                    </Styled.Description>
                    {videoRef === parseSplitUrl(lesson.url) ? (
                      <Styled.Status>Em andamento</Styled.Status>
                    ) : null}
                  </Styled.List>
                );
              })}

              {chapter.homeworks.map((homework) => {
                return (
                  <Styled.List
                    key={homework.id}
                    onClick={() => onClickHomeWork(homework.id, chapter.id)}
                  >
                    <Styled.Description>
                      {validatorHomework(userProgressChapters, homework) ? (
                        <i className="fa fa-check-circle fa-2x icon-active" />
                      ) : (
                        <i className={"fa fa-pencil-alt fa-2x"} />
                      )}
                      <Styled.Info>
                        <p>{homework.title}</p>
                        <small>Exercício</small>
                      </Styled.Info>
                    </Styled.Description>
                  </Styled.List>
                );
              })}
            </Styled.Chapters>
          </Collapsible>
        </React.Fragment>
      ))}
    </>
  );
};

export default Chapters;
