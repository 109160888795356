import React from "react";
import Layout from "../../layout/External";
import Input from "../../components/Presentational/Input";
import AuthFormTemplate from "../../components/Presentational/Forms/AuthFormTemplate";
import { ICON_VISIBILITY_ON } from "../../images";

import "./styles.css";

const ResetPassword = () => {
  return (
    <Layout>
      <AuthFormTemplate>
        <form onSubmit={() => {}}>
          <p className="form-title">
            Olá!
            <br />
            Vamos redefinir sua senha!
          </p>
          <Input
            label="Digite sua nova senha"
            name="password"
            value={""}
            required={true}
            rightIcon={<img src={ICON_VISIBILITY_ON} alt="icon" />}
          />
          <Input
            label="Confirme sua nova senha"
            name="confirmPassword"
            value={""}
            required={true}
            rightIcon={<img src={ICON_VISIBILITY_ON} alt="icon" />}
          />
          <div></div>
          {/* <ErrorMessage display={!!errorMessage} onCloseErrorMessage={(e) => onCloseErrorMessage(e)} message={errorMessage} /> */}
          <button style={{ opacity: 0.5 }}>Redefinir senha</button>
          <a href="/signup">Não tem uma conta?</a>
        </form>
      </AuthFormTemplate>
    </Layout>
  );
};

export default ResetPassword;
