import React, { useState } from "react";
import { URL_CHATBOT } from "../../../constants";
import * as Styled from "./styled";

const Chatbot = () => {
  const [isOpenChatbot, setIsOpenChatbot] = useState(false);

  if (isOpenChatbot) {
    return (
      <Styled.ChatbotContainer>
        <Styled.ChatbotClose onClick={() => setIsOpenChatbot(false)}>
          Fechar
        </Styled.ChatbotClose>
        <Styled.Iframe
          id="iframe"
          src={`${URL_CHATBOT}?p=ensina-by-fs`}
          title="Chatbot"
          frameBorder="0"
          width="400"
        />
      </Styled.ChatbotContainer>
    );
  }

  return (
    <Styled.Chatbot id="chat-icon">
      <Styled.ChatbotImage
        id="chat-image"
        onClick={() => setIsOpenChatbot(true)}
        alt="Chatbot"
        src={`${URL_CHATBOT}/assets/images/icon/ensina.png`}
      />
    </Styled.Chatbot>
  );
};

export default Chatbot;
