import React from "react";
import reactStringReplace from "react-string-replace";
import { ICON_ERROR } from "../../../../images";
import "./styles.css";

export default (props) => {
  return (
    <div
      className="error"
      style={{ display: `${props.display ? "flex" : "none"}` }}
    >
      <img src={ICON_ERROR} alt="error" />
      <div>
        {reactStringReplace(props.message, /(https?:\/\/\S+)/g, (match, i) => (
          <a
            key={match + i}
            rel="noopener noreferrer"
            target="_blank"
            href={match}
          >
            aqui
          </a>
        ))}
      </div>
    </div>
  );
};
