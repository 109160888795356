import React from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import MyCourses from "./pages/Courses/MyCourses";
import CourseVideo from "./pages/Courses/Video";
import CourseQuizzes from "./pages/Courses/Quizzes";
import CourseBundle from "./pages/Courses/Bundle";
import Help from "./pages/Help";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Certificates from "./pages/Certificates";
import Explore from "./pages/Explore";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/Signin";
import ResetPassword from "./pages/ResetPassword";
import KnowledgeTrail from "./pages/KnowledgeTrail";
import ExternalCertificate from "./pages/ExternalCertificate";
import Achievements from "./pages/Achievements";
import Activate from "./pages/Activate";

/**
 * Authorize
 */
import { isAuthorizated } from "./utils";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthorizated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default () => (
  <HashRouter basename="/">
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/help" exact={true} component={Help} />
      <Route
        path="/static/privacypolicy"
        exact={true}
        component={PrivacyPolicy}
      />
      <Route
        path="/static/termsconditions"
        exact={true}
        component={TermsConditions}
      />
      <Redirect from="/chat" to="/?show-bot" exact={true} />
      <PrivateRoute path="/mycourses" exact={true} component={MyCourses} />
      <PrivateRoute path="/dashboard" exact={true} component={Dashboard} />
      <PrivateRoute
        path="/certificates/:id?"
        exact={true}
        component={Certificates}
      />
      <PrivateRoute path="/explore/:id?" exact={true} component={Explore} />
      <PrivateRoute
        path="/knowledgetrail"
        exact={true}
        component={KnowledgeTrail}
      />
      <PrivateRoute
        path="/course/video/:courseId/:videoRef?/:chapterId?/:lessonId?"
        exact={true}
        component={CourseVideo}
      />
      <PrivateRoute
        path="/knowledgetrail/:id"
        exact={true}
        component={CourseBundle}
      />
      <PrivateRoute
        path="/achievements"
        exact={true}
        component={Achievements}
      />
      <PrivateRoute
        path="/course/quizzes/:homeworkId/:chapterId?/:courseId?/:videoRef?/:nextChapter?/:nextLesson?"
        exact={true}
        component={CourseQuizzes}
      />
      <Route path="/signup" exact={true} component={SignUp} />
      <Route path="/signin" exact={true} component={SignIn} />
      <Route path="/resetpassword" exact={true} component={ResetPassword} />
      <Route path="/ativar" exact={true} component={Activate} />
      <Route
        path="/certificate/:id?"
        exact={true}
        component={ExternalCertificate}
      />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </HashRouter>
);
