import axios from "axios";
import firebase from "firebase/app";
import { translateMessage } from "../../utils/TranslateMessage";
import {
  URL_FIREBASE_AUTH,
  URL_FIREBASE,
  API_KEY_FIREBASE,
} from "../../constants";

const HEADERS = {
  "Content-Type": "application/json",
};

/**
 * Function Sign in anonymously
 */
export async function signInAnonymously() {
  try {
    const response = await firebase.auth().signInAnonymously();
    const idToken = await response.user?.getIdToken();
    console.log("[signInAnonymously-response]:", response);
    return {
      status: true,
      sessionToken: idToken,
      refreshToken: response.user.refreshToken,
      firebaseUid: response.user.uid,
    };
  } catch (error) {
    console.log("[signInAnonymously-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Signin with Phone Number
 * @param {json} body
 */
export async function signInWithPhoneNumber(phoneNumber, appVerifier) {
  try {
    const response = await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier);
    console.log("[signInWithPhoneNumber-response]:", response);
    return {
      status: true,
      response: response,
    };
  } catch (error) {
    console.log("[signInWithEmailPassword-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Signin with Email Password
 * @param {json} body
 */
export async function signInWithEmailPassword({ email, password }) {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    const idToken = await response.user?.getIdToken();

    console.log("[signInWithEmailPassword-response]:", response);

    return {
      status: true,
      sessionToken: idToken,
      refreshToken: response.user.refreshToken,
      firebaseUid: response.user.uid,
    };
  } catch (error) {
    console.log("[signInWithEmailPassword-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Signup Anonumous
 * @param {json} body
 */
export async function signUpAnonymously(email, password) {
  try {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );

    const response = await firebase
      .auth()
      .currentUser.linkWithCredential(credential);
    const idToken = await response.user?.getIdToken();

    console.log("[signUpAnonymous-response]:", response);
    return {
      status: true,
      sessionToken: idToken,
      email: response.user.email,
      refreshToken: response.user.refreshToken,
      firebaseUid: response.user.uid,
    };
  } catch (error) {
    console.log("[signUpAnonymous-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Get New Token
 * @param {json} body
 */
export async function getNewToken(refreshToken) {
  try {
    const response = await axios.post(
      `${URL_FIREBASE_AUTH}/token?key=${API_KEY_FIREBASE}`,
      {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
      { headers: HEADERS }
    );

    console.log("[getNewToken-response]:", response.data);
    return {
      status: true,
      idToken: response.data.id_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (error) {
    console.log("[getNewToken-error]: ", error.response);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Send Email Verification
 */
export async function sendEmailVerification() {
  try {
    const response = await firebase.auth().currentUser.sendEmailVerification();
    console.log("[sendEmailVerification-response]:", response);
    return {
      status: true,
    };
  } catch (error) {
    console.log("[sendEmailVerification-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Send Password Reset Email
 * @param {string} email
 */
export async function sendPasswordResetEmail(email) {
  try {
    const response = await firebase.auth().sendPasswordResetEmail(email);
    console.log("[sendPasswordResetEmail-response]:", response);
    return {
      status: true,
    };
  } catch (error) {
    console.log("[sendPasswordResetEmail-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Sign Out Firebase
 */
export async function signOutFirebase() {
  try {
    const response = await firebase.auth().signOut();
    console.log("[signOutFirebase-response]:", response);
    return {
      status: true,
    };
  } catch (error) {
    console.log("[signOutFirebase-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Update Password
 * @param {json} body
 */
export async function updatePassword({ email, oldPassword, newPassword }) {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );

    const reAuthResponse = await firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credential);
    console.log("[updatePassword-reAuthResponse]:", reAuthResponse);
    const response = await firebase
      .auth()
      .currentUser.updatePassword(newPassword);
    console.log("[updatePassword-response]:", response);
    return {
      status: true,
    };
  } catch (error) {
    console.log("[updatePassword-error]: ", error);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Update Email
 * @param {json} body
 */
export async function updateEmail({ email, oldEmail, password }) {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      oldEmail,
      password
    );

    const reAuthResponse = await firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credential);
    console.log("[updateEmail-reAuthResponse]:", reAuthResponse);

    const decodeToken = JSON.stringify(reAuthResponse);
    const token = JSON.parse(decodeToken);

    await firebase.auth().currentUser.updateEmail(email);
    console.log("[updateEmail-response]:", token);
    return {
      status: true,
      email: token.user.email,
      sessionToken: token.user.stsTokenManager.accessToken,
      refreshToken: token.user.stsTokenManager.refreshToken,
    };
  } catch (error) {
    console.log("[updateEmail-error]: ", error);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Create User With Email And Password
 * @param {json} body
 */
export async function createUserWithEmailAndPassword(email, password) {
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const decodeToken = JSON.stringify(response);
    const token = JSON.parse(decodeToken);

    console.log("[createUserWithEmailAndPassword-response]:", token);

    return {
      status: true,
      email: token.user.email,
      sessionToken: token.user.stsTokenManager.accessToken,
      refreshToken: token.user.stsTokenManager.refreshToken,
      firebaseUid: response.user.uid,
    };
  } catch (error) {
    console.log("[createUserWithEmailAndPassword-error]: ", error);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Get Id Token
 * @param {json} customToken
 */
export async function getIdToken(customToken) {
  try {
    const response = await axios.post(
      `${URL_FIREBASE}/accounts:signInWithCustomToken?key=${API_KEY_FIREBASE}`,
      { token: customToken, returnSecureToken: true },
      { headers: HEADERS }
    );

    console.log("[get-idToken]: ", response.data);

    return {
      status: true,
      idToken: response.data.idToken,
      refreshToken: response.data.refreshToken,
    };
  } catch (error) {
    console.log("[getIdToken-error]: ", error);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}
