import styled from "styled-components";
import { P, H1 } from "../../../../styles";

export const Banner = styled.div`
  margin-top: 80px;
`;

export const Container = styled.div`
  max-width: 1000px;
  width: 100vw;
  margin: 120px auto;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 600px;
  height: 500px;

  @media only screen and (max-width: 768px) {
    height: 800px;
    max-height: 1200px;
  }
`;

export const ContentLeft = styled.div`
  align-items: flex-start;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: 40px;

    > p {
      font-size: 1.1rem;
      color: #fff;
    }
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;

  > h1 {
    font-size: 25px;
    color: var(--color-text);
    font-family: var(--font-lexend-semi-bold);
  }

  > a {
    font-family: var(--font-lexend-medium);
    color: var(--color-primary);
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    margin: 40px;
  }
`;

export const ContentButtons = styled.div`
  display: block;
  justify-content: center;
  text-align: center;
`;

export const Paragraph = styled(P)`
  color: #fff;
`;

export const Title = styled(H1)`
  margin-bottom: 20px;
  color: #fff;
`;
