import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Styled from "./styled";
import Input from "../../../Input";
import { ICON_VISIBILITY_ON, ICON_VISIBILITY_OFF } from "../../../../../images";

const FormSigninEmail = ({
  handleSubmitForm = () => {},
  handleChangeForm = () => {},
  onClickForgotPasswordModal = () => {},
  onClickBackForm = () => {},
  email,
  password,
  children,
  errorMessage,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const isValidInput = () => {
    if (email && password) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Styled.Form onSubmit={handleSubmitForm}>
      <i
        className="fas fa-chevron-circle-left fa-1x"
        style={{
          marginBottom: 15,
          color: "var(--color-primary)",
        }}
        onClick={onClickBackForm}
      ></i>

      <Styled.Paragraph>
        Olá!
        <br />
        Qual o seu email cadastrado?
      </Styled.Paragraph>
      <Input
        label="Seu email"
        name="email"
        type="email"
        value={email}
        required={true}
        onChange={handleChangeForm}
      />
      <Input
        label="Sua senha"
        name="password"
        type={passwordVisibility ? "text" : "password"}
        value={password}
        required={true}
        onChange={handleChangeForm}
        rightIconAction={() => {
          passwordVisibility
            ? setPasswordVisibility(false)
            : setPasswordVisibility(true);
        }}
        rightIcon={
          passwordVisibility ? (
            <img src={ICON_VISIBILITY_OFF} alt="icon" />
          ) : (
            <img src={ICON_VISIBILITY_ON} alt="icon" />
          )
        }
      />
      <Link to="#" onClick={onClickForgotPasswordModal}>
        Esqueceu a senha?
      </Link>
      <Styled.ContentButtons
        style={{
          border: errorMessage ? "solid 1px #dadce0" : "none",
        }}
      >
        {children}
        <button
          disabled={isValidInput() ? false : true}
          style={{ opacity: isValidInput() ? 1 : 0.5 }}
        >
          Entrar
        </button>
        <Link to="/signup" style={{ alignSelf: "center" }}>
          Não tem uma conta?
        </Link>
      </Styled.ContentButtons>
    </Styled.Form>
  );
};

export default FormSigninEmail;
