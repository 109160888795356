/**
 * Function Sleep
 * @param {string} delay
 * @returns
 */
export const sleep = (delay) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
};
