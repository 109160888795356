import { makeRequest } from "../../../services/Graphql";
import {
  GET_COURSES,
  GET_COURSES_BY_TITLE,
  GET_COURSES_BY_CATEGORIES,
  GET_COURSES_BY_ID,
} from "./queries";

export async function getCourses(limit, skip) {
  const response = await makeRequest(GET_COURSES, { limit, skip });
  console.log("[success-getCourses]: ", response.courses);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    courses: response.courses,
  };
}

export async function getCourseById(id) {
  const response = await makeRequest(GET_COURSES_BY_ID, { id });
  console.log("[success-getCoursesById]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    course: response.courses[0],
  };
}

export async function getCoursesByCategory(categories) {
  const response = await makeRequest(GET_COURSES_BY_CATEGORIES, {
    categories,
  });

  console.log("[success-getCoursesByCategory]: ", response.courses);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    courses: response.courses,
  };
}

export async function getCoursesByTitle(title) {
  const response = await makeRequest(GET_COURSES_BY_TITLE, {
    title,
  });

  console.log("[success-getCoursesByTitle]: ", response.courses);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    searchedCourse: response.courses,
  };
}
