import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../Presentational/Modal";
import ButtonPrimary from "../../../Presentational/Button/Primary";
import * as Styled from "./styled";
import IMAGE1 from "./images/image.png";

const TrailsModal = () => {
  const [display, setDisplay] = useState(false);
  const [checked, setChecked] = useState(false);

  const onContinue = () => {
    if (checked) {
      localStorage.setItem("trailspopup", "true");
    }
    setDisplay(false);
  };

  useEffect(() => {
    if (!(localStorage.getItem("trailspopup") === "true")) {
      setDisplay(true);
    }
  }, [setDisplay]);

  return (
    <>
      {display && (
        <ModalTemplate isClose={() => setDisplay(false)}>
          <Styled.Section>
            <Styled.Content>
              <img src={IMAGE1} alt="" width="250" />
              <p
                style={{
                  fontFamily: "LexendBold",
                  fontSize: 24,
                  marginBottom: 12,
                }}
              >
                Seja especialista no que quiser
              </p>
              <p
                style={{
                  fontFamily: "LexendMedium",
                  fontSize: 14,
                }}
              >
                Com as trilhas de aprendizagem, você terá uma formação
                continuada,
                <br />
                aprimorando seu conhecimento nos temas de seu interesse
              </p>
              <ButtonPrimary onClick={onContinue}>Continuar</ButtonPrimary>
            </Styled.Content>

            <Styled.ContentFooter
              name="trail-checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              <input type="checkbox" />
              <p>Não mostrar novamente</p>
            </Styled.ContentFooter>
          </Styled.Section>
        </ModalTemplate>
      )}
    </>
  );
};

export default TrailsModal;
