/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderRight from "../../Containers/Header/Right";
// import { LOGO, LOGO_TRANSPARENT } from "../../../images";
import * as Styled from "./styled";

const Header = ({ isGuest = true }) => {
  const history = useHistory();

  const LOGO = localStorage.getItem("logo");
  const LOGO_TRANSPARENT = localStorage.getItem("transparentLogoUrl");

  return (
    <>
      {isGuest ? (
        <Styled.ContentGuest>
          <p>
            Atualmente você está utilizando a plataforma como convidado,
            cadastre-se para liberar mais funções!
          </p>
        </Styled.ContentGuest>
      ) : null}
      <Styled.Header>
        <Styled.Content>
          <Styled.ContentLogo>
            <Link to="#">
              <img src={LOGO} alt="" height="40" />
            </Link>
          </Styled.ContentLogo>
          <Styled.ContentLogoMobile>
            <Link to="#">
              <img src={LOGO_TRANSPARENT} alt="" height="40" />
            </Link>
          </Styled.ContentLogoMobile>

          {isGuest ? (
            <Styled.DivGuest>
              <Styled.ButtonGuestTransparent
                onClick={() => history.push("/signin")}
              >
                Já tem uma conta
              </Styled.ButtonGuestTransparent>
              <Styled.ButtonGuest onClick={() => history.push("/signup")}>
                Cadastrar
              </Styled.ButtonGuest>
            </Styled.DivGuest>
          ) : null}
          <HeaderRight isGuest={isGuest} />
        </Styled.Content>
      </Styled.Header>
    </>
  );
};

export default Header;
