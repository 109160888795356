import axios from "axios";
import uuidv4 from "uuid/v4";
import { translateMessage } from "../../utils/TranslateMessage";

import {
  URL_BACKEND_BFF,
  BRAND_NAME,
  REQUESTER_NAME,
  CLIENT_ID,
  CLIENT_SECRET,
  URL_CHAT,
} from "../../constants";

const HEADERS = {
  "Content-Type": "application/json",
  "X-FS-Correlation-ID": uuidv4(),
  "X-FS-Requester-Name": REQUESTER_NAME,
  "X-FS-Brand-Name": BRAND_NAME,
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  "X-FS-Is-BFF": "true",
};

/**
 * Function Send Pincode
 * @param {json} body
 */
export async function sendPincode(body) {
  try {
    console.log("[send-pincode]: ", body);
    const response = await axios.post(
      `${URL_BACKEND_BFF}/authentication/v1/send`,
      body,
      { headers: HEADERS }
    );
    console.log("[success-pincode]:", response.data.transaction_id);
    return { status: true, transactionId: response.data.transaction_id };
  } catch (error) {
    console.log("[send-pincode-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message),
    };
  }
}

/**
 * Function Validate Pincode
 * @param {json} body
 */
export async function validatePincode(body) {
  try {
    console.log("[validate-pincode]:", body);
    const response = await axios.post(
      `${URL_BACKEND_BFF}/authentication/v1/validate`,
      body,
      { headers: HEADERS }
    );
    console.log("[success-pincode]:", response.data["fs-custom-token"]);
    return { status: true, fs_custom_token: response.data["fs-custom-token"] };
  } catch (error) {
    if (error.response.data.code === 401) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message:
          "Código incorreto, verifique a mensagem enviada e tente novamente.",
      };
    }

    if (error.response.data.code === 403) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message:
          "Código inválido ou expirado, solicite um novo código e tente novamente.",
      };
    }

    if (error.response.data.code === 406) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        code: 406,
        message: `Máximo de tentativas excedido. Clique ${URL_CHAT} para falar conosco.`,
      };
    }

    if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco.`,
      };
    }

    console.log("[error-api]:", error.request);
    return {
      status: false,
      message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco.`,
    };
  }
}
