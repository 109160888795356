import axios from "axios";
import { URL_CLOUD_FUNCTION, URL_CLOUD_STORAGE } from "../../constants";

/**
 * Function Generate Certificate
 * @param {obj} placeholders
 */
export async function generateCertificate(
  partner,
  firstName,
  lastName,
  courseName,
  finishedAt,
  durationAt,
  certificateId,
  userId
) {
  const data = {
    template: partner,
    placeholders: {
      firstName: firstName,
      lastName: lastName,
      courseName: courseName,
      finishedAt: finishedAt,
      durationAt: durationAt,
      certificateId: certificateId,
    },
    userId: userId,
    output: {
      pdf: {
        width: "1200px",
        height: "800px",
      },
      image: {
        width: "1200px",
        height: "800px",
      },
    },
  };

  try {
    const response = await axios.post(
      `${URL_CLOUD_FUNCTION}/generateCertificate`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("[generateCertificate-response]:", response.data);
    return {
      status: true,
      imageUrl: `${URL_CLOUD_STORAGE}/fs-pdf-files/${response.data.image}.png`,
      pdfUrl: `${URL_CLOUD_STORAGE}/fs-pdf-files/${response.data.pdf}.pdf`,
    };
  } catch (error) {
    console.log("[generateCertificate-error]: ", error.response);

    return {
      status: false,
      message: "error",
    };
  }
}
