import { gql } from "graphql-request";

export const GET_PROFILE_BY_EMAIL = gql`
  query {
    profiles {
      id
      name
      surname
      email
      imageUrl
      phoneNumber
      campaign {
        skin {
          color
          logoUrl
          title
          transparentLogoUrl
        }
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation createProfile($data: CreateProfileInput!) {
    createProfile(data: $data) {
      id
      email
      name
      surname
      phoneNumber
      campaign {
        skin {
          color
          logoUrl
          title
          transparentLogoUrl
        }
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $currentEmail: String
    $phoneNumber: String
    $data: UpdateProfileInput!
  ) {
    updateProfile(
      email: $currentEmail
      phoneNumber: $phoneNumber
      data: $data
    ) {
      id
      email
      name
      surname
      phoneNumber
      campaign {
        skin {
          color
          logoUrl
          title
          transparentLogoUrl
        }
      }
    }
  }
`;
