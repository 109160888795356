import { gql } from "graphql-request";

export const GET_ACHIEVEMENTS = gql`
  query {
    achievements {
      id
      title
      description
      imageUrl
      imageUrlDisabled
    }
  }
`;

export const GET_NEW_ACHIEVEMENTS = gql`
  query {
    newAchievements {
      id
      title
      description
      imageUrl
      imageUrlDisabled
    }
  }
`;
