import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;

export const Content = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Button = styled.div`
  height: 48px;
  align-self: center;
  border-radius: 24px;
  font-family: var(--font-lexend-medium);
  background-color: transparent;
  color: ${(props) => props.theme.color};
  margin: 8px 0;
  font-size: 14px;
  cursor: pointer;
`;
