import styled from "styled-components";

export const Li = styled.li`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => (props.image ? `${props.image}` : "")});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  min-height: 220px;

  &:first-child {
    grid-column: 1 / -1;
  }
`;

export const ContentTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  i {
    color: #fff;
  }
`;

export const ContentFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const P = styled.p`
  color: hsla(0, 0%, 100%, 1);
  font-family: var(--font-lexend-medium, Verdana, Geneva, Tahoma, sans-serif);
  font-size: 1.1rem;
  margin-bottom: 6px;
`;

export const Span = styled.span`
  color: hsla(0, 0%, 100%, 1);
  font-family: var(--font-lexend-light, Verdana, Geneva, Tahoma, sans-serif);
  font-size: 0.8rem;
`;

export const Time = styled.span`
  align-items: center;
  color: hsla(0, 0%, 100%, 1);
  display: flex;
  font-size: 0.8rem;

  i {
    margin-right: 6px;
  }
`;

export const ContentBottoms = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ContentPlay = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 20px;
  color: hsla(0, 0%, 100%, 1);
  font-size: 0.8rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #fff;
  }
`;

export const ButtonCertificate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color};
  height: 40px;
  width: 40px;
  border-radius: 20px;
`;
