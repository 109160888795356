import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ThemeContext from "../../contexts/ThemeContext";
import NavBarLeft from "../../components/Presentational/Navbar/Left";
import NavbarRight from "../../components/Presentational/Navbar/Right";
import NavbarFooter from "../../components/Presentational/Navbar/Mobile";
import Loader from "../../components/Presentational/Loader";
import ErrorMessage from "../../components/Presentational/Alerts/Errors";
import Header from "../../components/Presentational/Header";
import Chatbot from "../../components/Presentational/Chatbot";
// import { NATIVE_APP } from "../../constants";
import * as Styled from "./styled";

const Layout = ({ children, loading, errorMessage }) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [displayGoalsModal, setDisplayGoalsModal] = useState(false);

  useEffect(() => {
    const isGuest = localStorage.getItem("isGuest");
    if (isGuest === "true") {
      setIsGuest(isGuest);
    }
  }, []);

  return (
    <ThemeContext>
      <main>
        <Header isGuest={isGuest} />
        <Styled.Article>
          <Loader display={loader || loading} />
          <NavBarLeft
            isGuest={isGuest}
            display={displayMenu}
            onCloseMenu={() => setDisplayMenu(false)}
          />
          <Styled.Container>
            {error || errorMessage ? (
              <ErrorMessage message={error || errorMessage} />
            ) : (
              children
            )}
          </Styled.Container>
          <NavbarRight
            setLoader={setLoader}
            setErrorMessage={setError}
            displayGoalsModal={displayGoalsModal}
            setDisplayGoalsModal={setDisplayGoalsModal}
          />
          <NavbarFooter />
        </Styled.Article>
        {/* {!NATIVE_APP && <Chatbot />} */}
        <Chatbot />
      </main>
      <ToastContainer />
    </ThemeContext>
  );
};

export default Layout;
