import { makeRequest } from "../../../services/Graphql";
import { GET_QUIZ_BY_ID } from "./queries";

export async function getQuizById(id) {
  const response = await makeRequest(GET_QUIZ_BY_ID, { id });
  console.log("[success-getQuizById]: ", response.homeworks[0].questions[0]);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    title: response.homeworks[0].title,
    quizzes: response.homeworks[0].questions[0],
  };
}
