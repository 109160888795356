import React from "react";
import * as Styled from "./styled";
import { LOGO_ENSINA_BY_FS } from "../../../../images";

export default () => {
  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.Content>
          <img src={LOGO_ENSINA_BY_FS} alt="Logo" width="150" />
        </Styled.Content>
      </Styled.Container>
    </Styled.Header>
  );
};
