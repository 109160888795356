import React from "react";
import Certificate from "../../../Containers/Certificate";
import * as Styled from "./styled";

const CertificateModal = ({ setDisplay = () => {}, courseId = null }) => {
  return (
    <Styled.Certificates>
      <Styled.Container>
        <Styled.Header>
          <span className="close" onClick={() => setDisplay(false)}>
            <i className="fa fa-times" />
          </span>
        </Styled.Header>
        <Certificate
          courseId={courseId}
          headerButtons={false}
          footerButtons={true}
        />
      </Styled.Container>
    </Styled.Certificates>
  );
};

export default CertificateModal;
