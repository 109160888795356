import styled from "styled-components";

export const Footer = styled.footer`
  background: #fff;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 2px solid #dadada;
  position: relative;
  bottom: 0;
  width: 100vw;
`;

export const FooterCenter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  width: 100vw;
`;

export const Container = styled.div`
  display: grid;
  padding: 40px 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 30px;
  justify-content: space-around;

  small {
    font-family: var(--font-lexend-regular);
    font-size: 12px;
    color: #616161;
  }
`;

export const Terms = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const Item = styled.a`
  color: var(--color-primary);
  font-family: var(--font-lexend-regular);
  font-size: 12px;
  margin-right: 10px;
`;
