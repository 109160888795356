import styled from "styled-components";
import { Section, H3, P } from "../../../styles";

export const Container = styled(Section)``;

export const BannerImage = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const Title = styled(H3)`
  margin-bottom: 20px;
`;

export const Paragraph = styled(P)``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  box-shadow: 6px 6px 16px 0 hsl(0deg 0% 0% / 10%);
  text-align: center;
  padding: 15px 0;
  flex: 1 1;
  margin-bottom: 30px;
`;

export const InfoDiv = styled.div`
  margin: 0 30px;
`;

export const Time = styled(P)`
  font-size: 1rem;
  font-family: var(--font-lexend-semi-bold, Verdana, Geneva, Tahoma);
  margin-bottom: -2px;
`;

export const Small = styled.small`
  font-size: 0.7rem;
  color: var(--color-text);
`;

export const ContentModules = styled.div``;
