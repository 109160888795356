import React from "react";
import ModalSignUp from "../../../Containers/Modal/Signup";
import * as Styled from "./styled";

const BannerHomeLeverageCareer = () => (
  <Styled.Section>
    <Styled.Content>
      <Styled.Title>
        Alavanque <br /> sua carreira
      </Styled.Title>
      <Styled.Paragraph>
        Com as trilhas de aprendizagem, <br /> você aprimorará seu conhecimento{" "}
        <br /> através de uma formação continuada
      </Styled.Paragraph>
      <ModalSignUp buttonName="Comece a aprender" />
    </Styled.Content>
  </Styled.Section>
);

export default BannerHomeLeverageCareer;
