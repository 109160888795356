import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Loading from "../../components/Presentational/Loader";
import FormSendPincode from "../../components/Presentational/Forms/Activate/SendPincode";
import FormValidatePincode from "../../components/Presentational/Forms/Activate/ValidatePincode";
import FormSuccessActivate from "../../components/Presentational/Forms/Activate/SuccessActivate";
import Footer from "../../components/Presentational/Footer";
import { validatorPhoneNumber } from "../../utils";

import {
  sendPincode,
  validatePincode,
  subscribeSerial,
  updateContact,
} from "../../services";

import IMAGE1 from "./images/brasao-fsensina.png";
import "./styles.css";

const DISPLAY = {
  sendPincode: true,
  validatePincode: false,
  successMessage: false,
};

const Activate = (props) => {
  const [loader, setLoader] = useState(false);
  const [display, setDisplayForm] = useState(DISPLAY);
  const [licenca, setLicenca] = useState({});
  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
    setLicenca((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmitFormSendPincode = async (e) => {
    e.preventDefault();
    setLoader(true);

    const licencaSerial = licenca.serial;

    licencaSerial === undefined ? licenca.serial = licenca.licenca : licenca.licenca = licenca.serial

    const validator = validatorPhoneNumber(formData.phone);

    if (!validator.status) {
      setLoader(false);
      setLoader(false);
      setErrorMessage(validator.message);
      return;
    }

    const sendPincodeResponse = await sendPincode({
      msisdn: validator.phone.replace("+", ""),
    });

    if (!sendPincodeResponse.status) {
      setLoader(false);
      setErrorMessage(sendPincodeResponse.message);
      return;
    }

    setDisplayForm((data) => ({
      ...data,
      sendPincode: false,
      validatePincode: true,
    }));

    setFormData((data) => ({
      ...data,
      phone: validator.phone.replace("+55", ""),
      transactionId: sendPincodeResponse.transactionId,
    }));

    setLoader(false);
  };

  const handleSubmitFormValidatePincode = async (e) => {
    e.preventDefault();
    setLoader(true);

    const validatePincodeResponse = await validatePincode({
      transaction_id: formData.transactionId,
      pincode: formData.pincode,
    });

    if (!validatePincodeResponse.status) {
      setLoader(false);
      setErrorMessage(validatePincodeResponse.message);
      return;
    }

    const subscribeSerialResponse = await subscribeSerial({
      serial: licenca.licenca,
      msisdn: '55' + formData.phone,
    });

    if (!subscribeSerialResponse.status) {
      setLoader(false);
      setErrorMessage(subscribeSerialResponse.message);
      return;
    }

    await updateContact(formData.phone);

    setLoader(false);

    setDisplayForm((data) => ({
      ...data,
      validatePincode: false,
      successMessage: true,
    }));
  };

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    if (parsed.licenca) {
      setLicenca((data) => ({
        ...data,
        licenca: parsed.licenca
      }));
    }
  }, [props.location.search]);

  return (
    <div>
      <div className="wrapper">
        <div className="degustacao">
          <header
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <img src={IMAGE1} alt="Logotipo FS Ensina" />
          </header>
          {display.sendPincode && (
            <FormSendPincode
              serial={licenca.licenca}
              phone={formData.phone}
              handleChange={handleChangeForm}
              handleSubmit={handleSubmitFormSendPincode}
            />
          )}
          {display.validatePincode && (
            <FormValidatePincode
              phone={formData.phone}
              handleResendPincode={() => {
                setDisplayForm((data) => ({
                  ...data,
                  sendPincode: true,
                  validatePincode: false,
                }));
              }}
              handleChange={handleChangeForm}
              handleSubmit={handleSubmitFormValidatePincode}
            />
          )}
          {errorMessage && (
            <p
              style={{
                margin: 10,
                color: "red",
              }}
            >
              {errorMessage}
            </p>
          )}
          {display.successMessage && <FormSuccessActivate />}
          {loader && <Loading display={true} />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activate;
