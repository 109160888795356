import {
  LOGO_ENSINA_BY_FS,
  LOGO_ENSINA_BY_FS_WHITE,
  LOGO_ENSINA_FINANCAS,
  LOGO_ENSINA_FINANCAS_WHITE,
  LOGO_ENSINA_BETA,
  LOGO_ENSINA_BETA_WHITE,
  BANNER_ENSINA_FINANCAS,
  BANNER_ENSINA_BETA,
  // BANNER_ENSINA_BY_FS,
} from "./images";

export default {
  ensinafinancas: {
    projectName: "ensina-financas",
    title: "Ensina Finanças",
    campaignTitle: "ENSINA-FINANCAS",
    logoUrl: LOGO_ENSINA_FINANCAS,
    logoTransparent: LOGO_ENSINA_FINANCAS_WHITE,
    bannerHome: BANNER_ENSINA_FINANCAS,
    color: {
      button: "#1ba831",
      text: "#616161",
    },
    chatbot: {
      name: "ensina-financas",
      icon: "ensina-financas",
      context: "WelcomeEnsinaFinancas",
    },
  },
  ensinabeta: {
    projectName: "ensina-beta",
    title: "Ensina Beta",
    campaignTitle: "ENSINA-BETA",
    logoUrl: LOGO_ENSINA_BETA,
    logoTransparent: LOGO_ENSINA_BETA_WHITE,
    bannerHome: BANNER_ENSINA_BETA,
    color: {
      button: "#555EC0",
      text: "#616161",
    },
    chatbot: {
      name: "ensina-beta",
      icon: "ensina",
      context: "WelcomeMeuEnsina",
    },
  },
  ensina: {
    projectName: "ensina-by-fs",
    title: "Ensina by FS",
    campaignTitle: "",
    logoUrl: LOGO_ENSINA_BY_FS,
    logoTransparent: LOGO_ENSINA_BY_FS_WHITE,
    bannerHome: BANNER_ENSINA_BETA,
    color: {
      button: "#1fbcb6",
      text: "#616161",
    },
    chatbot: {
      name: "ensina-by-fs",
      icon: "ensina",
      context: "",
    },
  },
};
