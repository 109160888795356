import React from "react";
import { Redirect } from "react-router-dom";
import ButtonLink from "../../Presentational/Button/Link";
import ModalSignUp from "../../Containers/Modal/Signup";
import { validateToken } from "../../../utils";
import * as Styled from "./styled";
import { LOGO_ENSINA_BY_FS_WHITE } from "../../../images";

const SplashScreen = () => {
  if (validateToken("ensina:sessionToken")) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <Styled.Banner>
      <Styled.Container>
        <img
          src={LOGO_ENSINA_BY_FS_WHITE}
          alt=""
          width="200"
          style={{ alignSelf: "center" }}
        />
        <Styled.Buttons>
          <ModalSignUp buttonName="Comece a aprender" buttonType={true} />
          <ButtonLink to={"/signin"}>Já sou cliente</ButtonLink>
        </Styled.Buttons>
      </Styled.Container>
    </Styled.Banner>
  );
};

export default SplashScreen;
