import React, { useState, useEffect } from "react";
import ModalTemplate from "../../../Presentational/Modal";
import ButtonPrimary from "../../../Presentational/Button/Primary";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import SuccessMessage from "../../../Presentational/Alerts/Success";
import Input from "../../../Presentational/Input";
import { updateUserProfile } from "../../../../services";
import Spinner from "../../../Presentational/Spinner";

const UpdateProfle = ({
  display = false,
  setDisplay = () => {},
  firstName = "",
  lastName = "",
  email = "",
}) => {
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData((currentData) => ({
      ...currentData,
      firstName: firstName,
      lastName: lastName,
      email: email,
    }));
  }, [firstName, lastName, email, display]);

  const isValidInput = () => {
    if (
      userData.email &&
      userData.firstName &&
      userData.lastName &&
      userData.password
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setUserData((currentData) => ({
      ...currentData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    setErrorMessage(null);

    const updateUserProfileResponse = await updateUserProfile(
      userData.email,
      "",
      userData.firstName,
      userData.lastName,
      userData.email,
      ""
    );

    if (!updateUserProfileResponse.status) {
      setLoader(false);
      setErrorMessage(updateUserProfileResponse.message);
      return;
    }

    setSuccessMessage("Perfil atualizado com sucesso!");
    setUserData({});
    setLoader(false);

    setTimeout(() => {
      setSuccessMessage("");
      window.location.reload();
    }, 1500);
  };

  return (
    <>
      {display && (
        <ModalTemplate isClose={() => setDisplay(false)}>
          <div className="modal-form" style={{ textAlign: "center" }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 12,
                  marginBottom: 16,
                }}
              >
                <p style={{ fontFamily: "LexendBold" }}>Informações pessoais</p>
              </div>
              <Input
                label="Seu nome"
                name="firstName"
                type="text"
                value={userData.firstName}
                required={true}
                onChange={handleChangeForm}
              />
              <Input
                label="Seu sobrenome"
                name="lastName"
                type="text"
                value={userData.lastName}
                required={true}
                onChange={handleChangeForm}
              />
              <ErrorMessage
                display={!!errorMessage}
                onCloseErrorMessage={() => setErrorMessage(null)}
                message={errorMessage}
              />

              {!!successMessage && <SuccessMessage message={successMessage} />}

              {loader ? (
                <Spinner />
              ) : (
                <ButtonPrimary
                  disabled={isValidInput() ? false : true}
                  style={{ opacity: isValidInput() ? 1 : 0.5 }}
                >
                  Alterar
                </ButtonPrimary>
              )}
            </form>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default UpdateProfle;
