import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../layout/Inner";
import FormSendQuizzes from "../../../components/Presentational/Forms/Quizzes/SendQuiz";
import ShimmerEffect from "../../../components/Presentational/ShimmerEffect";
import ModalAlert from "../../../components/Containers/Modal/Alert";
import { getQuizById } from "../../../services/Graphql/Quizzes";
import { updateCourseProgress } from "../../../services/Graphql/Profile";
import * as Styled from "./styled";

const MODAL = {
  display: false,
  color: "",
  title: "",
  message: "",
  showButtonSuccess: false,
};

const Quizzes = (props) => {
  const [loader, setLoader] = useState(true);
  const [homeworkId, setHomeworkId] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [titleQuiz, setTitleQuiz] = useState("");
  const [answerUser, setAnswerUser] = useState("");
  const [answerQuiz, setAnswerQuiz] = useState("");
  const [questionQuiz, setQuestionQuiz] = useState("");
  const [optionQuiz, setOptionQuiz] = useState([]);
  const [explanationQuiz, setExplanationQuiz] = useState("");
  const [countAnswer, setCountAnswer] = useState(1);
  const [videoRef, setVideoRef] = useState("");
  const [nextChapter, setNextChapter] = useState("");
  const [nextLesson, setNextLesson] = useState("");
  const [modal, setModal] = useState(MODAL);

  const questionsForQuiz = useCallback(
    async (
      homeworkId,
      chapterId,
      courseId,
      videoRef,
      nextChapter,
      nextLesson
    ) => {
      const response = await getQuizById(chapterId);

      if (!response.status) {
        setLoader(false);
        return;
      }

      setHomeworkId(homeworkId);
      setChapterId(chapterId);
      setCourseId(courseId);
      setVideoRef(videoRef);
      setNextChapter(nextChapter);
      setNextLesson(nextLesson);
      setTitleQuiz(response.title);
      setAnswerQuiz(response.quizzes.answer);
      setOptionQuiz(response.quizzes.options);
      setQuestionQuiz(response.quizzes.question);
      setExplanationQuiz(response.quizzes.explanation);
      setLoader(false);
    },
    []
  );

  const onSubmitFormSendQuizzes = useCallback(
    async (e) => {
      e.preventDefault();

      if (answerUser !== answerQuiz && countAnswer === 1) {
        setCountAnswer(countAnswer + 1);
        setModal({
          ...modal,
          display: true,
          color: "#721c24",
          title: "Ops, não foi dessa vez!",
          message: "Resposta errada! =( Que tal tentar mais uma vez?",
          showButtonSuccess: false,
        });
        return;
      }

      setLoader(true);

      const response = await updateCourseProgress({
        courseId,
        chapterId,
        homeworkId,
        finished: true,
      });

      if (!response.status) {
        setLoader(false);
        return;
      }

      if (answerUser === answerQuiz) {
        setCountAnswer(1);
        setLoader(false);

        setModal({
          ...modal,
          display: true,
          color: "var(--color-primary)",
          title: "Parabéns! Você acertou!",
          message: explanationQuiz,
          showButtonSuccess: true,
        });
        return;
      }

      if (answerUser !== answerQuiz && countAnswer === 2) {
        setCountAnswer(1);
        setLoader(false);

        setModal({
          ...modal,
          display: true,
          color: "#721c24",
          title: "Resposta errada!",
          message: explanationQuiz,
          showButtonSuccess: true,
        });
        return;
      }
    },
    [
      answerUser,
      answerQuiz,
      countAnswer,
      courseId,
      chapterId,
      homeworkId,
      modal,
      explanationQuiz,
    ]
  );

  useEffect(() => {
    let homeworkId = props.match.params.homeworkId;
    let courseId = props.match.params.courseId;
    let chapterId = props.match.params.chapterId;
    let videoRef = props.match.params.videoRef;
    let nextChapter = props.match.params.nextChapter;
    let nextLesson = props.match.params.nextLesson;

    questionsForQuiz(
      homeworkId,
      chapterId,
      courseId,
      videoRef,
      nextChapter,
      nextLesson
    );
  }, [
    questionsForQuiz,
    props.match.params.homeworkId,
    props.match.params.chapterId,
    props.match.params.courseId,
    props.match.params.videoRef,
    props.match.params.nextChapter,
    props.match.params.nextLesson,
  ]);

  if (loader) {
    return (
      <Layout>
        <Styled.Container>
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"image"} />
        </Styled.Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>{titleQuiz}</Styled.Title>
        <Styled.Paragraph>QUESTÃO</Styled.Paragraph>
        <Styled.Content>
          <FormSendQuizzes
            question={questionQuiz}
            chapterId={chapterId}
            courseId={courseId}
            videoRef={videoRef}
            homeworkId={homeworkId}
            options={optionQuiz}
            handleChange={(e) => setAnswerUser(e.target.value)}
            handleSubmit={onSubmitFormSendQuizzes}
          />
        </Styled.Content>
        <ModalAlert
          color={modal.color}
          title={modal.title}
          message={modal.message}
          showButtonSuccess={modal.showButtonSuccess}
          videoRef={videoRef}
          courseId={courseId}
          nextLesson={nextLesson}
          nextChapter={nextChapter}
          homeworkId={homeworkId}
          display={modal.display}
          onClickModalDisplay={() =>
            setModal({
              ...modal,
              display: false,
            })
          }
        />
      </Styled.Container>
    </Layout>
  );
};

export default Quizzes;
