import * as firebase from "firebase/app";
import { NODE_ENV } from "../../constants";
import "firebase/analytics";

/**
 * Function Click Analytics Event
 */
export function logAnalyticsEvent(event) {
  if (NODE_ENV === "production") return firebase.analytics().logEvent(event);
}

export function logLetsCooeeEvent(event) {
  if (NODE_ENV === "production") return window.CooeeSDK.events.push(event);
}

/**
 * Function Get Params Token
 */
export function getParamsStorage(type) {
  switch (type) {
    case "skin":
      return localStorage.getItem("skin");

    default:
      return localStorage.getItem("gaid");
  }
}
