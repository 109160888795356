import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ListCard from "../../../Presentational/Cards/List";
import CourseCard from "../../../Presentational/Cards/Course";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import ConfirmationModal from "../../../Containers/Modal/ConfirmationModal";

import {
  getAllUserCourseProgress,
  getAllUserCourseFavorites,
  removeFavoriteUserCourse,
} from "../../../../services/Graphql/Profile";
import { SHIMMER_SIZE } from "../../../../constants";
import { ICON_LIST_COURSE_SAVE } from "../../../../images";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../../services/Analytics";
import * as Styled from "./styled";

const ContainerListCardCourseSaved = ({ screenName = "" }) => {
  const history = useHistory();
  const [deleteSavedModal, setDeleteSavedModal] = useState(false);
  const [deleteCourse, setDeleteCourse] = useState(null);
  const [loader, setLoader] = useState(true);
  const [savedCourses, setSavedCourses] = useState([]);

  const getCourses = useCallback(async () => {
    setLoader(true);
    const getAllUserCourseProgressResponse = await getAllUserCourseProgress();

    if (!getAllUserCourseProgressResponse.status) {
      setLoader(false);
      return;
    }

    const getAllUserCourseFavoritesResponse = await getAllUserCourseFavorites();

    if (!getAllUserCourseFavoritesResponse.status) {
      setLoader(false);
      return;
    }

    setSavedCourses(getAllUserCourseFavoritesResponse.userCourseFavorite);

    setLoader(false);
  }, []);

  const removeCourseFavorite = useCallback(
    async (courseId, title, categoryId, categoryTitle) => {
      setLoader(true);

      const response = await removeFavoriteUserCourse(courseId);

      if (!response.status) {
        setLoader(false);
        return;
      }

      logAnalyticsEvent("curso_remover_lista", {
        categoria_id: categoryId,
        categoria_nome: categoryTitle,
        curso_id: courseId,
        curso_nome: title,
        screen_name: screenName,
        user_id: getParamsStorage("gaid"),
        skin: getParamsStorage("skin"),
      });

      getCourses();
    },
    [getCourses, screenName]
  );

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return savedCourses.length ? (
    <section className="box-courses">
      <ListCard>
        {savedCourses.map((item, index) => (
          <CourseCard
            key={index}
            courseId={item.id}
            title={item.title}
            subTitle={item.title}
            duration={item.duration}
            image={item.imageUrl}
            onPlay={() => {
              logAnalyticsEvent("curso_abrir", {
                categoria_id: item.categories[0].id,
                categoria_nome: item.categories[0].title,
                curso_id: item.id,
                curso_nome: item.title,
                screen_name: screenName,
              });
              history.push(`course/video/${item.id}`);
            }}
            onRemove={() => {
              setDeleteCourse({
                courseId: item.id,
                title: item.title,
                categoryId: item.categories[0].id,
                categoryTitle: item.categories[0].title,
              });
              setDeleteSavedModal(true);
            }}
          />
        ))}
        <ConfirmationModal
          display={deleteSavedModal}
          setDisplay={setDeleteSavedModal}
          message={`Deseja excluir o curso ${deleteCourse?.title} de sua lista?`}
          confirmText="Excluir"
          cancelText="Cancelar"
          confirmAction={() => {
            removeCourseFavorite(
              deleteCourse?.courseId,
              deleteCourse?.title,
              deleteCourse?.categoryId,
              deleteCourse?.categoryTitle
            );
            setDeleteSavedModal(false);
          }}
          cancelAction={() => setDeleteSavedModal(false)}
        />
      </ListCard>
    </section>
  ) : (
    <Styled.NotFound>
      <Styled.Image src={ICON_LIST_COURSE_SAVE} alt="course" />
      <Styled.Paragraph>
        Aqui você encontra todos os cursos que você adicionou a sua lista
      </Styled.Paragraph>
    </Styled.NotFound>
  );
};

export default ContainerListCardCourseSaved;
