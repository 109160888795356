import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormEmail from "../../../Presentational/Forms/Signin/Email";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import Loader from "../../../Presentational/Loader";
import ModalForgotPassword from "../../Modal/ForgotPassword";
import { saveSessionTokens } from "../../../../utils";
import { signInWithEmailPassword } from "../../../../services";
import { getUserProfile } from "../../../../services/Graphql/User";
import {
  logAnalyticsEvent,
  logLetsCooeeEvent,
} from "../../../../services/Analytics";

const AuthEmailContainer = ({ onClickBackForm = () => {} }) => {
  const history = useHistory();
  const [loading, setLoader] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setUserData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrorMessage(null);

    const response1 = await signInWithEmailPassword({
      email: userData.email,
      password: userData.password,
    });

    if (!response1.status) {
      setLoader(false);
      logAnalyticsEvent("LoginEmailNotFound");
      setErrorMessage(response1.message);
      return;
    }

    const response2 = await getUserProfile(response1.sessionToken);

    console.log(response2);

    if (!response2.status) {
      setLoader(false);
      logAnalyticsEvent("LoginEmailNotFound");
      setErrorMessage(response2.message);
      return;
    }

    localStorage.setItem("isGuest", "false");
    localStorage.setItem("gaid", response2.profile[0].id);
    localStorage.setItem("skin", response2.profile[0].campaign.skin.title);
    localStorage.setItem("color", response2.profile[0].campaign.skin.color);
    localStorage.setItem("logo", response2.profile[0].campaign.skin.logoUrl);
    localStorage.setItem(
      "transparentLogoUrl",
      response2.profile[0].campaign.skin.transparentLogoUrl
    );

    saveSessionTokens(response1.sessionToken, response1.refreshToken);

    logAnalyticsEvent("fluxo_cliente_entrar_email_fim", {
      screen_name: "LoginEmail",
      user_id: response2.profile[0].id,
      skin: response2.profile[0].campaign.skin.title,
    });

    logLetsCooeeEvent([
      "Log In",
      {
        item: {
          id: response2.profile[0].id,
          email: userData.email,
        },
      },
    ]);

    setTimeout(async () => {
      history.push("/dashboard");
    }, 500);
  };

  useEffect(() => {
    logAnalyticsEvent("fluxo_cliente_entrar_email", {
      screen_name: "LoginEmail",
    });
  }, []);

  return (
    <>
      <Loader display={loading} />
      <FormEmail
        email={userData.email}
        password={userData.password}
        handleChangeForm={handleChangeForm}
        handleSubmitForm={handleSubmitForm}
        errorMessage={errorMessage}
        onClickForgotPasswordModal={() => setDisplayModal(true)}
        onClickBackForm={onClickBackForm}
      >
        <ErrorMessage
          display={!!errorMessage}
          onCloseErrorMessage={() => setErrorMessage(null)}
          message={errorMessage}
        />
      </FormEmail>

      <ModalForgotPassword
        display={displayModal}
        setDisplay={setDisplayModal}
        setLoader={setLoader}
      />
    </>
  );
};

export default AuthEmailContainer;
