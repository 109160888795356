import React, { useEffect, useState, useCallback } from "react";
import ListCard from "../../../Presentational/Cards/List";
import CardsCourse from "../../../Presentational/Cards/Course";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import { getCertificates } from "../../../../services/Graphql/Certificates";
import IMAGE1 from "./images/ic_certificates.svg";
import { SHIMMER_SIZE } from "../../../../constants";
import * as Styled from "./styled";

const ContainerListCertificateCard = () => {
  const [loader, setLoader] = useState(true);
  const [certificates, setCertificates] = useState([]);

  const getAllCertificates = useCallback(async () => {
    setLoader(true);
    let response = await getCertificates();

    if (!response.status) {
      setLoader(false);
      return;
    }

    setCertificates(response.certificates);
    setLoader(false);
  }, []);

  useEffect(() => {
    getAllCertificates();
  }, [getAllCertificates]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <>
      {certificates.length ? (
        <ListCard>
          {certificates.map((item, index) => (
            <>
              <CardsCourse
                key={index}
                certificates
                courseId={item.course.id}
                image={item.course.imageUrl}
                title={item.course.title}
                subTitle={item.course.categories[0].title}
                completionDate={item.finishedAt}
              />
            </>
          ))}
        </ListCard>
      ) : (
        <Styled.NoCertificate>
          <Styled.Image src={IMAGE1} alt="certificate" />
          <Styled.Paragraph>
            Sempre que você terminar um curso, seu certificado estará disponível
            aqui!
          </Styled.Paragraph>
        </Styled.NoCertificate>
      )}
    </>
  );
};

export default ContainerListCertificateCard;
