import styled from "styled-components";
import { P } from "../../../../styles";

export const NotFound = styled.section`
  text-align: center;
`;

export const Image = styled.img``;

export const Paragraph = styled(P)`
  margin-top: 40px;
`;
