import { gql } from "graphql-request";

export const GET_ALL_CATEGORIES = gql`
  query {
    categories {
      id
      title
      imageUrl
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    courses {
      categories {
        id
        title
        imageUrl
      }
    }
  }
`;
