import React, { useState, useEffect } from "react";
import { DOCUMENT_IMAGE } from "../../../../images";
import ModalCertificate from "../../../Containers/Modal/Certificate";
import { logAnalyticsEvent } from "../../../../services/Analytics";
import * as Styled from "./styled";

const ButtonCertificate = ({ courseId, screenName = "" }) => {
  const [modalCertificate, setModalCertificate] = useState(false);

  useEffect(() => {
    logAnalyticsEvent("curso_certificado", {
      categoria_id: "",
      categoria_nome: "",
      curso_id: courseId,
      curso_nome: "",
      screen_name: screenName,
    });
  });

  return (
    <>
      <Styled.Button onClick={() => setModalCertificate(true)}>
        <img src={DOCUMENT_IMAGE} alt="certificate" />
        <Styled.Span>Certificado</Styled.Span>
      </Styled.Button>

      {modalCertificate && (
        <ModalCertificate
          courseId={courseId}
          setDisplay={() => setModalCertificate(false)}
        />
      )}
    </>
  );
};

export default ButtonCertificate;
