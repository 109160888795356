import React from "react";
import { Route } from "react-router-dom";
import Layout from "../../layout/Inner";
import CoursesListCertificateCard from "../../components/Containers/Courses/ListCertificateCard";
import Certificate from "../../components/Containers/Certificate";
import * as Styled from "./styled";

const Certificates = (props) => {
  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>Certificados</Styled.Title>
        <Route path="/certificates" exact={true}>
          <CoursesListCertificateCard />
        </Route>
        <Route path="/certificates/:id" exact={true}>
          <Certificate courseId={props.match.params.id} />
        </Route>
      </Styled.Container>
    </Layout>
  );
};

export default Certificates;
