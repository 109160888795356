import styled from "styled-components";

export const Button = styled.button`
  height: 48px;
  width: 40%;
  align-self: center;
  border-radius: 24px;
  font-family: var(--font-lexend-medium);
  border: solid 1px ${(props) => props.theme.color};
  background-color: #ffffff;
  color: ${(props) => props.theme.color};
  margin: 16px 0;
  cursor: pointer;
  margin-left: 5px;

  i {
    margin-right: 5px;
  }

  span {
    font-family: var(--font-lexend-semi-bold);
    font-size: 0.8rem;
    padding-left: 5px;
  }

  &.active {
    color: #ffffff;
    background-color: ${(props) => props.theme.color};
    border-color: 1px solid ${(props) => props.theme.color};
  }
`;
