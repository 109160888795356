import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../layout/Inner";
import CardBundle from "../../../components/Presentational/Cards/Bundle";
import ShimmerEffect from "../../../components/Presentational/ShimmerEffect";
import { maskToTime } from "../../../utils";
import { getTrailsById } from "../../../services/Graphql/Trails";
import { getCertificates } from "../../../services/Graphql/Certificates";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../services/Analytics";
import * as Styled from "./styled";

const CoursesBundle = (props) => {
  const [loader, setLoader] = useState(true);
  const [bundleId, setBundleId] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [bundleDescription, setbBundleDescription] = useState("");
  const [bundleImageDetails, setBundleImageDetails] = useState("");
  const [bundleDuration, setBundleDuration] = useState("");
  const [numberOfCourses, setNumberOfCourses] = useState("");
  const [coursesInfo, setCoursesInfo] = useState([]);

  /**
   * Get Bundle Details
   * @param {int} id
   */
  const getBundleDetails = useCallback(async (id) => {
    const response = await getTrailsById(id);

    if (!response.status) {
      setLoader(false);
      return;
    }

    const getCertificatesResponse = await getCertificates();

    if (!getCertificatesResponse.status) {
      setLoader(false);
      return;
    }

    const data = [];

    response.trails[0].courses.forEach((rowCourse) => {
      getCertificatesResponse.certificates.forEach((rowUser) => {
        rowCourse.isCompleted =
          rowUser.course.id === rowCourse.id ? true : false;
        rowCourse.finishedAt =
          rowUser.course.id === rowCourse.id ? rowUser.finishedAt : "";
      });
      data.push(rowCourse);
    });

    setLoader(false);
    setBundleName(response.trails[0].title);
    setbBundleDescription(response.trails[0].description);
    setBundleImageDetails(response.trails[0].imageUrl);
    setBundleDuration(
      response.trails[0].courses.reduce((n, { duration }) => n + duration, 0)
    );
    setNumberOfCourses(response.trails[0].courses.length);
    setCoursesInfo(data);

    logAnalyticsEvent("trilha_comecar", {
      trilha_id: response.trails[0].id,
      trilha_nome: response.trails[0].title,
      curso_id: response.trails[0].courses.id,
      curso_nome: response.trails[0].courses.title,
      screen_name: "CourseBundle",
      user_id: getParamsStorage("gaid"),
      skin: getParamsStorage("skin"),
    });
  }, []);

  useEffect(() => {
    setBundleId(props.match.params.id);
    getBundleDetails(props.match.params.id);
  }, [getBundleDetails, props.match.params.id]);

  if (loader) {
    return (
      <Layout>
        <Styled.Container>
          <ShimmerEffect type={"image"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"image"} />
          <ShimmerEffect type={"line"} />
          <ShimmerEffect type={"image"} />
          <ShimmerEffect type={"image"} />
          <ShimmerEffect type={"image"} />
        </Styled.Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Styled.Container>
        <Styled.BannerImage src={bundleImageDetails} alt="bundle" />
        <Styled.Title>{bundleName}</Styled.Title>
        <Styled.Paragraph>{bundleDescription}</Styled.Paragraph>
        <Styled.Content>
          <Styled.Info>
            <Styled.InfoDiv>
              <Styled.Time>{maskToTime(bundleDuration)}</Styled.Time>
              <Styled.Small>Tempo total</Styled.Small>
            </Styled.InfoDiv>
            <Styled.InfoDiv>
              <Styled.Time>{numberOfCourses}</Styled.Time>
              <Styled.Small>Cursos</Styled.Small>
            </Styled.InfoDiv>
          </Styled.Info>
        </Styled.Content>
        <Styled.ContentModules>
          <Styled.Title>Módulos</Styled.Title>
          <CardBundle
            rows={coursesInfo}
            bundleId={bundleId}
            bundleName={bundleName}
          />
        </Styled.ContentModules>
      </Styled.Container>
    </Layout>
  );
};

export default CoursesBundle;
