import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { getCoursesByTitle } from "../../../services/Graphql/Courses";
import "./styles.css";

const InputSearch = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedCourses, setSearchedCourses] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const searchCourseByKeyword = useCallback(
    debounce(async (searchKeyword) => {
      setLoader(true);
      const response = await getCoursesByTitle(searchKeyword);

      if (!response.status || response.searchedCourse.length === 0) {
        setLoader(false);
        setErrorMessage(true);
        return;
      }

      setSearchedCourses(response.searchedCourse);
      setErrorMessage(false);
      setLoader(false);
    }, 500),
    []
  );

  const listSearched = () => {
    if (errorMessage) {
      return (
        <p style={{ textAlign: "center" }}>
          Não encontramos nenhum curso relacionado a este tema
        </p>
      );
    }

    return searchedCourses.map((item, index) => (
      <Link
        key={index}
        to="#"
        onClick={() => {
          history.push(`/course/video/${item.id}`);
        }}
      >
        <img src={item.imageUrl} alt="course" />
        <p>{item.title}</p>
      </Link>
    ));
  };

  useEffect(() => {
    if (searchText.length > 3) {
      searchCourseByKeyword(searchText);
      return;
    }
    setSearchedCourses([]);
    setErrorMessage(false);
  }, [searchText, searchCourseByKeyword]);

  return (
    <>
      <div className="search-bar">
        <i className="fas fa-search" />
        <input
          type="text"
          value={searchText}
          required
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => setIsFocused(true)}
        />
        <label>O que você quer aprender?</label>
        {searchText ? (
          <i
            className="fa fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearchText("");
              setSearchedCourses([]);
            }}
          />
        ) : null}
      </div>
      {isFocused ? (
        <div className="search-pop-up">
          {loader ? (
            <p style={{ textAlign: "center" }}>Carregando...</p>
          ) : (
            listSearched()
          )}
        </div>
      ) : null}
    </>
  );
};

export default InputSearch;
