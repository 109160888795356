import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Layout from "../../layout/External";
import AuthFormTemplate from "../../components/Presentational/Forms/AuthFormTemplate";
import FormSignUp from "../../components/Presentational/Forms/SignUp";
import ErrorMessage from "../../components/Presentational/Alerts/Errors";
import { saveSessionTokens, validateToken } from "../../utils";
import { logAnalyticsEvent, logLetsCooeeEvent } from "../../services/Analytics";

import {
  createUserProfile,
  updateUserProfile,
} from "../../services/Graphql/User";

import {
  createUserWithEmailAndPassword,
  signUpAnonymously,
} from "../../services/Firebase";

import "./styles.css";

const TITLE = "Olá!";
const SUBTITLE = "Vamos criar sua conta!";

const SignUp = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({});

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const signUpAnonymous = async () => {
    const signUpAnonymouslyResponse = await signUpAnonymously(
      formData.email,
      formData.password
    );

    if (!signUpAnonymouslyResponse.status) {
      setLoader(false);
      setErrorMessage(signUpAnonymouslyResponse.message);
      return;
    }

    const currentEmail = `guest.${signUpAnonymouslyResponse.firebaseUid}@ensina.com.br`;

    const updateProfileResponse = await updateUserProfile(
      currentEmail,
      formData.firstName,
      formData.lastName,
      formData.email,
      validateToken("ensina:sessionToken")
    );

    if (!updateProfileResponse.status) {
      setLoader(false);
      setErrorMessage(updateProfileResponse.message);
      return;
    }

    saveSessionTokens(
      signUpAnonymouslyResponse.sessionToken,
      signUpAnonymouslyResponse.refreshToken
    );

    localStorage.setItem(
      "color",
      updateProfileResponse.profile.campaign.skin.color
    );
    localStorage.setItem(
      "logo",
      updateProfileResponse.profile.campaign.skin.logoUrl
    );
    localStorage.setItem(
      "transparentLogoUrl",
      updateProfileResponse.profile.campaign.skin.transparentLogoUrl
    );

    history.push("/dashboard");
  };

  const signUp = async () => {
    const createUserResponse = await createUserWithEmailAndPassword(
      formData.email,
      formData.password
    );

    if (!createUserResponse.status) {
      setLoader(false);
      setErrorMessage(createUserResponse.message);
      return;
    }

    const createProfileResponse = await createUserProfile(
      formData.firstName,
      formData.lastName,
      formData.email,
      "",
      "ENSINA-BETA",
      "ensina-beta"
    );

    if (!createProfileResponse.status) {
      setLoader(false);
      setErrorMessage(createProfileResponse.message);
      return;
    }

    localStorage.setItem("gaid", createProfileResponse.profile.id);
    localStorage.setItem(
      "skin",
      createProfileResponse.profile.campaign.skin.title
    );

    localStorage.setItem(
      "color",
      createProfileResponse.profile.campaign.skin.color
    );
    localStorage.setItem(
      "logo",
      createProfileResponse.profile.campaign.skin.logoUrl
    );
    localStorage.setItem(
      "transparentLogoUrl",
      createProfileResponse.profile.campaign.skin.transparentLogoUrl
    );

    saveSessionTokens(
      createUserResponse.sessionToken,
      createUserResponse.refreshToken
    );

    logAnalyticsEvent("fluxo_visitante_cadastrar_fim", {
      screen_name: "SignupOtherInformations",
      user_id: createProfileResponse.profile.id,
      skin: createProfileResponse.profile.campaign.skin.title,
    });

    logLetsCooeeEvent([
      "Sign Up",
      {
        item: {
          id: createProfileResponse.profile.id,
          email: formData.email,
        },
      },
    ]);

    history.push("/dashboard");
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (validateToken("ensina:sessionToken")) {
      let token = jwt_decode(validateToken("ensina:sessionToken"));

      if (token.firebase.sign_in_provider === "anonymous") {
        signUpAnonymous();
        return;
      }
    }

    signUp();
  };

  return (
    <Layout loader={loader}>
      <AuthFormTemplate>
        <FormSignUp
          title={TITLE}
          subTitle={SUBTITLE}
          phone={formData.phone}
          pincode={formData.pincode}
          email={formData.email}
          password={formData.password}
          firstName={formData.firstName}
          lastName={formData.lastName}
          confirmPassword={formData.confirmPassword}
          handleChangeForm={handleChangeForm}
          handleSubmitForm={handleSignup}
          errorMessage={errorMessage}
          onClickBackForm={() => history.push("/signin")}
        >
          <ErrorMessage
            display={!!errorMessage}
            onCloseErrorMessage={() => setErrorMessage(null)}
            message={errorMessage}
          />
        </FormSignUp>
      </AuthFormTemplate>
    </Layout>
  );
};

export default SignUp;
