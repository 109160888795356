import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Nav = ({ children, to }) => {
  return <NavLink to={to}>{children}</NavLink>;
};

const NavLink = styled(Link)`
  font-family: var(--font-lexend-medium);
  color: white;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
`;

export default styled(Nav)``;
