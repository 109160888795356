import React from "react";
import * as Styled from "./styled";

import {
  ICON_CALENDAR,
  ICON_DOCUMENT,
  ICON_TRAILS,
  ICON_PHONELINK,
  ICON_ROCKET,
  ICON_FAVORITE,
} from "../../../../images";

const BannerHomeNewWayLearning = () => (
  <Styled.Section>
    <Styled.Header>
      <Styled.Title>Conheça uma nova forma de aprender</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <ul>
        <li>
          <img src={ICON_CALENDAR} alt="" />
          <Styled.Paragraph>
            Defina seu próprio cronograma de estudos e aprenda do seu jeito
          </Styled.Paragraph>
        </li>
        <li>
          <img src={ICON_DOCUMENT} alt="" />
          <Styled.Paragraph>
            Obtenha certificados e compartilhe com quem quiser
          </Styled.Paragraph>
        </li>
        <li>
          <img src={ICON_TRAILS} alt="" />
          <Styled.Paragraph>
            Amplie suas oportunidades de carreira com trilhas de aprendizagem
          </Styled.Paragraph>
        </li>
      </ul>
      <ul>
        <li>
          <img src={ICON_PHONELINK} alt="" />
          <Styled.Paragraph>
            Assista as aulas em qualquer dispositivo, celular ou computador
          </Styled.Paragraph>
        </li>
        <li>
          <img src={ICON_ROCKET} alt="" />
          <Styled.Paragraph>
            Aprenda com cursos rápidos e objetivos
          </Styled.Paragraph>
        </li>
        <li>
          <img src={ICON_FAVORITE} alt="" />
          <Styled.Paragraph>
            Marque seus cursos favoritos para ver depois
          </Styled.Paragraph>
        </li>
      </ul>
    </Styled.Content>
  </Styled.Section>
);

export default BannerHomeNewWayLearning;
