import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bttom: 20px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;

  p {
    margin: 12px 0;
    color: var(--color-text);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    margin: auto;
  }
`;

export const Image1 = styled.img`
  margin-bottom: 16px;
`;

export const Image2 = styled.img`
  width: 160px;
  margin-top: 30px;
`;

export const Image3 = styled.img`
  width: 100px;
`;

export const Image4 = styled.hr`
  padding: 3px;
  background-color: #1fbcb6;
  width: 100%;
  border: none;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
`;

export const CertificateTitle = styled.p`
  font-size: 40px;
  margin: 30px auto;
`;

export const CertificateName = styled.h5`
  font-size: 40px;
  color: #1fbcb6;
  text-transform: uppercase;
  margin: 20px auto;
`;

export const MenuButton = styled.div`
  color: var(--color-text);
  margin-left: 14px;
  cursor: pointer;
`;

export const MenuPoupup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 30px;
  right: 30;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 10px;

  > a {
    padding: 8px 24px;
    font-family: var(--font-lexend-regular);
    color: var(--color-text);
    font-size: 0.8rem;
  }

  > a + a {
    border-top: 1px solid #ececec;
  }
`;
