/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ListCard from "../../../Presentational/Cards/List";
import CourseCard from "../../../Presentational/Cards/Course";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import InfiniteScroll from "../../../Presentational/InfiniteScroll";
import { getCourses } from "../../../../services/Graphql/Courses";
import { SHIMMER_SIZE } from "../../../../constants";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../../services/Analytics";

const ContainerCourseListCard = ({ screenName = "" }) => {
  let history = useHistory();
  const [shimmer, setShimmer] = useState(true);
  const [loader, setLoader] = useState(false);
  const [courses, setCourses] = useState([]);
  const [limit] = useState(8);
  const [skip, setSkip] = useState(0);

  const fetchMoreData = async () => {
    setLoader(true);

    const response = await getCourses(limit, skip);
    if (!response.status) {
      return;
    }

    if (response.courses.length > 0) {
      setCourses([...courses, ...response.courses]);
      setSkip(skip + limit);
    }
    setLoader(false);
  };

  const getCoursesById = useCallback(async () => {
    const response = await getCourses(limit, skip);

    if (!response.status) {
      return;
    }

    setShimmer(false);
    setCourses(response.courses);
    setSkip(skip + limit);
  }, [limit, skip]);

  useEffect(() => {
    getCoursesById();
  }, []);

  if (shimmer) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <InfiniteScroll
      data={courses}
      loader={loader}
      fetchMore={fetchMoreData}
      hasMore={true}
    >
      <ListCard>
        {courses.map((item, index) => (
          <CourseCard
            key={index}
            title={item.title}
            subTitle={item.categories[0].title}
            duration={item.duration}
            image={item.imageUrl}
            onPlay={() => {
              logAnalyticsEvent("curso_abrir", {
                categoria_id: item.categories[0].id,
                categoria_nome: item.categories[0].title,
                curso_id: item.id,
                curso_nome: item.title,
                screen_name: screenName,
                user_id: getParamsStorage("gaid"),
                skin: getParamsStorage("skin"),
              });
              history.push(`course/video/${item.id}`);
            }}
          />
        ))}
      </ListCard>
    </InfiniteScroll>
  );
};

export default ContainerCourseListCard;
