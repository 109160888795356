import styled from "styled-components";

export const Form = styled.form``;

export const Div = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const TextPrimary = styled.p`
  font-family: var(--font-lexend-bold);
  padding-bottom: 10px;
`;

export const TextSecondary = styled.p`
  font-family: var(--font-lexend-medium);
  font-size: 10px;
  padding-bottom: 10px;
`;
