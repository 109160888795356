import React from "react";
import { NavLink } from "react-router-dom";
import * as Styled from "./styled";

const NavBarMobile = () => {
  return (
    <Styled.Nav>
      <div>
        <NavLink
          to="/dashboard"
          isActive={(match) => (match ? true : false)}
          activeClassName="active-item"
        >
          <i className="fas fa-house-user" />
          <p>Início</p>
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/explore"
          isActive={(match) => (match ? true : false)}
          activeClassName="active-item"
        >
          <i className="fas fa-search" />
          <p>Explorar</p>
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/knowledgetrail"
          isActive={(match) => (match ? true : false)}
          activeClassName="active-item"
        >
          <i className="fas fa-map-signs" />
          <p>Trilhas</p>
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/mycourses"
          isActive={(match) => (match ? true : false)}
          activeClassName="active-item"
        >
          <i className="fas fa-th-list" />
          <p>Meus cursos</p>
        </NavLink>
      </div>
    </Styled.Nav>
  );
};

export default NavBarMobile;
