import React from "react";
import Layout from "../../layout/External";
import "./styles.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <section className="box-privacy">
        <header>
          <h1>
            <strong>POLÍTICA DE PRIVACIDADE</strong>
          </h1>
          <br />
          <hr />
        </header>
        <section>
          <p>
            Sem prejuízo das demais disposições constantes deste instrumento,
            poderão ser coletadas pela FS, por meio do FS Ensina, todas as
            informações ativamente inseridas pelo USUÁRIO no Aplicativo, como
            seus dados cadastrais, arquivos inseridos, nome de ameaças
            encontradas, dentre outras. Além disso, poderão ser coletadas
            algumas informações geradas automaticamente durante a utilização do
            FS Ensina pelo USUÁRIO, tais como as características do Dispositivo,
            IP com data e hora, origem do IP, informações sobre cliques, dados
            de geolocalização, dentre outros.
          </p>

          <p>
            Ainda, a FS poderá utilizar algumas tecnologias para coleta de
            informações dos USUÁRIOS, tais como cookies, de modo a aprimorar o
            serviço prestado e melhorar sua experiência de navegação.
          </p>

          <p>
            As informações coletadas por meio da utilização do FS Ensina serão
            utilizadas pela FS para a adequada prestação de serviços, bem como
            para aprimorar as funcionalidades disponibilizadas por meio do FS
            Ensina , inclusive por meio da geração de relatórios estatísticos.
            Ainda, por meio do aceite desta Política, o USUÁRIO consente
            expressamente em receber e-mails promocionais por parte da FS, bem
            como de seus parceiros e eventuais outros comunicados
            correlacionados. Caso não deseje receber essas mensagens, o USUÁRIO
            deverá entrar em contato com a FS, solicitando a cessação do
            recebimento dessas mensagens.
          </p>

          <p>
            Ao aceitar os presentes Termos, o USUÁRIO manifesta seu
            consentimento livre, expresso e informado para que os dados
            coletados sobre este durante sua utilização do FS Ensina sejam
            utilizados para as finalidades dispostas acima.
          </p>

          <p>
            Ainda, o USUÁRIO se declara ciente de que as informações coletadas
            por meio do FS Ensina poderão ser compartilhadas com terceiros nas
            seguintes circunstâncias:
          </p>

          <p>
            Se necessário às atividades comerciais e aos serviços prestados pela
            FS;
          </p>

          <p>
            Com empresas parceiras para fins publicitários, estatísticos e para
            a prestação dos serviços relacionados às funcionalidades
            disponibilizadas no FS Ensina, como, mas não se limitando a:
            AppsFlyer, Google Analytics, Facebook e Google AdWords;
          </p>

          <p>
            A fim de proteger os interesses da FS, em qualquer tipo de conflito,
            incluindo ações judiciais;
          </p>

          <p>
            No caso de operações societárias envolvendo a FS, hipótese em que a
            transferência das informações será necessária para a continuidade
            dos serviços;
          </p>

          <p>
            Mediante ordem judicial ou pelo requerimento de autoridades
            administrativas que detenham competência legal para sua requisição.
          </p>

          <p>
            Os dados coletados pela FS serão armazenados em servidores próprios
            ou contratados, de forma que serão empregados todos os esforços
            razoáveis de mercado para garantir a segurança dos respectivos
            sistemas na guarda de referidos dados, conforme diretrizes descritas
            no Decreto nº 8.771/2016.
          </p>

          <p>
            O Usuário poderá requerer a exclusão dos dados sobre ele coletados,
            contatando a FS para indicar seu pedido. Ressaltamos que a FS
            empreenderá seus melhores esforços para atender aos pedidos no menor
            espaço de tempo possível. Ainda, o Usuário se declara ciente de que
            a exclusão de seus dados implicará o fim de seu acesso ao Aplicativo
            e que, mesmo nesse caso, a FS deverá respeitar o prazo de
            armazenamento mínimo de informações determinado pela legislação
            brasileira.
          </p>
        </section>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
