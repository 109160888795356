import { gql } from "graphql-request";

export const GET_TRAILS = gql`
  query {
    trails {
      id
      title
      description
      imageUrl
      courses {
        id
        title
      }
    }
  }
`;

export const GET_TRAILS_BY_ID = gql`
  query ($trailsId: ID) {
    trails(id: $trailsId) {
      id
      title
      description
      imageUrl
      courses {
        id
        title
        imageUrl
        duration
      }
    }
  }
`;
