import styled from "styled-components";

export const Content = styled.section`
  margin: 0 auto;
  width: 100vw;
  display: flex;
  max-width: 1400px;

  @media (max-width: 1440px) {
    max-width: 100%;
  }
`;

export const ContentLogo = styled.div`
  align-items: center;
`;

export const Div = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
`;

export const MenuProfile = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: var(--font-lexend-regular);
    color: var(--color-text);
    font-size: 0.8rem;
    text-align: right;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const P = styled.p`
  font-family: var(--font-lexend-regular);
  color: var(--color-text);
  font-size: 0.8rem;
`;

export const ProfileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-left: 14px;
  object-fit: cover;
  background-color: var(--color-text);
  color: #fff;
`;

export const MenuButton = styled.div`
  color: var(--color-text);
  margin-left: 14px;
  cursor: pointer;
`;

export const MenuPoupup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 50px;
  right: 30;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 10px;

  > a {
    text-align: right;
    padding: 8px 24px;
    font-family: var(--font-lexend-regular);
    color: var(--color-text);
    font-size: 0.8rem;
  }

  > a + a {
    border-top: 1px solid #ececec;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const IconMenuMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    color: white;
    margin-top: 10px;
  }
`;

export const Nav = styled.nav`
  display: none;

  @media only screen and (max-width: 768px) {
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    height: 100%;
    margin: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    display: grid;
  }
`;

export const NavContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`;

export const NavbarContent = styled.nav`
  display: none;

  @media only screen and (max-width: 768px) {
    background-color: hsla(0, 0%, 100%, 1);
    display: block;
    height: 100vh;
    width: 250px;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 90;

    i {
      float: right;
      margin: 10px;
    }
  }
`;

export const List = styled.div`
  margin-top: 20px;

  ul {
    list-style: none;

    > li + li {
      border-top: 1px solid #ececec;
    }

    li a {
      margin: 10px 20px;
      align-items: center;
      color: var(--color-text, hsla(0, 0%, 0%, 1));
      display: flex;
      font-size: 14px;
      text-decoration: none;

      &.blocked {
        opacity: 0.5;
      }

      &:hover p,
      &.active-item p {
        color: ${(props) => props.theme.color};
      }

      &:hover i,
      &.active-item i {
        color: ${(props) => props.theme.color};
      }

      i {
        margin-right: 10px;
      }
    }
  }
`;

export const MenuProfileMobile = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px;

  p {
    font-family: var(--font-lexend-regular);
    color: var(--color-text);
    font-size: 0.8rem;
  }
`;

export const ProfileImageMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 14px;
  object-fit: cover;
  background-color: var(--color-text);
  color: #fff;
`;
