import styled, { keyframes } from "styled-components";

const modalAnimation = keyframes`
  0% {
    top: -100%;
    opacity: 0;
  }
  60% {
    top: 58%;
    opacity: 1;
  }
  100% {
    top: 50%; 
    opacity: 1;
  }
`;

export const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  height: 100%;
  margin: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`;

export const Content = styled.div`
  position: absolute;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  float: left;
  min-width: 340px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${modalAnimation} 1s ease-out;
  border-radius: 4px;
`;

export const Header = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
  border-radius: 2px;
  border-bottom: none;
  padding: 15px;
  height: 45px;

  span {
    float: right;
    margin: 0;
    margin-top: -6px;
  }
`;

export const Span = styled.span``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  padding: 15px;

  button {
    align-items: center;
  }
`;
