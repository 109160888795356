/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ShimmerEffect from "../../Presentational/ShimmerEffect";
import Certificate from "../../Presentational/Certificate";
import ButtonPrimary from "../../Presentational/Button/Default";
import Loader from "../../Presentational/Loader";
import {
  getCertificateUserByCourse,
  getExternalCertificate,
} from "../../../services/Graphql/Certificates";
import { generateCertificate } from "../../../services/CloudFunctions";
import { maskToTime, maskDate } from "../../../utils";
import { PROJECT_URL, NATIVE_APP } from "../../../constants";
import * as Styled from "./styled";

const ContainerCertificate = ({
  courseId,
  certificateId,
  external = false,
  headerButtons = true,
  footerButtons = false,
}) => {
  const [loader, setLoader] = useState(true);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [certificates, setCertificatesUser] = useState([]);
  const [certificateImage, setCertificateImage] = useState("");
  const [certificatePDF, setCertificatePDF] = useState("");

  const createCertificate = async (data) => {
    let generateCertificateResponse = await generateCertificate(
      "ensina",
      data.profile.name,
      data.profile.surname,
      data.course.title,
      maskDate(data.finishedAt),
      maskToTime(data.course.duration),
      data.id,
      data.profile.id
    );

    if (!generateCertificateResponse.status) {
      return;
    }

    setCertificateImage(generateCertificateResponse.imageUrl);
    setCertificatePDF(generateCertificateResponse.pdfUrl);
  };

  const getCertificateByCourse = async (courseId) => {
    let response = await getCertificateUserByCourse(courseId);

    if (!response.status) {
      return;
    }

    setCertificatesUser(response.certificates[0]);
    await createCertificate(response.certificates[0]);
    setLoader(false);
  };

  const getCertificateExternal = async (certificateId) => {
    let response = await getExternalCertificate(certificateId);

    if (!response.status) {
      return;
    }

    setCertificatesUser(response.certificates[0]);
    await createCertificate(response.certificates[0]);
    setLoader(false);
  };

  const copyToClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {}
  };

  const onClickButtonPrint = (e) => {
    let win = window.open("_blank", "_new");
    win.document.open();
    win.document.write(
      [
        '<html style="margin: 0" >',
        "   <head>",
        "   </head>",
        '   <body onload="window.print()" onafterprint="window.close()" style="margin: 0;" >',
        "   <img src='" + certificateImage + "'/>",
        "   </body>",
        "</html>",
      ].join("")
    );
    win.document.close();
    e.preventDefault();
  };

  const buttonsHeader = () => (
    <Styled.Container>
      <Styled.MenuButton
        onClick={() => setDisplayMenu((currentState) => !currentState)}
      >
        {displayMenu ? (
          <i className="fas fa-ellipsis-v" />
        ) : (
          <i className="fas fa-ellipsis-v" />
        )}
      </Styled.MenuButton>

      {displayMenu ? (
        <Styled.MenuPoupup>
          <a href={certificatePDF}>Baixar</a>
          {!NATIVE_APP && (
            <>
              <Link to="#" onClick={onClickButtonPrint}>
                Imprimir
              </Link>
              <Link
                to="#"
                data-tip={"Link copiado com sucesso!"}
                data-for={"tooltip"}
                data-event={"click"}
              >
                Compartilhar
              </Link>
              <ReactTooltip
                id="tooltip"
                globalEventOff={"click"}
                afterShow={() =>
                  copyToClipBoard(
                    `${PROJECT_URL}/#/certificate/${certificates.id}`
                  )
                }
              />
            </>
          )}
        </Styled.MenuPoupup>
      ) : null}
    </Styled.Container>
  );

  const buttonsFooter = () => (
    <Styled.ButtonsFooter>
      <ButtonPrimary onClick={() => window.open(certificatePDF)}>
        Baixar
      </ButtonPrimary>
      <ButtonPrimary onClick={onClickButtonPrint}>Imprimir</ButtonPrimary>
    </Styled.ButtonsFooter>
  );

  useEffect(() => {
    if (certificateId) {
      getCertificateExternal(certificateId);
      return;
    }

    getCertificateByCourse(courseId);
  }, [courseId, certificateId]);

  if (external) {
    if (loader) {
      return <Loader display />;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <img src={certificateImage} alt="Certificate" />
        </div>
      </div>
    );
  }

  if (loader) {
    return <ShimmerEffect type={"image"} />;
  }

  return (
    <>
      {headerButtons ? buttonsHeader() : null}
      <Certificate
        name={certificates.profile.name}
        surName={certificates.profile.surname}
        courseName={certificates.course.title}
        completionDate={certificates.finishedAt}
        courseDuration={certificates.course.duration}
        certificateNumber={certificates.id}
      />
      {footerButtons ? buttonsFooter() : null}
    </>
  );
};

export default ContainerCertificate;
