import styled from "styled-components";

export const Section = styled.section`
 display: flex;
 justify-content: center;
 margin-top: 75px;
`;

export const Div = styled.div`
    height: 180px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background-color: var(--color-primary);
`;

export const DivAuthForm = styled.div`
  padding: 32px 32px 32px 32px;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: #ffffff;
  margin-top: 80px;
  margin-bottom: 80px;

    @media (min-width: 768px) {
        width: 40%;
    }
`;