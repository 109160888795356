import { gql } from "graphql-request";

export const GET_CERTIFICATES = gql`
  query {
    certificates {
      id
      finishedAt
      course {
        id
        title
        imageUrl
        duration
        categories {
          title
        }
      }
      profile {
        name
        surname
      }
    }
  }
`;

export const GET_CERTIFICATE_BY_COURSE = gql`
  query ($courseId: ID) {
    certificates(courseId: $courseId) {
      id
      finishedAt
      course {
        id
        title
        imageUrl
        duration
        categories {
          title
        }
      }
      profile {
        id
        name
        surname
      }
    }
  }
`;

export const GET_PUBLIC_CERTIFICATE = gql`
  query ($id: ID) {
    certificates(id: $id) {
      id
      finishedAt
      course {
        id
        title
        imageUrl
        duration
        categories {
          title
        }
      }
      profile {
        id
        name
        surname
      }
    }
  }
`;
