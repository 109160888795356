import { makeRequest } from "../../../services/Graphql";
import { GET_RATING_BY_COURSE_ID, UPDATE_RATING_COURSE } from "./queries";

export async function getRatingByCourse(courseId) {
  const response = await makeRequest(GET_RATING_BY_COURSE_ID, {
    courseId,
  });

  console.log("[success-getRatingByCourse]: ", response.ratings);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    score: response.ratings.length > 0 ? response.ratings[0].score : 0,
    ratings: response.ratings.length > 0 ? response.ratings[0] : [],
  };
}

export async function updateRatingCourse(courseId, score) {
  const response = await makeRequest(UPDATE_RATING_COURSE, {
    courseId,
    score,
  });

  console.log("[success-updateRatingCourse]: ", response.createRating);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    rating: response.createRating,
  };
}
