import styled from "styled-components";
import { PBold } from "../../../../../styles";

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  button {
    height: 48px;
    width: 50%;
    align-self: center;
    border-radius: 24px;
    font-family: var(--font-lexend-medium);
    background-color: var(--color-primary);
    color: #fff;
    margin: 16px 0;
  }

  a {
    font-family: var(--font-lexend-medium);
    color: var(--color-primary);
    font-size: 12px;
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
`;

export const Paragraph = styled(PBold)`
  margin-bottom: 16px;
`;
