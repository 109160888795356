import React, { useState } from "react";
import { Route } from "react-router-dom";
import Layout from "../../layout/Inner";
import InputSearch from "../../components/Containers/InputSearch";
import ListCardCategories from "../../components/Containers/Courses/ListCardCategories";
import ListCardCourseByCategories from "../../components/Containers/Courses/ListCardCourseByCategories";
import * as Styled from "./styled";

const Explore = (props) => {
  const [description, setDescription] = useState({});

  return (
    <Layout>
      <Styled.Container>
        <InputSearch />
        <Styled.Title>
          {props.match.params.id
            ? description.title
            : "Navegar pelas categorias"}
          <br />
          <Styled.Paragraph>
            {props.match.params.id && description.size}
          </Styled.Paragraph>
        </Styled.Title>
        <Route path="/explore" exact={true}>
          <ListCardCategories />
        </Route>
        <Route path="/explore/:id" exact={true}>
          <ListCardCourseByCategories
            courseId={props.match.params.id}
            setDescription={setDescription}
            screenName={"Explore"}
          />
        </Route>
      </Styled.Container>
    </Layout>
  );
};

export default Explore;
