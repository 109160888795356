import styled from "styled-components";
import { H2 } from "../../../../styles";

export const Section = styled.section`
  padding: 40px 0 60px;
  max-width: 1000px;
  width: 100vw;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 20px 14px;
  }
`;

export const Header = styled.header`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #616161;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 28px;
    color: var(--color-text);
  }
`;

export const Content = styled.div`
  margin: 20px auto;
  max-width: 1000px;
  width: 100vw;

  @media only screen and (max-width: 768px) {
    margin: 20px auto;
    max-width: 100%;
    width: 100vw;
  }
`;

export const List = styled.ul`
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  li {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    min-height: 160px;
    position: relative;
  }
`;

export const SubList = styled.li`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  min-height: 160px;
  position: relative;
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(H2)``;

export const SubTitle = styled.h3`
  color: #fff;
  font-family: var(--font-lexend-bold, Verdana, Geneva, Tahoma, sans-serif);
  font-size: 20px;
  margin: 8px 0;
  text-align: center;
`;

export const ContentButton = styled.div`
  margin-top: 30px;
  text-align: center;
`;
