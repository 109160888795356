import styled from "styled-components";
import { Section, H3, P } from "../../styles";

export const Container = styled(Section)``;

export const Title = styled(H3)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Paragraph = styled(P)``;
