import { makeRequest } from "../../../services/Graphql";
import { GET_ACHIEVEMENTS, GET_NEW_ACHIEVEMENTS } from "./queries";

export async function getAchievements() {
  const response = await makeRequest(GET_ACHIEVEMENTS, {});
  console.log("[success-get-achievements]: ", response.achievements);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    achievements: response.achievements,
  };
}

export async function getNewAchievements() {
  const response = await makeRequest(GET_NEW_ACHIEVEMENTS, {});
  console.log("[success-get-new-achievements]: ", response.newAchievements);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    newAchievements: response.newAchievements,
  };
}
