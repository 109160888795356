import React from "react";
import * as Styled from "./styled";
import ButtonDownloadApp from "../../../Presentational/Button/DownloadApp";
import { BANNER_DOWNLOAD } from "../../../../images";

const BannerHomeDownloadApp = () => (
  <Styled.Section>
    <Styled.Center>
      <Styled.Container>
        <div>
          <Styled.Title>
            Baixe o app e <br />
            comece a aprender <br />
            de qualquer lugar
          </Styled.Title>
          <Styled.ContentButtons>
            <ButtonDownloadApp system="ios" />
            <ButtonDownloadApp system="android" />
          </Styled.ContentButtons>
        </div>
        <div>
          <img src={BANNER_DOWNLOAD} alt="" />
        </div>
      </Styled.Container>
    </Styled.Center>
  </Styled.Section>
);

export default BannerHomeDownloadApp;
