import React, { useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import * as Styled from "./styled";

const NavBar = ({
  isGuest = true,
  display = false,
  onCloseMenu = () => {},
}) => {
  const clickOutsideNav = useCallback(
    (e) => {
      if (!document.getElementById("navbar")?.contains(e.target) && display) {
        onCloseMenu();
      }
    },
    [display, onCloseMenu]
  );

  useEffect(() => {
    window.addEventListener("click", clickOutsideNav);
    return () => {
      window.removeEventListener("click", clickOutsideNav);
    };
  }, [clickOutsideNav]);

  return (
    <Styled.Nav id="navbar" className={`nav ${display ? "active-nav" : ""}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Menu */}
        <div>
          <ul>
            <li>
              <NavLink
                to="/dashboard"
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
              >
                <i className="fas fa-house-user" />
                <p>Início</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/explore"
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
              >
                <i className="fas fa-search" />
                <p>Explorar</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/knowledgetrail"
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
              >
                <i className="fas fa-map-signs" />
                <p>Trilhas</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mycourses"
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
              >
                <i className="fas fa-th-list" />
                <p>Meus cursos</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/achievements"
                onClick={(e) => isGuest && e.preventDefault()}
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
                className={isGuest ? "blocked" : ""}
              >
                <i className="fas fa-trophy" />
                <p>Conquistas</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/certificates"
                onClick={(e) => isGuest && e.preventDefault()}
                isActive={(match) => (match ? true : false)}
                activeClassName="active-item"
                className={isGuest ? "blocked" : ""}
              >
                <i className="fas fa-scroll" />
                <p>Certificados</p>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </Styled.Nav>
  );
};

export default NavBar;
