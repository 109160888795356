import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../Button";
import * as Styled from "./styled";
import { NATIVE_APP } from "../../../../../constants";

const AuthType = ({ onClickButtonType = () => {} }) => (
  <Styled.Header>
    {!NATIVE_APP && (
      <Link to="/">
        <i
          className="fas fa-chevron-circle-left fa-1x"
          style={{
            marginBottom: 15,
            color: "var(--color-primary)",
          }}
        ></i>
      </Link>
    )}
    <Styled.Paragraph>Como gostaria de entrar em sua conta?</Styled.Paragraph>
    <Styled.ContentButtons>
      <Button onClick={() => onClickButtonType("email")}>
        Entrar com email
      </Button>
      <Button onClick={() => onClickButtonType("phone")}>
        Entrar com celular
      </Button>
    </Styled.ContentButtons>
  </Styled.Header>
);

export default AuthType;
