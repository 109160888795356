import React from "react";
import * as Styled from "./styled";

const Spinner = () => {
  return (
    <Styled.Spinner>
      <Styled.Container>
        <Styled.Content></Styled.Content>
        <Styled.Content></Styled.Content>
        <Styled.Content></Styled.Content>
      </Styled.Container>
    </Styled.Spinner>
  );
};

export default Spinner;
