import { makeRequest } from "../../../services/Graphql";

import {
  SEND_CREATE_PROFILE,
  GET_PROFILE_BY_EMAIL,
  UPDATE_COURSE_PROGRESS,
  GET_USER_COURSE_PROGRESS,
  GET_ALL_USER_COURSE_PROGRESS,
  GET_ALL_USER_COURSE_FAVORITE,
  SAVE_FAVORITE_USER_COURSE,
  REMOVE_FAVORITE_USER_COURSE,
  GET_USER_COURSE_FAVORITE_BY_COURSE,
  GET_ALL_USER_ACHIEVEMENTS,
} from "./queries";

export async function getProfile() {
  const response = await makeRequest(GET_PROFILE_BY_EMAIL);
  console.log("[success-getProfile]: ", response.profiles);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    profile: response.profiles[0],
  };
}

export async function sendCreateProfile(
  firstName,
  lastName,
  email,
  phone,
  acceptedTermsOfUse = true,
  imageUrl = ""
) {
  const response = await makeRequest(
    SEND_CREATE_PROFILE,
    {
      firstName,
      lastName,
      email,
      acceptedTermsOfUse,
      imageUrl,
      phone,
    },
    false
  );

  console.log("[success-sendCreateProfile]: ", response.data);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    profile: response.data,
  };
}

export async function updateCourseProgress({
  courseId,
  chapterId,
  lessonId,
  homeworkId,
  currentTime,
  finished,
}) {
  const payload = { courseId, chapterId, finished };

  if (!homeworkId) {
    payload.lessonId = lessonId;
    payload.currentTime = currentTime;
  }

  payload.homeworkId = homeworkId;

  const response = await makeRequest(UPDATE_COURSE_PROGRESS, payload);
  console.log(
    "[success-updateCourseProgress]: ",
    response.updateCourseProgress
  );

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    updateCourseProgress: response.updateCourseProgress,
  };
}

export async function getUserCourseProgress(courseId) {
  const response = await makeRequest(GET_USER_COURSE_PROGRESS, {
    courseId,
  });

  console.log("[success-getUserCourseProgress]: ", response.courseProgress);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    userCourseCompleted:
      response.courseProgress.length > 0
        ? response.courseProgress[0].finished
        : false,
    userCourseProgress:
      response.courseProgress.length > 0
        ? response.courseProgress[0].chapters
        : [],
  };
}

export async function getAllUserCourseProgress() {
  const response = await makeRequest(GET_ALL_USER_COURSE_PROGRESS, {});

  console.log("[success-getAllUserCourseProgress]: ", response.courseProgress);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    userCourseProgress: response.courseProgress,
  };
}

export async function getAllUserCourseFavorites() {
  const response = await makeRequest(GET_ALL_USER_COURSE_FAVORITE, {});
  console.log("[success-getAllUserCourseFavorites]: ", response.favorites);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    userCourseFavorite: response.favorites?.courses || [],
  };
}

export async function getUserCourseFavoritesByCourse(courseId) {
  const response = await makeRequest(GET_USER_COURSE_FAVORITE_BY_COURSE, {
    courseId,
  });
  console.log("[success-getUserCourseFavoritesByCourse]: ", response.favorites);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  const courses = response.favorites?.courses || [];

  return {
    status: true,
    isFavoriteCourse: courses.length > 0,
  };
}

export async function saveFavoriteUserCourse(courseId) {
  const response = await makeRequest(SAVE_FAVORITE_USER_COURSE, { courseId });
  console.log("[success-saveFavoriteUserCourse]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
  };
}

export async function removeFavoriteUserCourse(courseId) {
  const response = await makeRequest(REMOVE_FAVORITE_USER_COURSE, {
    courseId,
  });
  console.log("[removeFavoriteUserCourse]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
  };
}

export async function getAllUserAchievements() {
  const response = await makeRequest(GET_ALL_USER_ACHIEVEMENTS);
  console.log("[getAllUserAchievements]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    achievements: response?.profiles[0].achievements,
  };
}
