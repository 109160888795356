import { PROJECT_URL, URL_CHAT } from "../../constants";

const INVALID_TOKEN = "Context creation failed: Invalid token";
const EXPIRED_TOKEN = "Context creation failed: Expired token";
const COURSE_NOT_FOUND = "No registers found for course";
const PRODUCT_NOT_FOUND = "No product found for user";

export const ERRORS_MESSAGE = {
  TOKEN: [INVALID_TOKEN, EXPIRED_TOKEN],
  NOT_FOUND: [COURSE_NOT_FOUND],
};

const MESSAGES = {
  "The email address is badly formatted.":
    "Email invalido. Por favor informe um email correto.",
  "The email address is already in use by another account.":
    "Este email já está cadastrado no Ensina Finanças.",
  "The email address is already in use by another account":
    "Este email já está cadastrado no Ensina Finanças.",
  "There is no user record corresponding to this identifier. The user may have been deleted.":
    "Não encontramos uma conta vinculada a este email.",
  "The password is invalid or the user does not have a password.":
    "Usuário ou senha digitados não conferem.",
  [COURSE_NOT_FOUND]: "Ops, esse curso não foi encontrado.",
  "Context creation failed: Invalid token": "Token invalido",
  [PRODUCT_NOT_FOUND]: `Ops, não encontramos o seu produto. Clique ${
    PROJECT_URL + "/#/signup"
  } para se cadastrar.`,
  "Password should be at least 6 characters":
    "Sua senha deve conter mais de 6 caracteres.",
  "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.":
    "Código de verificação inválido.",
  "Request failed with status code 406": "Número de telefone inválido.",
  "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.": `Máximo de tentativas excedido. Clique ${URL_CHAT} para falar conosco.`,
};

export function translateMessage(message) {
  return (
    MESSAGES[message] ||
    `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco.`
  );
}
