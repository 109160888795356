import axios from "axios";
import uuidv4 from "uuid/v4";

import {
  URL_BACKEND_BFF,
  REQUESTER_NAME,
  BRAND_NAME,
  URL_CHAT,
} from "../../constants";

/**
 * Function Subscribe Serial
 * @param {json} body
 */
export async function updateContact(phone) {
  try {
    await axios.post(
      `${URL_BACKEND_BFF}/bff/api/v2/contact_update`,
      {
        phone: phone,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-FS-Correlation-ID": uuidv4(),
          "X-FS-Requester-Name": REQUESTER_NAME,
          "X-FS-Brand-Name": BRAND_NAME,
        },
      }
    );

    return { status: true };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}
