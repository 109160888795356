import React from "react";
import NumberFormat from "react-number-format";
import "./styles.css";

const CustomInputPhoneNumber = ({
  containerStyle = {},
  inputStyle = {},
  labelStyle = {},
  rightIconStyle = {},
  rightIcon,
  rightIconAction = () => {},
  label = "",
  type = "text",
  name = "",
  value = "",
  required = false,
  onChange = () => {},
  autoFocus = false,
}) => {
  return (
    <div className="custom-input-box" style={containerStyle}>
      <NumberFormat
        label="Seu número de celular"
        name="phone"
        type="phone"
        value={value}
        required={required}
        onChange={onChange}
        format="(##) #####-####"
        mask="_"
        autoFocus={true}
        style={inputStyle}
      />
      <label htmlFor={name} style={labelStyle}>
        {label}
      </label>
      <div onClick={rightIconAction} style={rightIconStyle}>
        {rightIcon}
      </div>
    </div>
  );
};

export default CustomInputPhoneNumber;
