import React from 'react';

import './styles.css';

const CustomInput = ({
    containerStyle = {},
    inputStyle = {},
    labelStyle = {},
    rightIconStyle = {},
    rightIcon,
    rightIconAction = () => {},
    label = '',
    type = 'text',
    name = '',
    value = '',
    required = false,
    onChange = () => {},
    autoFocus = false,
}) => {
    return (
        <div className="custom-input-box" style={containerStyle}>
            <input
                id={name}
                name={name}
                type={type}
                required={required}
                value={value}
                onChange={onChange}
                style={inputStyle}
                autoFocus={autoFocus}
            />
            <label htmlFor={name} style={labelStyle}>
                {label}
            </label>
            <div onClick={rightIconAction} style={rightIconStyle}>
                {rightIcon}
            </div>
        </div>
    );
};

export default CustomInput;
