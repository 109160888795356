import React from "react";
import * as Styled from "./styled";
import { Link } from "react-router-dom";
import { maskToTime, maskDate } from "../../../../utils";
import { DOCUMENT_IMAGE } from "../../../../images";

const CourseCard = ({
  courseId = "",
  title = "",
  subTitle = "",
  image = "",
  duration = "",
  completionDate = "",
  onPlay = () => {},
  onRemove = null,
  certificates = "",
}) => {
  return (
    <Styled.Li image={image}>
      <Styled.ContentTop>
        {onRemove ? (
          <Link to="#" className="remove" onClick={onRemove}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Link>
        ) : null}
      </Styled.ContentTop>
      <Styled.ContentFooter>
        <div>
          <Styled.P>{title}</Styled.P>
          <Styled.Span>{subTitle}</Styled.Span>
          {completionDate && (
            <Styled.Time>
              {`Completo em ${maskDate(completionDate)}`}
            </Styled.Time>
          )}
          {duration && (
            <Styled.Time>
              <i className="fas fa-clock" />
              {maskToTime(duration, 2)}
            </Styled.Time>
          )}
        </div>
        <Styled.ContentBottoms>
          {certificates ? (
            <Styled.ButtonCertificate>
              <Link to={`/certificates/${courseId}`}>
                <img src={DOCUMENT_IMAGE} alt="certificate" />
              </Link>
            </Styled.ButtonCertificate>
          ) : (
            <Styled.ContentPlay>
              <Link to="#" onClick={onPlay}>
                <i className="fas fa-play" />
              </Link>
            </Styled.ContentPlay>
          )}
        </Styled.ContentBottoms>
      </Styled.ContentFooter>
    </Styled.Li>
  );
};

export default CourseCard;
