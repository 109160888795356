/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import ChangePassword from "../../../Containers/Modal/ChangePassword";
import UpdateProfile from "../../../Containers/Modal/UpdateProfile";
import { signOutFirebase } from "../../../../services";
import { removeToken } from "../../../../utils/Token";
import { getProfile } from "../../../../services/Graphql/Profile";
import * as Styled from "./styled";

const ContainerHeaderRight = ({ isGuest = true }) => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayMenuMobile, setDisplayMenuMobile] = useState(false);
  const [displayProfileModal, setDisplayProfileModal] = useState(false);
  const [displayChangePasswordModal, setDisplayChangePasswordModal] =
    useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    await signOutFirebase();
    setTimeout(function () {
      removeToken();
      history.push("/signin");
    }, 500);
  };

  const getUserProfile = useCallback(async () => {
    let response = await getProfile();

    if (!response.status) {
      setErrorMessage(true);
      return;
    }

    setUser({
      firstName:
        response.profile.surname !== "guest"
          ? response.profile.name
          : "Convidado",
      lastName:
        response.profile.surname !== "guest" ? response.profile.surname : "",
      email: response.profile.email,
    });

    setErrorMessage(false);
  }, [setErrorMessage]);

  const navBarMobile = () => {
    return (
      <>
        <Styled.IconMenuMobile>
          <i
            className="fas fa-bars"
            onClick={() => setDisplayMenuMobile(true)}
          />
        </Styled.IconMenuMobile>

        {displayMenuMobile ? (
          <Styled.Nav>
            <Styled.NavbarContent>
              <i
                className="fas fa-times"
                onClick={() => setDisplayMenuMobile(false)}
              />
              <Styled.NavContentMobile>
                <Styled.MenuProfileMobile>
                  <Styled.ProfileImageMobile>
                    <i className="fas fa-user"></i>
                  </Styled.ProfileImageMobile>
                  <Styled.P>
                    Bem vindo(a),
                    <br />
                    {user.firstName} {user.lastName}
                  </Styled.P>
                </Styled.MenuProfileMobile>
                <Styled.List>
                  <ul>
                    <li>
                      <NavLink
                        to="/certificates"
                        onClick={(e) => isGuest && e.preventDefault()}
                        isActive={(match) => (match ? true : false)}
                        activeClassName="active-item"
                        className={isGuest ? "blocked" : ""}
                      >
                        <i className="fas fa-scroll" />
                        <p>Certificados</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/achievements"
                        onClick={(e) => isGuest && e.preventDefault()}
                        isActive={(match) => (match ? true : false)}
                        activeClassName="active-item"
                        className={isGuest ? "blocked" : ""}
                      >
                        <i className="fas fa-trophy" />
                        <p>Conquistas</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        onClick={() => !isGuest && setDisplayProfileModal(true)}
                        isActive={(match) => (match ? true : false)}
                        className={isGuest ? "blocked" : ""}
                      >
                        <i className="fas fa-cogs" />
                        <p>Informações pessoais</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        onClick={() =>
                          !isGuest && setDisplayChangePasswordModal(true)
                        }
                        isActive={(match) => (match ? true : false)}
                        className={isGuest ? "blocked" : ""}
                      >
                        <i className="fas fa-user" />
                        <p>Alterar senha</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/" onClick={handleLogout}>
                        <i className="far fa-window-close" />
                        <p>Sair</p>
                      </NavLink>
                    </li>
                  </ul>
                </Styled.List>
              </Styled.NavContentMobile>
            </Styled.NavbarContent>
          </Styled.Nav>
        ) : null}
      </>
    );
  };

  const navBar = () => {
    return (
      <>
        <Styled.MenuProfile>
          <Styled.P>
            Bem vindo(a),
            <br />
            {user.firstName} {user.lastName}
          </Styled.P>
          <Styled.ProfileImage>
            <i className="fas fa-user"></i>
          </Styled.ProfileImage>

          <Styled.MenuButton
            onClick={() => setDisplayMenu((currentState) => !currentState)}
          >
            {displayMenu ? (
              <i className="fas fa-chevron-up" />
            ) : (
              <i className="fas fa-chevron-down" />
            )}
          </Styled.MenuButton>
        </Styled.MenuProfile>

        {displayMenu ? (
          <Styled.MenuPoupup>
            <Link to="#" onClick={() => setDisplayProfileModal(true)}>
              Informações pessoais
            </Link>
            <Link to="#" onClick={() => setDisplayChangePasswordModal(true)}>
              Alterar senha
            </Link>
            <Link to="/" onClick={handleLogout}>
              Sair
            </Link>
          </Styled.MenuPoupup>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  if (errorMessage) {
    return "";
  }

  if (isGuest) {
    return navBarMobile();
  }

  return (
    <>
      <Styled.Div>
        {navBar()}
        {navBarMobile()}
      </Styled.Div>
      <UpdateProfile
        display={displayProfileModal}
        setDisplay={setDisplayProfileModal}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
      />
      <ChangePassword
        display={displayChangePasswordModal}
        setDisplay={setDisplayChangePasswordModal}
      />
    </>
  );
};

export default ContainerHeaderRight;
