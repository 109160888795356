import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { validateToken } from "../../utils";
import Layout from "../../layout/External";
import AuthFormTemplate from "../../components/Presentational/Forms/AuthFormTemplate";
import FormAuthType from "../../components/Presentational/Forms/Signin/AuthType";
import EmailAuthentication from "../../components/Containers/Authentication/Email";
import PhoneAuthentication from "../../components/Containers/Authentication/Phone";

const DISPLAY = {
  formSigninType: true,
  formSigninEmail: false,
  formSigninPhone: false,
};

const SignIn = () => {
  const history = useHistory();
  const [displayForm, setDisplayForm] = useState(DISPLAY);

  const onClickButtonType = (type) => {
    setDisplayForm((data) => ({
      ...data,
      formSigninType: false,
      formSigninEmail: type === "email" ? true : false,
      formSigninPhone: type === "phone" ? true : false,
    }));
  };

  useEffect(() => {
    if (validateToken("ensina:sessionToken")) {
      let decoded = jwt_decode(validateToken("ensina:sessionToken"));

      if (decoded.firebase.sign_in_provider !== "anonymous") {
        history.push("/dashboard");
      }
    }
  }, [history]);

  return (
    <Layout>
      <AuthFormTemplate>
        {displayForm.formSigninType && (
          <FormAuthType onClickButtonType={onClickButtonType} />
        )}
        {displayForm.formSigninEmail && (
          <EmailAuthentication
            onClickBackForm={() => {
              setDisplayForm((data) => ({
                ...data,
                formSigninType: true,
                formSigninEmail: false,
              }));
            }}
          />
        )}
        {displayForm.formSigninPhone && (
          <PhoneAuthentication
            onClickBackForm={() => {
              setDisplayForm((data) => ({
                ...data,
                formSigninType: true,
                formSigninPhone: false,
              }));
            }}
          />
        )}
      </AuthFormTemplate>
    </Layout>
  );
};

export default SignIn;
