import styled from "styled-components";

export const Button = styled.button`
  height: 48px;
  width: 50%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 24px;
  font-family: var(--font-lexend-medium);
  background-color: ${(props) => props.theme.color};
  color: #fff;
  margin: 16px 0;
  cursor: pointer;
`;
