import React from "react";
import * as Styled from "./styled";

const Button = ({
  children,
  color = false,
  size = false,
  background = false,
  width = false,
  border = false,
  onClick,
}) => {
  return (
    <Styled.Button
      $color={color}
      $size={size}
      $background={background}
      $width={width}
      $border={border}
      onClick={onClick}
    >
      {children}
    </Styled.Button>
  );
};

export default Button;
