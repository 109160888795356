import React from "react";
import * as Styled from "./styled";
import reactStringReplace from "react-string-replace";
import { ICON_ERROR } from "../../../../images";

const AlertErrors = ({ children, errorMessage, successMessage }) => {
  return (
    <Styled.Content
      style={{
        border: !!errorMessage ? "solid 1px #dadce0" : "none",
      }}
    >
      {!!errorMessage && (
        <Styled.Error>
          <img src={ICON_ERROR} alt="error" />
          <div>
            {reactStringReplace(
              errorMessage,
              /(https?:\/\/\S+)/g,
              (match, i) => (
                <a
                  key={match + i}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={match}
                >
                  aqui
                </a>
              )
            )}
          </div>
        </Styled.Error>
      )}
      {children}
    </Styled.Content>
  );
};

export default AlertErrors;
