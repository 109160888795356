import React from "react";
import Layout from "../../layout/Inner";
import ListAchievements from "../../components/Containers/Courses/ListAchievements";
import ModalNewAchievements from "../../components/Containers/Modal/NewAchievements";
import * as Styled from "./styled";

const Achievements = () => {
  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>Conquistas</Styled.Title>
        <ListAchievements />
      </Styled.Container>
      <ModalNewAchievements />
    </Layout>
  );
};

export default Achievements;
