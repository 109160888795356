import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import CourseCard from "../../../Presentational/Cards/Course";
import ListCard from "../../../Presentational/Cards/List";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import { getCoursesByCategory } from "../../../../services/Graphql/Courses";
import { SHIMMER_SIZE } from "../../../../constants";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../../services/Analytics";

const ContainerCourseListCardByCategories = ({
  courseId,
  screenName = "",
  setDescription = () => {},
}) => {
  let history = useHistory();
  const [loader, setLoader] = useState(true);
  const [coursesList, setCoursesList] = useState([]);

  const getAllCourseByCategories = useCallback(
    async (id) => {
      if (id) {
        const response2 = await getCoursesByCategory(id);

        if (response2.courses.length > 0) {
          const [categories] = response2.courses[0].categories.filter(
            (c) => c.id === id
          );

          setDescription({
            id: categories.id,
            title: categories.title,
            size:
              response2.courses.length > 1
                ? `${response2.courses.length} cursos`
                : `${response2.courses.length} curso`,
          });
        }

        if (!response2.status) {
          setLoader(false);
          return;
        }
        setCoursesList(response2.courses ?? []);
      }

      setLoader(false);
    },
    [setDescription]
  );

  useEffect(() => {
    setDescription({});
    getAllCourseByCategories(courseId);
  }, [getAllCourseByCategories, setDescription, courseId]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <ListCard>
      {coursesList.map((item, index) => (
        <CourseCard
          key={index}
          title={item.title}
          subTitle={item.interestName}
          duration={item.duration}
          image={item.imageUrl}
          onPlay={() => {
            logAnalyticsEvent("curso_abrir", {
              categoria_id: item.categories[0].id,
              categoria_nome: item.categories[0].title,
              curso_id: item.id,
              curso_nome: item.title,
              screen_name: screenName,
              user_id: getParamsStorage("gaid"),
              skin: getParamsStorage("skin"),
            });
            history.push(`/course/video/${item.id}`);
          }}
        />
      ))}
    </ListCard>
  );
};

export default ContainerCourseListCardByCategories;
