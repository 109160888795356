import { makeRequest } from "../../../services/Graphql";
import { GET_CATEGORIES, GET_ALL_CATEGORIES } from "./queries";

export async function getCategories(isLoggedIn = false) {
  const response = await makeRequest(GET_CATEGORIES, {}, isLoggedIn, null);
  console.log("[success-get-categories]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  const categoriesInfo = response.courses
    .flatMap((c) => c["categories"])
    .map(({ id, title, imageUrl }) => [id, { id, title, imageUrl }]);

  const data = [...new Map(categoriesInfo).values()];

  return {
    status: true,
    categories: data,
  };
}

export async function getAllCategories() {
  const response = await makeRequest(GET_ALL_CATEGORIES, {}, false);
  console.log("[success-get-categories]: ", response.categories);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    categories: response.categories,
  };
}
