import { gql } from "graphql-request";

export const GET_QUIZ_BY_ID = gql`
  query ($id: String) {
    homeworks(chapterId: $id) {
      id
      title
      questions {
        question
        options
        answer
        explanation
      }
    }
  }
`;
