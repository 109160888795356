import axios from "axios";
import { translateMessage } from "../../utils/TranslateMessage";
import uuidv4 from "uuid/v4";
import {
  URL_BACKEND,
  URL_BACKEND_BFF,
  REQUESTER_NAME,
  BRAND_NAME,
  URL_CHAT,
} from "../../constants";

/**
 * Function Get Signature
 * @param {json} body
 */
export async function getSignature(token) {
  try {
    const response = await axios.get(`${URL_BACKEND}/consultPXSignatureV2`, {
      headers: {
        Authorization: token,
      },
    });

    console.log("[getSignature-response]:", response.data);
    return {
      status: true,
      hasSignature: response.data.hasSignature,
      isFirstLogin: response.data.isFirstLogin,
      isMigrated: response.data.isMigrated,
      customToken: response.data.customToken,
      campaignTitle: response.data.data.campaign,
      coursePackage: response.data.data.coursePackage,
    };
  } catch (error) {
    console.log("[getSignature-error]: ", error.response);

    return {
      status: false,
      message: translateMessage("No product found for user"),
    };
  }
}

/**
 * Function Subscribe Serial
 * @param {json} body
 */
export async function subscribeSerial(body) {
  try {
    console.log("[send-subscribe-serial]:", body);
    const response = await axios.post(
      `${URL_BACKEND_BFF}/bff/api/v2/subscription/subscribe-serial`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "X-FS-Correlation-ID": uuidv4(),
          "X-FS-Requester-Name": REQUESTER_NAME,
          "X-FS-Brand-Name": BRAND_NAME,
        },
      }
    );
    console.log("[success-subscribe-serial]:", response.data);
    return { status: true, subscribe: response.data };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, este serial não foi encontrado! Tente novamente!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}
