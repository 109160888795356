import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";

import // myCourses,
// getGoals,
// enrollUserToCourse,
// getInterests,
"../../../../services";
import { PUBLICITY_EXITO } from "../../../../images";

const SideBar = ({
  setLoader = () => {},
  setErrorMessage = () => {},
  displayGoalsModal = false,
  setDisplayGoalsModal = () => {},
}) => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  // const enrollToCourse = useCallback(
  //   async (courseId) => {
  //     setLoader(true);
  //     const response = await enrollUserToCourse({
  //       courseDetailsId: Number(courseId),
  //     });

  //     if (!response.status) {
  //       setLoader(false);
  //       setErrorMessage(response.message);
  //       return;
  //     }

  //     window.location = "/course/video/" + courseId;
  //   },
  //   [setLoader, setErrorMessage]
  // );

  // const getEnrolledCourses = useCallback(async () => {
  //   setLoader(true);
  //   let response = await myCourses(3);

  //   if (!response.status) {
  //     setLoader(false);
  //     setErrorMessage(response.message);
  //     return;
  //   }

  //   setEnrolledCourses(response.enrolledCourses ?? []);
  //   setErrorMessage(null);
  //   setLoader(false);
  // }, [setLoader, setErrorMessage]);

  // const getUserGoals = useCallback(async () => {
  //   setLoader(true);
  //   let response = await getGoals();

  //   if (!response.status) {
  //     setLoader(false);
  //     setErrorMessage(response.message);
  //     return;
  //   }

  //   const selected =
  //     (response.goals || []).find((item) => {
  //       return item.isSelected;
  //     }) ?? {};

  //   setSelectedGoal(selected);
  //   setGoals(response.goals ?? []);
  //   setErrorMessage(null);
  //   setLoader(false);
  // }, [setLoader, setErrorMessage]);

  // const getUserInterest = useCallback(async () => {
  //   setLoader(true);
  //   let response = await getInterests();

  //   if (!response.status) {
  //     setLoader(false);
  //     setErrorMessage(response.message);
  //     return;
  //   }

  //   setUserInterests(response.userInterests ?? []);
  //   setInterests(response.interestInfo ?? []);
  //   setErrorMessage(null);
  //   setLoader(false);
  // }, [setLoader, setErrorMessage]);

  // useEffect(() => {
  //   getUserGoals();
  //   getEnrolledCourses();
  //   getUserInterest();
  // }, [getUserGoals, getEnrolledCourses, getUserInterest]);

  useEffect(() => {
    setEnrolledCourses([]);
  }, []);

  return (
    <aside className="sidebar">
      {/* Goal */}
      {/* <section className="goal">
        <header>
          <i className="fas fa-bullseye gradient-icon" />
          <h1>Meu Objetivo</h1>
        </header>
        <p className="">{selectedGoal?.goalDescription}</p>
        <footer>
          <NavLink to="#" onClick={() => setDisplayGoalsModal(true)}>
            Alterar
          </NavLink>
        </footer>
      </section> */}
      {/* Enrolled Courses */}
      {enrolledCourses.length ? (
        <section className="courses-started">
          <header>
            <h1>Meus Cursos Iniciados</h1>
          </header>
          <div className="content">
            <ul className="list-courses">
              {enrolledCourses.map((item, index) => (
                <li key={index}>
                  <div
                    className="list-courses-progress"
                    style={{
                      width: `${
                        item.enrolledCourses?.courseCompletionStatus ?? 0
                      }%`,
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fas fa-play"
                      onClick={() => {
                        // enrollToCourse(item.courseInfo?.courseId);
                      }}
                    />
                    <div className="infos">
                      <p>{item.courseInfo?.courseName}</p>
                      <small>{item.courseInfo?.interestName}</small>
                    </div>
                  </div>
                  <span className="porcent">
                    {item.enrolledCourses?.courseCompletionStatus ?? 0}%
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </section>
      ) : null}
      {/* News */}
      <section className="news">
        <header>
          <h1>
            <strong>Novidades</strong>
          </h1>
        </header>
        <div style={{ marginTop: 20 }}>
          <a
            href="https://exito.app.toolzz.com.br/ensina"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PUBLICITY_EXITO} alt="News" />
          </a>
        </div>
      </section>
    </aside>
  );
};

export default SideBar;
