import React from "react";
import * as Styled from "./styled";

export default (props) => {
  return (
    <Styled.Section>
      <Styled.Div />
      <Styled.DivAuthForm>{props.children}</Styled.DivAuthForm>
    </Styled.Section>
  );
};
