import styled from "styled-components";
import { PBold } from "../../../../../styles";

export const Header = styled.header`
  button {
    align-self: center;
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
`;

export const Paragraph = styled(PBold)`
  margin-bottom: 20px;
`;
