import styled from "styled-components";
import { P, H3, Section } from "../../../styles";

export const Container = styled(Section)``;

export const Title = styled(H3)`
  margin-bottom: 20px;
`;

export const Paragraph = styled(P)``;

export const Content = styled.div`
  align-items: center;
  background-color: hsla(0, 0%, 94%, 1);
  border-radius: 6px;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  display: flex;
  font-family: var(--font-lexend-regular, Verdana, Geneva, Tahoma);
  padding: 20px;
  margin: 20px 0;
`;
