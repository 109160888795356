import React, { useEffect, useState, useCallback } from "react";
import ListCard from "../../../Presentational/Cards/List";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import CardAchievements from "../../../Presentational/Cards/Achievements";
import { getAchievements } from "../../../../services/Graphql/Achievements";
import { getAllUserAchievements } from "../../../../services/Graphql/Profile";
import { SHIMMER_SIZE } from "../../../../constants";

const ContainerListAchievements = () => {
  const [loader, setLoader] = useState(true);
  const [achievements, setAchievements] = useState([]);

  const getAllAchievements = useCallback(async () => {
    setLoader(true);
    let getAchievementsResponse = await getAchievements();

    if (!getAchievementsResponse.status) {
      setLoader(false);
      return;
    }

    let getUserAchievementsResponse = await getAllUserAchievements();

    if (!getUserAchievementsResponse.status) {
      setLoader(false);
      return;
    }

    const allAchievements = getAchievementsResponse.achievements;
    const userAchievements = getUserAchievementsResponse.achievements.map(
      ({ id }) => id
    );

    const achievementsList = allAchievements.map((item) => {
      if (userAchievements.includes(item.id)) {
        return item;
      }

      return {
        ...item,
        imageUrl: item.imageUrlDisabled,
      };
    });

    setAchievements(achievementsList);
    setLoader(false);
  }, []);

  useEffect(() => {
    getAllAchievements();
  }, [getAllAchievements]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <ListCard>
      {achievements.map((item, index) => (
        <CardAchievements
          key={index}
          imageUrl={item.imageUrl}
          title={item.title}
          description={item.description}
        />
      ))}
    </ListCard>
  );
};

export default ContainerListAchievements;
