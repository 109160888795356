import { makeRequest } from "../../../services/Graphql";
import {
  GET_PROFILE_BY_EMAIL,
  CREATE_PROFILE,
  UPDATE_PROFILE,
} from "./queries";

export async function getUserProfile(idToken) {
  const response = await makeRequest(GET_PROFILE_BY_EMAIL, {}, true, idToken);
  console.log("[success-getUserProfile]: ", response.profiles);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    profile: response.profiles,
  };
}

export async function createUserProfile(
  name,
  surname,
  email,
  phoneNumber,
  campaignTitle,
  coursePackage
) {
  const response = await makeRequest(
    CREATE_PROFILE,
    {
      data: {
        name,
        surname,
        email,
        phoneNumber,
        campaignTitle,
        coursePackage,
      },
    },
    false
  );

  console.log("[createUserProfile-response]:", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    profile: response.createProfile,
  };
}

export async function updateUserProfile(
  currentEmail,
  phoneNumber,
  name,
  surname,
  email,
  campaignTitle,
  coursePackage,
  idToken
) {
  const response = await makeRequest(
    UPDATE_PROFILE,
    {
      currentEmail,
      phoneNumber,
      data: {
        name,
        surname,
        email,
        campaignTitle,
        coursePackage,
      },
    },
    true,
    idToken
  );
  console.log("[updateUserProfile-response]:", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    profile: response.updateProfile,
  };
}
