import React from "react";
import * as Styled from "./styled";

const AchievementsCard = ({ imageUrl = "", title = "", description = "" }) => {
  return (
    <Styled.Badges>
      <Styled.Image src={imageUrl} alt="badge" />
      <Styled.Content>
        <Styled.Paragraph>{title}</Styled.Paragraph>
        <Styled.Small>{description}</Styled.Small>
      </Styled.Content>
    </Styled.Badges>
  );
};

export default AchievementsCard;
