import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.div`
  text-align: center;
`;

export const ButtonBack = styled.button`
  color: ${(props) => props.theme.color};
  font-size: 16px;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;
