import styled from "styled-components";
import BANNER from "../../../images/files/banner.png";
import SCREEN from "../../../images/files/screen.png";

export const Banner = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${BANNER});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  @media only screen and (max-width: 768px) {
    background-image: url(${SCREEN});
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media only screen and (max-width: 768px) {
    position: fixed;
    bottom: 20px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;
