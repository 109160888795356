import styled from "styled-components";

export const Nav = styled.nav`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: row;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: #fffcfd;
    width: 100%;
    padding: 5px;
  }

  > div {
    padding: 5px 20px;
    text-align: center;

    p {
      font-size: 10px;
      color: #878787;
    }

    i {
      color: #878787;
    }
  }
`;
