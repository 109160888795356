import styled from "styled-components";

export const Article = styled.article`
  margin: 0 auto;
  max-width: 1440px;
  width: 100vw;
  display: grid;
  grid-template-areas: "nav main sidebar";
  grid-template-columns: 240px 1fr 350px;
  padding-top: 30px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 50px;

    grid-template-areas:
      "main"
      "sidebar";
    grid-template-columns: 1fr;
  }
`;

export const Container = styled.div`
  border-right: 1px solid var(--color-line-division, hsla(0, 3%, 90%, 1));
  grid-area: main;
`;
