import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { maskDate } from "../../../../utils";
import ModalCertificate from "../../../Containers/Modal/Certificate";
import { ICON_CHECK, DOCUMENT_IMAGE, ICON_PADLOCK } from "../../../../images";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../../services/Analytics";
import * as Styled from "./styled";

const CardBundleList = ({ rows = [], bundleId = "", bundleName = "" }) => {
  const history = useHistory();
  const [modalCertificate, setModalCertificate] = useState(false);
  const [courseId, setCourseId] = useState(false);

  const onClickModalCertificate = (courseId) => {
    setCourseId(courseId);
    setModalCertificate(true);

    logAnalyticsEvent("trilha_certificado", {
      curso_id: courseId,
      screen_name: "CourseTrials",
      user_id: getParamsStorage("gaid"),
      skin: getParamsStorage("skin"),
    });
  };

  const progressBar = (data) => (
    <Styled.ProgressBar
      onClick={() => {
        logAnalyticsEvent("trilha_abrir", {
          trilha_id: bundleId,
          trilha_nome: bundleName,
          curso_id: data.id,
          curso_nome: data.title,
          screen_name: "CourseTrials",
          user_id: getParamsStorage("gaid"),
          skin: getParamsStorage("skin"),
        });
        history.push(`/course/video/${data.id}`);
      }}
    >
      <Styled.LineTop />
      <Styled.CircleCenter
        isCompleted
        style={
          data.isCompleted
            ? {}
            : {
                backgroundColor: "transparent",
                border: "1px solid rgba(0,0,0,.2)",
              }
        }
      >
        {data.isCompleted ? <img src={ICON_CHECK} alt="check" /> : null}
      </Styled.CircleCenter>
      <Styled.LineBottom />
    </Styled.ProgressBar>
  );

  const courseList = (data) => (
    <Styled.CourseInfo
      onClick={() => {
        logAnalyticsEvent("trilha_abrir", {
          trilha_id: bundleId,
          trilha_nome: bundleName,
          curso_id: data.id,
          curso_nome: data.title,
          screen_name: "CourseTrials",
          user_id: getParamsStorage("gaid"),
          skin: getParamsStorage("skin"),
        });
        history.push(`/course/video/${data.id}`);
      }}
    >
      <Styled.Image src={data.imageUrl} alt="course" />
      <Styled.Content>
        <Styled.ContentDetail>
          <Styled.Title>{data.title}</Styled.Title>
        </Styled.ContentDetail>
        <Styled.ContentButtons>
          <img src={ICON_PADLOCK} alt="lock" />
        </Styled.ContentButtons>
      </Styled.Content>
    </Styled.CourseInfo>
  );

  const certificateList = (data) => (
    <Styled.CourseInfo onClick={() => onClickModalCertificate(data.id)}>
      <Styled.Image src={data.imageUrl} alt="course" />
      <Styled.Content>
        <Styled.ContentDetail>
          <Styled.Title>{data.title}</Styled.Title>
          <Styled.SubTitle>
            Completo em
            <br />
            {maskDate(data.finishedAt)}
          </Styled.SubTitle>
        </Styled.ContentDetail>
        <Styled.ContentButtons>
          <Styled.ButtonCertificate>
            <img src={DOCUMENT_IMAGE} alt="certificate" />
          </Styled.ButtonCertificate>
        </Styled.ContentButtons>
      </Styled.Content>
    </Styled.CourseInfo>
  );

  return (
    <Styled.Bundle>
      {rows.map((response, key) => {
        return (
          <Styled.Container key={key}>
            {progressBar(response)}

            {response.isCompleted
              ? certificateList(response)
              : courseList(response)}
          </Styled.Container>
        );
      })}

      {modalCertificate && (
        <ModalCertificate
          courseId={courseId}
          setDisplay={() => setModalCertificate(false)}
        />
      )}
    </Styled.Bundle>
  );
};

export default CardBundleList;
