import React, { useEffect } from "react";
import { NATIVE_APP } from "./constants";
import Routes from "./routes";

export const App = () => {
  const askTrackingPermission = () => {
    if (window.cordova) {
      console.log("trying to request permission ");
      window.cordova.exec(win, fail, "idfa", "requestPermission", []);
    }
    function win(res) {
      console.log("success " + JSON.stringify(res));
    }
    function fail(res) {
      console.log("fail " + JSON.stringify(res));
    }
  };

  const readTrackingPermission = () => {
    if (window.cordova) {
      window.cordova.exec(win, fail, "idfa", "getInfo", []);
    }
    function win(res) {
      console.log("success  " + JSON.stringify(res));
    }
    function fail(res) {
      console.log("fail " + JSON.stringify(res));
    }
  };

  useEffect(() => {
    if (NATIVE_APP) {
      askTrackingPermission();
      readTrackingPermission();
    }
  });

  return <Routes />;
};
