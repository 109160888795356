/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  getRatingByCourse,
  updateRatingCourse,
} from "../../../services/Graphql/Rating";
import Spinner from "../../../components/Presentational/Spinner";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../services/Analytics";
import * as Styled from "./styled";

const Rating = ({ courseId, screenName = "" }) => {
  const [start, setStart] = useState(0);
  const [mid, setMid] = useState(0);
  const [end, setEnd] = useState(0);
  const [loader, setLoader] = useState(true);
  const [courseName, setCourseName] = useState();

  const getRateCourse = useCallback(async (courseId) => {
    const response = await getRatingByCourse(courseId);

    if (!response.status) {
      return;
    }

    setCourseName(response.ratings.course?.title);
    setLoader(false);
    setStart(Math.floor(response.score));
    setMid(response.score - Math.floor(response.score) ? 1 : 0);
    setEnd(5 - Math.ceil(response.score));
  }, []);

  const rateCourse = useCallback(
    async (rating, courseName) => {
      const response = await updateRatingCourse(courseId, rating);

      if (!response.status) {
        return;
      }

      logAnalyticsEvent("curso_avaliar", {
        curso_id: courseId,
        curso_nome: courseName,
        avaliacao: rating,
        screen_name: screenName,
        user_id: getParamsStorage("gaid"),
        skin: getParamsStorage("skin"),
      });

      getRateCourse(courseId);
    },
    [courseId]
  );

  useEffect(() => {
    getRateCourse(courseId);
  }, []);

  if (loader) {
    return <Spinner />;
  }

  return (
    <Styled.Rating>
      <Styled.Container>
        {Array.from({ length: start }).map((item, index) => (
          <Styled.Icon
            className="fas fa-star"
            key={index}
            onClick={() => rateCourse(index + 1, courseName)}
          />
        ))}
        {mid ? (
          <Styled.Icon
            className="fas fa-star-half-alt"
            key={mid}
            onClick={() => rateCourse(start + mid, courseName)}
          />
        ) : null}
        {Array.from({ length: end }).map((item, index) => (
          <Styled.Icon
            className="far fa-star"
            key={index}
            onClick={() => rateCourse(start + mid + index + 1, courseName)}
          />
        ))}
      </Styled.Container>
      <Styled.Paragraph>Avalie Agora</Styled.Paragraph>
    </Styled.Rating>
  );
};

export default Rating;
