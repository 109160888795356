/**
 * Function Parse Split Url
 * @param {string} parseSplitUrl
 * @returns
 */
export function parseSplitUrl(url) {
  if (url) {
    const parse = url.split("/", 5);
    return parse[4].replace("?h=", "-");
  }

  return false;
}
