import React from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../../Button/Primary";
import * as Styled from "./styled";

const CategoryCard = ({ categoryId = "", name = "", image = "" }) => {
  const history = useHistory();
  return (
    <Styled.Card
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url("${image}")`,
      }}
    >
      <Styled.Container>
        <Styled.Title>{name}</Styled.Title>
        <ButtonPrimary onClick={() => history.push(`/explore/${categoryId}`)}>
          Ver cursos
        </ButtonPrimary>
      </Styled.Container>
    </Styled.Card>
  );
};

export default CategoryCard;
