import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ListCard from "../../../Presentational/Cards/List";
import CourseCard from "../../../Presentational/Cards/Course";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import {
  getAllUserCourseProgress,
  getAllUserCourseFavorites,
} from "../../../../services/Graphql/Profile";
import { SHIMMER_SIZE } from "../../../../constants";
import { ICON_START_COURSE } from "../../../../images";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../../services/Analytics";

import * as Styled from "./styled";

const ContainerListCardCourseEnrolled = ({ screenName = "" }) => {
  let history = useHistory();
  const [loader, setLoader] = useState(true);
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const getCourses = useCallback(async () => {
    setLoader(true);
    const getAllUserCourseProgressResponse = await getAllUserCourseProgress();

    if (!getAllUserCourseProgressResponse.status) {
      setLoader(false);
      return;
    }

    const getAllUserCourseFavoritesResponse = await getAllUserCourseFavorites();

    if (!getAllUserCourseFavoritesResponse.status) {
      setLoader(false);
      return;
    }

    setEnrolledCourses(
      getAllUserCourseProgressResponse.userCourseProgress.filter(
        (course) => course.finished === false
      )
    );

    setLoader(false);
  }, []);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  if (loader) {
    return <ShimmerEffect size={SHIMMER_SIZE} />;
  }

  return (
    <>
      {enrolledCourses.length ? (
        <section className="box-courses">
          <ListCard>
            {enrolledCourses.map((item, index) => (
              <CourseCard
                key={index}
                courseId={item.course.id}
                title={item.course.title}
                subTitle={item.course.categories[0].title}
                duration={item.course.duration}
                image={item.course.imageUrl}
                onPlay={() => {
                  logAnalyticsEvent("curso_abrir", {
                    categoria_id: item.course.categories[0].id,
                    categoria_nome: item.course.categories[0].title,
                    curso_id: item.course.id,
                    curso_nome: item.course.title,
                    screen_name: screenName,
                    user_id: getParamsStorage("gaid"),
                    skin: getParamsStorage("skin"),
                  });
                  history.push(`course/video/${item.course.id}`);
                }}
              />
            ))}
          </ListCard>
        </section>
      ) : (
        <Styled.NotFound>
          <Styled.Image src={ICON_START_COURSE} alt="course" />
          <Styled.Paragraph>
            Aqui você encontra todos os cursos que estão em progresso
          </Styled.Paragraph>
        </Styled.NotFound>
      )}
    </>
  );
};

export default ContainerListCardCourseEnrolled;
