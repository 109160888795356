import styled from "styled-components";

export const Rating = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Icon = styled.i`
  color: ${(props) => props.theme.color};
  cursor: pointer;
  font-size: 1.4rem;
`;

export const Paragraph = styled.p`
  margin-top: 10px;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
`;
