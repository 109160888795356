import React from "react";
import Layout from "../../layout/Inner";
import CoursesListCardTrail from "../../components/Containers/Courses/ListTrailCard";
import TrailsModal from "../../components/Containers/Modal/TrailsModal";
import * as Styled from "./styled";

const KnowledgeTrail = () => {
  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>Trilhas de aprendizagem</Styled.Title>
        <CoursesListCardTrail screenName="KnowledgeTrails" />
      </Styled.Container>
      <TrailsModal />
    </Layout>
  );
};

export default KnowledgeTrail;
