import React from "react";
import * as Styled from "./styled";
import { TERMS_OF_USE, PROJECT_NAME, PRIVACY_POLICY } from "../../../constants";

const date = new Date();

const Footer = () => (
  <Styled.Footer>
    <Styled.FooterCenter>
      <Styled.Container>
        <small>
          © {date.getFullYear()} {PROJECT_NAME} - Todos os direitos reservados
        </small>
        <Styled.Terms>
          <Styled.Item href="/#/help">Ajuda</Styled.Item>
          <Styled.Item href={TERMS_OF_USE} target="_blank">
            Termos de uso
          </Styled.Item>
          <Styled.Item href={PRIVACY_POLICY} target="_blank">
            Política de Privacidade
          </Styled.Item>
        </Styled.Terms>
      </Styled.Container>
    </Styled.FooterCenter>
  </Styled.Footer>
);

export default Footer;
