/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

const ThemeContext = ({ children }) => {
  const [theme, setTheme] = useState({});

  const sleep = (delay) => {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  };

  const getFromLocalStorage = async () => {
    let theme = {
      color: null,
      logoUrl: null,
    };

    while (!theme.color || !theme.logoUrl) {
      theme = {
        color: localStorage.getItem("color"),
        logoUrl: localStorage.getItem("logo"),
      };

      await sleep(50);
    }

    return theme;
  };

  useEffect(() => {
    getFromLocalStorage().then((response) => {
      setTheme(response);
    });
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContext;
