import styled from "styled-components";
import { H1 } from "../../../../styles";

export const Section = styled.section`
  background-color: #efefef;
  padding: 60px 0 32px 0;
  position: relative;
  margin-bottom: 180px;

  @media screen and (max-width: 414px) {
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 80px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;

  > img {
    position: absolute;
    border-radius: 10px;
    height: 580px;
    right: 0;
    top: -56px;
    pointer-events: none;
  }

  @media screen and (max-width: 414px) {
    > img {
      position: absolute;
      height: 350px;
    }
  }
`;

export const Content = styled.div`
  align-items: flex-start;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  justify-content: center;

  > h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--color-text);
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
`;

export const Title = styled(H1)`
  margin-top: 50px;
`;

export const Container = styled.div`
  max-width: 1000px;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 414px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
