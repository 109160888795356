import styled from "styled-components";

export const ContentGuest = styled.div`
  width: 100%;
  background-color: #e5e5e5;

  p {
    font-size: 12px;
    color: var(--color-text);
    text-align: center;
    padding: 8px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Header = styled.header`
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  padding: 20px 30px;
  width: 100%;
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 768px) {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    padding: 20px 30px;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    background-color: ${(props) => props.theme.color};
    display: flex;
  }
`;

export const Content = styled.section`
  margin: 0 auto;
  width: 100vw;
  display: flex;
  max-width: 1400px;

  @media (max-width: 1440px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex: 1;
    margin: 0 auto;
    flex-direction: row-reverse;
  }
`;

export const ContentLogo = styled.div`
  align-items: center;
  margin-right: auto;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContentLogoMobile = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const DivGuest = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonGuest = styled.button`
  padding: 8px 24px;
  background-color: white;
  color: ${(props) => props.theme.color};
  border: 2px solid ${(props) => props.theme.color};
  font-family: var(--font-lexend-medium);
  margin-right: 5px;
  border-radius: 23px;
  cursor: pointer;
  font-size: 14px;
`;

export const ButtonGuestTransparent = styled.button`
  padding: 8px 24px;
  background-color: ${(props) => props.theme.color};
  color: white;
  border: 2px solid ${(props) => props.theme.color};
  font-family: var(--font-lexend-medium);
  margin-right: 5px;
  border-radius: 23px;
  cursor: pointer;
  font-size: 14px;
`;
