import styled from "styled-components";

export const Bundle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  display: flex;
  font-family: var(--font-lexend-regular, Verdana, Geneva, Tahoma);
`;

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.6rem;
  cursor: pointer;
`;

export const LineTop = styled.div`
  width: 1px;
  height: calc(50% - 7.5px);
  background-color: rgba(0, 0, 0, 0.2);
`;

export const LineBottom = styled.div`
  width: 1px;
  height: calc(50% - 7.5px);
  background-color: rgba(0, 0, 0, 0.2);
`;

export const CircleCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.theme.color};
  border: none;
  border-radius: 100%;
`;

export const CourseInfo = styled.div`
  border: 1px solid #f0f0f0;
  display: flex;
  flex: 1 1;
  height: 100px;
  margin: 12px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: table;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 140px;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  padding: 18px;
`;

export const Title = styled.p`
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  font-family: var(--font-lexend-semi-bold, Verdana, Geneva, Tahoma);
  font-size: 14px;
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.color};
  font-family: var(--font-lexend-medium, Verdana, Geneva, Tahoma);
  font-size: 12px;
`;

export const ContentButtons = styled.div`
  align-self: center;
  margin-right: 20px;
`;

export const ButtonCertificate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color};
  height: 40px;
  width: 40px;
  border-radius: 20px;
`;
