import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

export default ({
  courseId,
  nextChapter,
  nextLesson,
  display,
  title,
  color,
  message,
  videoRef,
  showButtonSuccess,
  onClickModalDisplay,
}) => {
  const history = useHistory();

  const urlRedirect =
    nextLesson !== "0"
      ? `/course/video/${courseId}/${videoRef}/${nextChapter}/${nextLesson}`
      : `/course/video/${courseId}`;

  const colorSkin = localStorage.getItem("color");

  return (
    <div className="modal" style={{ display: `${display ? "block" : "none"}` }}>
      <div className="modal-content">
        <div
          className="modal-header"
          style={{
            backgroundColor: `${color}`,
            textAlign: "center",
            color: "#ffffff",
          }}
        >
          {title}
        </div>
        <div style={{ padding: 15 }}>
          <p>{message}</p>
        </div>
        {showButtonSuccess ? (
          <button
            onClick={() => history.push(urlRedirect)}
            style={{
              backgroundColor: colorSkin,
              color: "#fff",
              fontSize: 14,
              border: "none",
              marginTop: 20,
              width: "100%",
              padding: 15,
            }}
          >
            Pronto
          </button>
        ) : (
          <button
            onClick={onClickModalDisplay}
            style={{
              backgroundColor: colorSkin,
              color: "#fff",
              fontSize: 14,
              border: "none",
              marginTop: 20,
              width: "100%",
              padding: 15,
            }}
          >
            Tentar novamente
          </button>
        )}
      </div>
    </div>
  );
};
