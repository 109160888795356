import React from "react";
import Spinner from "../Spinner";
import InfiniteScroll from "react-infinite-scroll-component";

const InfiniteScrollList = ({
  children,
  fetchMore = () => {},
  hasMore,
  loader,
  data,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={() => fetchMore()}
      hasMore={hasMore}
      loader={loader && <Spinner />}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollList;
