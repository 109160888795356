import React from "react";
import Loader from "react-fullscreen-loading";

export default ({ display }) => {
  return (
    <Loader
      loading={display}
      background={"#fff"}
      loaderColor={"var(--color-primary)"}
    />
  );
};
