/**
 * Function Validator Time
 * @param {int} value
 */
export function maskTime(value, type = null) {
  const hours = Math.floor(value / 60);
  const remainingMinutes = value % 60;

  switch (type) {
    case 1:
      return `${hours}h ${remainingMinutes}m`;

    default:
      return `${hours} horas e ${remainingMinutes} minutos`;
  }
}

/**
 * Function Validator Time
 * @param {int} value
 */
export function maskToTime(duration, type = null) {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  let maskTime = "";

  if (hours) {
    maskTime += `${hours}h`;
  }

  if (minutes) {
    maskTime += ` ${minutes}m`;
  }

  if (seconds && !hours) {
    maskTime += ` ${seconds}s`;
  }

  return maskTime;
}
