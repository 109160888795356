import React from "react";
import * as Styled from "./styled";
import ReactHtmlParser from "react-html-parser";

const FormTemplate = ({
  children,
  textPrimary,
  textSecondary,
  onSubmit = () => {},
}) => {
  return (
    <Styled.Form onSubmit={onSubmit}>
      <Styled.Div>
        <Styled.TextPrimary>{ReactHtmlParser(textPrimary)}</Styled.TextPrimary>
        <Styled.TextSecondary>
          {ReactHtmlParser(textSecondary)}
        </Styled.TextSecondary>
      </Styled.Div>
      {children}
    </Styled.Form>
  );
};

export default FormTemplate;
