import React, { useState } from "react";
import Swiper from "react-id-swiper";
import Button from "../../../Presentational/Button";
import ModalSignUp from "../../../Containers/Modal/Signup";
import ModalSignin from "../../../Containers/Modal/Signin";
import IMAGE from "./images/exito.png";
import IMAGE1 from "./images/empreendedor.jpg";
import IMAGE2 from "./images/fotografia.jpg";
import IMAGE3 from "./images/programador.jpg";
import IMAGE4 from "./images/ensina-banner-materno-site-02.png";
import IMAGE5 from "./images/ensina-banner-financas-site-02.png";
import * as Styled from "./styled";
import "swiper/swiper.min.css";

const SimpleSwiper = () => {
  const [display, setDisplay] = useState(false);

  const params = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
  };

  return (
    <>
      <Styled.Banner>
        <Swiper {...params}>
          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE4})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Desperte a mulher <br />
                  independente que <br /> há em você!
                </Styled.Title>
                <Styled.Paragraph>
                  Empreenda em casa e no seu tempo <br />
                  livre com os cursos do Ensina.
                </Styled.Paragraph>
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>

          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE5})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Faça de 2022 <br /> o ano da sua virada <br />
                  financeira com o Ensina!
                </Styled.Title>
                <Styled.Paragraph>
                  Aprenda a controlar seus gastos, planejar seu ano e investir.
                </Styled.Paragraph>
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>

          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Agora você tem acesso <br />
                  também aos mais de <br />
                  200 cursos do Instituto <br />
                  Êxito de Empreendedorismo.
                </Styled.Title>
                <ModalSignin
                  title="Experimente já!"
                  style={{
                    color: "var(--color-primary)",
                  }}
                />
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>

          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE1})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Acesse conteúdo incrivel <br /> para expandir seu conhecimento{" "}
                  <br /> e tornar-se um empreendedor_
                </Styled.Title>
                <ModalSignin
                  title="Experimente já!"
                  style={{
                    color: "var(--color-primary)",
                  }}
                />
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>

          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE2})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Acesse conteúdo incrível <br />
                  para expandir seu conhecimento <br /> e tornar um fotógrafo_
                </Styled.Title>
                <ModalSignin
                  title="Experimente já!"
                  style={{
                    color: "var(--color-primary)",
                  }}
                />
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>

          <Styled.Content
            style={{
              backgroundImage: `linear-gradient( to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4) ), url(${IMAGE3})`,
            }}
          >
            <Styled.Container>
              <Styled.ContentLeft>
                <Styled.Title>
                  Acesse conteúdo incrível <br />
                  para expandir seu conhecimento <br /> e tornar um programador_
                </Styled.Title>
                <ModalSignin
                  title="Experimente já!"
                  style={{
                    color: "var(--color-primary)",
                  }}
                />
              </Styled.ContentLeft>
              <Styled.ContentRight>
                <Styled.Title>
                  Acesse já <br /> e inicie seus estudos
                </Styled.Title>
                <Button onClick={() => setDisplay(true)}>
                  Comece a aprender
                </Button>
                <ModalSignin />
              </Styled.ContentRight>
            </Styled.Container>
          </Styled.Content>
        </Swiper>
      </Styled.Banner>
      <ModalSignUp
        buttonStatus
        display={display}
        isClose={() => setDisplay(false)}
      />
    </>
  );
};

export default SimpleSwiper;
