import { gql } from "graphql-request";

export const GET_RATING_BY_COURSE_ID = gql`
  query ($courseId: ID!) {
    ratings(courseId: $courseId) {
      profile {
        id
        name
      }
      course {
        id
        title
      }
      score
    }
  }
`;

export const UPDATE_RATING_COURSE = gql`
  mutation ($courseId: ID!, $score: Int!) {
    createRating(courseId: $courseId, score: $score) {
      id
      profile {
        name
        surname
      }
      course {
        duration
      }
      score
    }
  }
`;
