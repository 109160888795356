import styled from "styled-components";

export const Nav = styled.nav`
  border-right: 1px solid var(--color-line-division, hsla(0, 3%, 90%, 1));
  padding: 30px;
  grid-area: nav;

  div {
    span {
      display: block;
      font-size: 0.8rem;
      margin-bottom: 16px;
    }

    ul {
      list-style: none;

      li + li {
        margin-top: 14px;
      }

      li a {
        align-items: center;
        color: var(--color-text, hsla(0, 0%, 0%, 1));
        display: flex;
        font-size: 1rem;
        text-decoration: none;

        &:hover p,
        &.active-item p {
          color: ${(props) => props.theme.color};
        }

        &:hover i,
        &.active-item i {
          color: ${(props) => props.theme.color};
        }

        i {
          margin-right: 10px;
        }
      }
    }
  }
`;
