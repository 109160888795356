import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ModalTemplate from "../../../Presentational/Modal";
import Button from "../../../Presentational/Button";
import ButtonDefault from "../../../Presentational/Button/Default";
import Loader from "../../../Presentational/Loader";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import { signInAnonymously } from "../../../../services/Firebase";
import {
  createUserProfile,
  getUserProfile,
} from "../../../../services/Graphql/User";
import { saveSessionTokens, validateToken } from "../../../../utils";
import { logAnalyticsEvent } from "../../../../services/Analytics";
import { PROJECT_NAME } from "../../../../constants";
import * as Styled from "./styled";
import image1 from "./images/image1.svg";
import image2 from "./images/image2.svg";
import image3 from "./images/image3.svg";

const ModalSignIn = ({
  buttonType,
  buttonName,
  buttonStatus,
  display = false,
  setDisplay = () => {},
  isClose = () => {},
}) => {
  const history = useHistory();
  // const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    logAnalyticsEvent("LandingPageSignup");

    if (validateToken("ensina:sessionToken")) {
      setIsLoggedIn(true);
    }
  }, []);

  const onStartLearning = (e) => {
    e.preventDefault();

    logAnalyticsEvent("fluxo_visitante_entrar_inicio", {
      screen_name: "LandingPageSignup",
    });

    if (isLoggedIn) {
      history.push("/dashboard");
    }
    setDisplay(true);
  };

  const handleLogin = async (linkWithEmailPassword) => {
    setLoader(true);
    setErrorMessage(null);

    const signInAnonymouslyResponse = await signInAnonymously({
      returnSecureToken: true,
    });

    if (!signInAnonymouslyResponse.status) {
      setLoader(false);
      setDisplay(true);
      setErrorMessage(signInAnonymouslyResponse.message);
      return;
    }

    try {
      await createUserProfile(
        `guest.${signInAnonymouslyResponse.firebaseUid}`,
        "guest",
        `guest.${signInAnonymouslyResponse.firebaseUid}@ensina.com.br`,
        "",
        "ENSINA-BETA",
        "ensina-beta"
      );
    } catch (error) {
      console.log("error", error);
    }

    const response2 = await getUserProfile(
      signInAnonymouslyResponse.sessionToken
    );

    if (!response2.status) {
      setLoader(false);
      setDisplay(true);
      setErrorMessage(response2.message);
      return;
    }

    localStorage.setItem("isGuest", true);
    localStorage.setItem("color", response2.profile[0].campaign.skin.color);
    localStorage.setItem("logo", response2.profile[0].campaign.skin.logoUrl);
    localStorage.setItem(
      "transparentLogoUrl",
      response2.profile[0].campaign.skin.transparentLogoUrl
    );

    saveSessionTokens(
      signInAnonymouslyResponse.sessionToken,
      signInAnonymouslyResponse.refreshToken
    );

    logAnalyticsEvent("fluxo_visitante_entrar_fim", {
      screen_name: "LandingPageSignup",
    });

    if (linkWithEmailPassword) {
      setTimeout(async () => {
        history.push("/signup");
      }, 500);
    } else {
      setTimeout(async () => {
        history.push("/dashboard");
      }, 500);
    }
  };

  const onSkipRegistration = (e) => {
    e.preventDefault();
    setDisplay(false);
    handleLogin(false);
  };

  const onRegistration = (e) => {
    e.preventDefault();
    history.push("/signup");
  };

  return (
    <>
      {!buttonStatus &&
        (buttonType ? (
          <Button size background color onClick={onStartLearning}>
            {buttonName}
          </Button>
        ) : (
          <Button size onClick={onStartLearning}>
            {buttonName}
          </Button>
        ))}
      <Loader display={loader} />
      {display && (
        <ModalTemplate isClose={isClose}>
          <Styled.Container>
            <Styled.Description>
              <Styled.H1>Por que se cadastrar no {PROJECT_NAME}?</Styled.H1>
            </Styled.Description>
            <Styled.Content>
              <Styled.Div>
                <Styled.Image src={image1} alt="" />
                <Styled.Paragraph>
                  Acesso ao seu progresso em todos os dispositivos
                </Styled.Paragraph>
              </Styled.Div>
              <Styled.Div>
                <Styled.Image src={image2} alt="" />
                <Styled.Paragraph>
                  Obtenha certificados e compartilhe com quem quiser
                </Styled.Paragraph>
              </Styled.Div>
              <Styled.Div>
                <Styled.Image src={image3} alt="" />
                <Styled.Paragraph>
                  Receba recomendações de acordo com seu histórico e interesses
                </Styled.Paragraph>
              </Styled.Div>
            </Styled.Content>
            <ErrorMessage
              display={!!errorMessage}
              onCloseErrorMessage={() => setErrorMessage(null)}
              message={errorMessage}
            />
            <ButtonDefault onClick={onRegistration}>
              Cadastrar agora
            </ButtonDefault>
            <Styled.Button onClick={onSkipRegistration}>
              Lembrar depois
            </Styled.Button>
          </Styled.Container>
        </ModalTemplate>
      )}
    </>
  );
};

export default ModalSignIn;
