import styled from "styled-components";

export const Card = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  min-height: 160px;
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  color: #ffffff;
  font-family: var(--font-lexend-bold, Verdana, Geneva, Tahoma, sans-serif);
  font-size: 20px;
  margin: 8px 0;
  text-align: center;
`;
