import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Styled from "./styled";
import Layout from "../../../layout/Inner";
import ShimmerEffect from "../../../components/Presentational/ShimmerEffect";
import Video from "../../../components/Presentational/Video/Vimeo";
import ButtonFavorite from "../../../components/Containers/Buttons/Favorites";
import ButtonCertificate from "../../../components/Containers/Buttons/Certificate";
import Rating from "../../../components/Containers/Rating";
import CardChapters from "../../../components/Presentational/Cards/Chapters";
import { getCourseById } from "../../../services/Graphql/Courses";
import {
  updateCourseProgress,
  getUserCourseProgress,
} from "../../../services/Graphql/Profile";
import { maskToTime, parseSplitUrl } from "../../../utils";
import {
  logAnalyticsEvent,
  getParamsStorage,
} from "../../../services/Analytics";
import "./styles.css";

const CoursesVideo = (props) => {
  const playerRef = useRef();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [courseDuration, setCourseDuration] = useState(0);
  const [videoRef, setVideoRef] = useState(0);
  const [totalHomeworks, setTotalHomeworks] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [courseId, setCourseId] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [lessonId, setLessonId] = useState("");
  const [course, setCourse] = useState({});
  const [chapters, setChapters] = useState([]);
  const [userProgressChapters, setUserProgressChapters] = useState([]);
  const [completedCourse, setCompletedCourse] = useState(false);
  const [allLessons, setallLessons] = useState([]);

  const getCoursesById = useCallback(
    async (courseId, videoRef, chapterId, lessonId) => {
      const response = await getCourseById(courseId);

      if (!response.status) {
        setErrorMessage(response.message);
        return;
      }

      const { course } = response;
      const { duration } = course;
      const { chapters } = course;

      if (!videoRef && !chapterId) {
        let chapterId = course.chapters[0].id;
        let lessonId = course.chapters[0].lessons[0].id;
        let videoRef = parseSplitUrl(course.chapters[0].lessons[0].url);
        localStorage.setItem("play", false);
        history.push(
          `/course/video/${courseId}/${videoRef}/${chapterId}/${lessonId}`
        );
        return;
      }

      let allVideoData = course.chapters.flatMap((item) =>
        [...item.homeworks, ...item.lessons].sort((a, b) => a.index - b.index)
      );

      setCourseId(courseId);
      setChapterId(chapterId);
      setLessonId(lessonId);
      setChapters(chapters);
      setCourse(course);
      setallLessons(allVideoData);
      setCourseDuration(duration);
      setVideoRef(videoRef);
      setTotalVideos(course.chapters.map((c) => c.lessons).flat().length);
      setTotalHomeworks(course.chapters.map((c) => c.homeworks).flat().length);

      localStorage.setItem("courseId", courseId);
      localStorage.setItem("videoRef", videoRef);
      localStorage.setItem("chapterId", chapterId);
      localStorage.setItem("lessonId", lessonId);

      localStorage.setItem(
        "nextVideoIndex",
        videoRef
          ? allVideoData.findIndex(
              (index) => parseSplitUrl(index.url) === videoRef
            ) + 1
          : 1
      );
    },
    [history]
  );

  const getProgressByUserCourse = useCallback(async (courseId) => {
    const response = await getUserCourseProgress(courseId);

    if (!response.status) {
      setErrorMessage(response.message);
      return;
    }

    setCompletedCourse(response.userCourseCompleted);
    setUserProgressChapters(response.userCourseProgress);
  }, []);

  const onClickLesson = (lesson, chapter, courseId) => {
    setChapterId(chapter.id);
    setVideoRef(parseSplitUrl(lesson.url));

    const nextVideoIndex = allLessons.findIndex(
      (index) => index.id === lesson.id
    );

    localStorage.setItem("nextVideoIndex", Number(nextVideoIndex) + 1);
    localStorage.setItem("courseId", courseId);
    localStorage.setItem("videoRef", parseSplitUrl(lesson.url));
    localStorage.setItem("chapterId", chapter.id);
    localStorage.setItem("lessonId", lesson.id);
    localStorage.setItem("play", true);

    logAnalyticsEvent("curso_comecar", {
      capitulo_id: chapter?.id,
      capitulo_nome: chapter?.title,
      aula_id: lesson?.id,
      aula_nome: lesson?.title,
      curso_id: course?.id,
      curso_nome: course?.title,
      screen_name: "CourseVideo",
      user_id: getParamsStorage("gaid"),
      skin: getParamsStorage("skin"),
    });

    history.push(
      `/course/video/${courseId}/${parseSplitUrl(lesson.url)}/${chapter.id}/${
        lesson.id
      }`
    );

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onClickHomeWork = (homeworkID, chapterID) => {
    const lessonIndex = allLessons.findIndex(
      (index) => index.id === homeworkID
    );

    const chapterIndex = course.chapters.findIndex(
      (index) => index.id === chapterID
    );

    let videoRef = parseSplitUrl(allLessons[Number(lessonIndex) + 1].url);
    let nextChapter = chapters[Number(chapterIndex) + 1].id;
    let nextLesson = allLessons[Number(lessonIndex) + 1].id;
    localStorage.setItem("nextVideoIndex", Number(lessonIndex + 1));
    localStorage.setItem("play", true);

    setTimeout(() => {
      history.push(
        `/course/quizzes/${homeworkID}/${chapterID}/${courseId}/${videoRef}/${nextChapter}/${nextLesson}`
      );
    }, 1500);
  };

  const onEndedChapterVideo = useCallback(
    async (sessionCourseId, sessionChapterId, sessionLessonId) => {
      let nextVideoIndex = localStorage.getItem("nextVideoIndex");

      const response = await updateCourseProgress({
        courseId: sessionCourseId,
        chapterId: sessionChapterId,
        lessonId: sessionLessonId,
        currentTime: 0,
        finished: true,
      });

      logAnalyticsEvent("curso_concluir", {
        capitulo_id: sessionChapterId,
        capitulo_nome: "",
        aula_id: sessionLessonId,
        aula_nome: "",
        curso_id: sessionCourseId,
        curso_nome: "",
        screen_name: "CourseVideo",
        user_id: getParamsStorage("gaid"),
        skin: getParamsStorage("skin"),
      });

      if (!response.status) {
        setErrorMessage(response.message);
        return;
      }

      if (!!parseSplitUrl(allLessons[nextVideoIndex].url)) {
        setVideoRef(parseSplitUrl(allLessons[nextVideoIndex].url));
      } else {
        localStorage.setItem("nextVideoIndex", Number(nextVideoIndex) + 2);

        const chapterIndex = course.chapters.findIndex(
          (index) => index.id === sessionChapterId
        );

        let homeworkId = allLessons[nextVideoIndex].id;
        let videoRef = !!allLessons[Number(nextVideoIndex) + 1]
          ? parseSplitUrl(allLessons[Number(nextVideoIndex) + 1].url)
          : "0";
        let nextLesson = !!allLessons[Number(nextVideoIndex) + 1]
          ? allLessons[Number(nextVideoIndex) + 1].id
          : "0";
        let nextChapter = !!chapters[Number(chapterIndex) + 1]
          ? chapters[Number(chapterIndex) + 1].id
          : "0";

        history.push(
          `/course/quizzes/${homeworkId}/${sessionChapterId}/${sessionCourseId}/${videoRef}/${nextChapter}/${nextLesson}`
        );
        return;
      }

      localStorage.setItem("nextVideoIndex", Number(nextVideoIndex) + 1);
      localStorage.setItem("play", true);

      await getProgressByUserCourse(sessionCourseId);

      const chapterIndex = course.chapters.findIndex(
        (index) => index.id === sessionChapterId
      );

      await getCoursesById(
        sessionCourseId,
        parseSplitUrl(allLessons[nextVideoIndex].url),
        chapters[chapterIndex].id,
        allLessons[nextVideoIndex].id
      );
    },
    [
      allLessons,
      chapters,
      course.chapters,
      getCoursesById,
      getProgressByUserCourse,
      history,
    ]
  );

  useEffect(() => {
    let courseId = props.match.params.courseId;
    let videoRef = props.match.params.videoRef;
    let chapterId = props.match.params.chapterId;
    let lessonId = props.match.params.lessonId;

    getCoursesById(courseId, videoRef, chapterId, lessonId);
    getProgressByUserCourse(courseId);
  }, [
    getCoursesById,
    props.match.params.courseId,
    props.match.params.videoRef,
    props.match.params.chapterId,
    props.match.params.lessonId,
    props.match.params.nextVideo,
    getProgressByUserCourse,
    props.match.params.id,
    props.match.params.nextVideo,
  ]);

  const titleVideo = () => <Styled.H1>{course?.title}</Styled.H1>;

  const descriptionVideo = () => (
    <Styled.Paragraph>{course?.description}</Styled.Paragraph>
  );

  const settingsButtons = () => (
    <>
      <ButtonFavorite courseId={courseId} screenName={"CourseVideo"} />
      {completedCourse && (
        <ButtonCertificate courseId={courseId} screenName={"CourseVideo"} />
      )}
    </>
  );

  const ratingVideo = () => (
    <Rating courseId={props.match.params.courseId} screenName={"CourseVideo"} />
  );

  const boxItems = () => (
    <Styled.InfoBox>
      <Styled.ItemBox>
        <Styled.Strong>
          {courseDuration && maskToTime(courseDuration)}
        </Styled.Strong>
        <Styled.Small>Tempo total</Styled.Small>
      </Styled.ItemBox>
      <Styled.ItemBox>
        <Styled.Strong>{totalVideos}</Styled.Strong>
        <Styled.Small>Vídeos</Styled.Small>
      </Styled.ItemBox>
      <Styled.ItemBox>
        <Styled.Strong>{totalHomeworks}</Styled.Strong>
        <Styled.Small>Exercícios</Styled.Small>
      </Styled.ItemBox>
    </Styled.InfoBox>
  );

  return (
    <Layout errorMessage={errorMessage}>
      <Styled.Container>
        <Styled.Video>
          {videoRef ? (
            <Video
              ref={playerRef}
              videoRef={videoRef}
              onEndedChapterVideo={onEndedChapterVideo}
            />
          ) : (
            <ShimmerEffect type={"image"} />
          )}
        </Styled.Video>
        <Styled.Description>
          <Styled.Title>
            <div>{titleVideo()}</div>
            <Styled.Buttons>{settingsButtons()}</Styled.Buttons>
          </Styled.Title>
          {descriptionVideo()}
          <Styled.Box>
            {ratingVideo()}
            {boxItems()}
          </Styled.Box>
        </Styled.Description>

        <Styled.DescriptionMobile>
          <Styled.ContainerMobile>
            {titleVideo()}
            {ratingVideo()}
          </Styled.ContainerMobile>
          <hr />
          <Styled.ContainerMobile>
            <Styled.ContentMobile>{settingsButtons()}</Styled.ContentMobile>
            <Styled.ContentMobile>
              <Styled.Paragraph>
                Toque no icone play para iniciar o curso. Quer aprender mais
                tarde? Salve o curso em sua lista. <br />
              </Styled.Paragraph>
            </Styled.ContentMobile>
          </Styled.ContainerMobile>
          <hr />
          <div style={{ margin: 15 }}>{boxItems()}</div>
          <hr />
          <Styled.ContainerMobile>
            <Styled.H1>Descrição</Styled.H1>
            <Styled.Paragraph>{course?.description}</Styled.Paragraph>
          </Styled.ContainerMobile>
          <hr />
        </Styled.DescriptionMobile>

        <Styled.ChapterContainer>
          <Styled.ChapterHeader>
            <Styled.H1>Conteúdo</Styled.H1>
          </Styled.ChapterHeader>
          <ul className="list-chapters">
            {chapters.length > 0 ? (
              <CardChapters
                chapters={chapters}
                videoRef={videoRef}
                chapterId={chapterId}
                courseId={courseId}
                lessonId={lessonId}
                userProgressChapters={userProgressChapters}
                onClickHomeWork={onClickHomeWork}
                onClickLesson={onClickLesson}
              />
            ) : (
              <ShimmerEffect type={"image"} size={6} />
            )}
          </ul>
        </Styled.ChapterContainer>
      </Styled.Container>
    </Layout>
  );
};

export default CoursesVideo;
