import React, { useState } from "react";
import firebase from "firebase/app";
import ModalTemplate from "../../../Presentational/Modal";
import ErrorMessage from "../../../Presentational/Alerts/Errors";
import SuccessMessage from "../../../Presentational/Alerts/Success";
import ButtonPrimary from "../../../Presentational/Button/Primary";
import Input from "../../../Presentational/Input";
import Spinner from "../../../Presentational/Spinner";
import { updatePassword } from "../../../../services";
import { ICON_VISIBILITY_ON, ICON_VISIBILITY_OFF } from "../../../../images";

const ChangePassword = ({ display = false, setDisplay = () => {} }) => {
  const [loader, setLoader] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmNewPasswordVisibility, setNewConfirmPasswordVisibility] =
    useState(false);

  const onCloseModal = () => {
    setDisplay(false);
    setPasswordData({});
  };

  const isValidInput = () => {
    if (
      passwordData.oldPassword &&
      passwordData.newPassword &&
      passwordData.confirmNewPassword &&
      passwordData.newPassword === passwordData.confirmNewPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setPasswordData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setErrorMessage("As senhas nao conferem, tente novamnete!");
      return;
    }

    setLoader(true);
    setErrorMessage(null);

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const response = await updatePassword({
          email: user.email,
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword,
        });
        if (!response.status) {
          setLoader(false);
          setErrorMessage(response.message);
          return;
        }

        setSuccessMessage("Senha alterada com sucesso!");
        setPasswordData({});
        setLoader(false);

        setTimeout(() => {
          setSuccessMessage("");
        }, 2500);
      }
    });
  };

  return (
    <>
      {display && (
        <ModalTemplate isClose={onCloseModal}>
          <div className="modal-form" style={{ textAlign: "center" }}>
            <form onSubmit={changePassword}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 12,
                  marginBottom: 16,
                }}
              >
                <p style={{ fontFamily: "LexendBold" }}>Alterar senha</p>
              </div>
              <Input
                label="Digite sua senha atual"
                name="oldPassword"
                type={oldPasswordVisibility ? "text" : "password"}
                value={passwordData.oldPassword}
                required={true}
                onChange={handleChangeForm}
                rightIconAction={() => {
                  oldPasswordVisibility
                    ? setOldPasswordVisibility(false)
                    : setOldPasswordVisibility(true);
                }}
                rightIcon={
                  oldPasswordVisibility ? (
                    <img src={ICON_VISIBILITY_OFF} alt="icon" />
                  ) : (
                    <img src={ICON_VISIBILITY_ON} alt="icon" />
                  )
                }
              />
              <Input
                label="Digite sua nova senha"
                name="newPassword"
                type={newPasswordVisibility ? "text" : "password"}
                value={passwordData.newPassword}
                required={true}
                onChange={handleChangeForm}
                rightIconAction={() => {
                  newPasswordVisibility
                    ? setNewPasswordVisibility(false)
                    : setNewPasswordVisibility(true);
                }}
                rightIcon={
                  newPasswordVisibility ? (
                    <img src={ICON_VISIBILITY_OFF} alt="icon" />
                  ) : (
                    <img src={ICON_VISIBILITY_ON} alt="icon" />
                  )
                }
              />
              <Input
                label="Confirme sua nova senha"
                name="confirmNewPassword"
                type={confirmNewPasswordVisibility ? "text" : "password"}
                value={passwordData.confirmNewPassword}
                required={true}
                onChange={handleChangeForm}
                rightIconAction={() => {
                  confirmNewPasswordVisibility
                    ? setNewConfirmPasswordVisibility(false)
                    : setNewConfirmPasswordVisibility(true);
                }}
                rightIcon={
                  confirmNewPasswordVisibility ? (
                    <img src={ICON_VISIBILITY_OFF} alt="icon" />
                  ) : (
                    <img src={ICON_VISIBILITY_ON} alt="icon" />
                  )
                }
              />
              <ErrorMessage
                display={!!errorMessage}
                onCloseErrorMessage={() => setErrorMessage(null)}
                message={errorMessage}
              />
              {!!successMessage && <SuccessMessage message={successMessage} />}

              {loader ? (
                <Spinner />
              ) : (
                <ButtonPrimary
                  disabled={isValidInput() ? false : true}
                  style={{ opacity: isValidInput() ? 1 : 0.5 }}
                >
                  Alterar senha
                </ButtonPrimary>
              )}
            </form>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default ChangePassword;
