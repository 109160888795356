import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--color-text, hsla(0, 0%, 100%, 1));

  @media only screen and (max-width: 768px) {
  }
`;

export const Description = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
  }
`;

export const H1 = styled.h1`
  font-family: LexendBold;
  font-size: 16px;
`;

export const Paragraph = styled.p`
  font-family: var(--font-lexend-regular);
  font-size: 0.8rem;
  text-align: center;
  height: 60px;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;

  @media only screen and (max-width: 768px) {
    display: grid;
  }
`;

export const Div = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Image = styled.img`
  margin-bottom: 12px;
  height: 120px;

  @media only screen and (max-width: 768px) {
    height: 70px;
    width: 90px;
  }
`;

export const Button = styled.button`
  color: var(--color-primary);
  font-family: var(--font-lexend-medium);
  margin: 10px auto;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
`;
