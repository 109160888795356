import React from "react";
import { Link } from "react-router-dom";
import * as Styled from "./styled";
import InputPincode from "../../../../Input/Pincode";

const FormValidatePincode = ({
  handleSubmitForm = () => {},
  handleChangeForm = () => {},
  handleClickFormBack = () => {},
  phone,
  pincode = "",
  children,
  errorMessage,
}) => {
  const isValidInput = () => {
    if (pincode.length === 6) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Styled.Form onSubmit={handleSubmitForm}>
      <Styled.Paragraph>
        Digite o código que enviamos ao seu número {phone}
      </Styled.Paragraph>
      <InputPincode
        name="pincode"
        type="phone"
        value={pincode}
        required={true}
        onChange={handleChangeForm}
      />
      <span
        style={{ alignSelf: "center", marginTop: 20 }}
        onClick={handleClickFormBack}
      >
        Alterar número
      </span>
      <Styled.ContentButtons
        style={{
          border: errorMessage ? "solid 1px #dadce0" : "none",
        }}
      >
        {children}
        <button
          disabled={isValidInput() ? false : true}
          style={{ opacity: isValidInput() ? 1 : 0.5 }}
        >
          Validar
        </button>
        <Link to="/signup" style={{ alignSelf: "center" }}>
          Não tem uma conta?
        </Link>
      </Styled.ContentButtons>
    </Styled.Form>
  );
};

export default FormValidatePincode;
