import React from "react";
import Layout from "../../layout/Inner";
import CoursesListCards from "../../components/Containers/Courses/ListCardCourse";
import * as Styled from "./styled";

const Dashboard = () => {
  return (
    <Layout>
      <Styled.Container>
        <Styled.Title>Mais acessados</Styled.Title>
        <CoursesListCards screenName="Home" />
      </Styled.Container>
    </Layout>
  );
};

export default Dashboard;
