import React, { useState, useEffect } from "react";
import ModalSignUp from "../../../Containers/Modal/Signup";
import ShimmerEffect from "../../../Presentational/ShimmerEffect";
import { getAllCategories } from "../../../../services/Graphql/Categories";
import * as Styled from "./styled";

const BannerCategoryCourse = () => {
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const getCategories = async () => {
    const response = await getAllCategories();
    if (!response.status) {
      setLoader(false);
      setCategories([]);
      return;
    }
    setCategories(response.categories);
    setLoader(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const cardCategoryCourse = () => {
    return (
      <Styled.List>
        {categories.slice(0, 6).map((item, index) => (
          <Styled.SubList
            key={index}
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${item.imageUrl})`,
            }}
          >
            <Styled.ContentList>
              <Styled.SubTitle>{item.title}</Styled.SubTitle>
              <ModalSignUp buttonName="Ver cursos" />
            </Styled.ContentList>
          </Styled.SubList>
        ))}
      </Styled.List>
    );
  };

  return (
    <Styled.Section>
      <Styled.Header>
        <Styled.Title>Encontre o seu próximo curso</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        {loader ? <ShimmerEffect size={2} /> : cardCategoryCourse()}
      </Styled.Content>
      <Styled.ContentButton>
        <ModalSignUp buttonName="Ver todos os cursos" buttonType={true} />
      </Styled.ContentButton>
    </Styled.Section>
  );
};

export default BannerCategoryCourse;
