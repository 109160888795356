import React from "react";
import Layout from "../../layout/External";
import "./styles.css";

const TermsConditions = () => {
  return (
    <Layout>
      <section className="box-terms">
        <header>
          <h1>
            <strong>TERMOS E CONDIÇÕES DE USO</strong>
          </h1>
          <br />
          <hr />
        </header>
        <section>
          <p>
            INFORMAÇÃO IMPORTANTE PARA TODOS OS USUÁRIOS: LEIA ATENTAMENTE O
            TERMOS E CONDIÇÕES DE USO (“TERMO”) ANTES DE COMEÇAR A USAR.
          </p>

          <p>
            A UTILIZAÇÃO DO APLICATIVO REPRESENTA A CONCORDÂNCIA TÁCITA E
            INTEGRAL COM O PRESENTE TERMO. CASO NÃO ESTEJA DE ACORDO COM TODOS
            OS TERMOS E CONDIÇÕES, CANCELE A INSTALAÇÃO E/OU NÃO INSTALE O
            APLICATIVO.
          </p>

          <p>
            O USUÁRIO está ciente e concorda que ao prosseguir a partir deste
            ponto de acesso, com a utilização do Serviço Ensina by FS, estará
            concordando, de forma expressa, irrevogável e irretratável, com
            todas as disposições constantes do presente Termo, declarando ter
            lido, compreendido e aceitado o mesmo em todos os seus termos e
            obrigando-se a cumprir todas as disposições.
          </p>

          <p>
            A FS SECURITY SERVIÇOS DE TECNOLOGIA S.A., sociedade empresária
            inscrita no CNPJ sob o nº 10.971.907/0001-51, com sede na Av. Luiz
            Carlos Berrini, 1645, 1º andar, Bairro Cidade Monções, Município de
            São Paulo, Estado de São Paulo, CEP: 04571-011, é a empresa
            desenvolvedora e titular do ENSINA by FS (também designada como
            “FS”, neste documento).
          </p>

          <p>Mais informações em https://fsensina.com.br.</p>

          <p>
            <strong>OBJETIVO</strong>
          </p>

          <p>
            O presente Termo estabelece as características, opções e formas de
            contratação, bem como responsabilidades de utilização e outras
            informações relacionadas ao serviço FS Ensina, bem como os direitos,
            restrições e ofertas promocionais aplicáveis ao mesmo.
          </p>

          <p>
            <strong>O APLICATIVO FS Ensina</strong>
          </p>

          <p>
            O serviço FS Ensina permite ao USUÁRIO, através do aplicativo para
            smartphone, tablet e website, acesso a cursos livres de
            aperfeiçoamento profissional e conhecimentos gerais. Os cursos são
            compostos, conforme o caso, por videoaulas, questionários e
            certificados emitidos pelo FS Ensina.
          </p>

          <p>
            O USUÁRIO, ao contratar o FS Ensina, permite o download ou acesso
            online mediante conexão de sua operadora ou qualquer outra conexão
            de internet. Como o produto possui estrutura independente, ele
            atende às operações da VIVO, CLARO, OI, TIM e operações do varejo.
          </p>

          <p>
            O serviço FS Ensina está disponível no link https://fsensina.com.br
            ou, ainda, através de outros canais, sites e lojas de
            disponibilização e venda de aplicativos, através da busca com o nome
            FS Ensina.
          </p>

          <p>
            O referido aplicativo está sujeito a limites de acesso, podendo a
            respectiva operadora, de acordo com o presente Termo, restringir
            e/ou direcionar o acesso, a seu exclusivo critério.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            por qualquer inaptidão do USUÁRIO em se conectar à internet ou usar
            o FS Ensina, e expressamente declara não existir quaisquer tipos de
            garantias de satisfação das expectativas não declaradas e próprias
            de cada USUÁRIO sobre eventuais oportunidades de negócios fundadas
            no desempenho do serviço disponibilizado, tampouco existindo
            quaisquer tipos de garantias no que se refere à adequação do serviço
            disponibilizado a qualquer objetivo em particular.
          </p>

          <p>
            A FS reserva-se o direito de alterar o presente Termo a qualquer
            momento, no todo ou em parte, independentemente de notificação
            prévia, sendo que a continuidade do USUÁRIO em usar o FS Ensina,
            após tais modificações, será presumida como a continuidade do
            acesso. É de responsabilidade do USUÁRIO acessar regularmente a
            página web aonde se encontra o presente Termo, a fim de verificar
            eventuais modificações no presente instrumento. Caso o USUÁRIO não
            concorde com quaisquer modificações introduzidas, deve cessar
            imediatamente sua utilização.
          </p>

          <p>
            <strong>ELEGIBILIDADE</strong>
          </p>

          <p>
            O acesso para autenticação e utilização deve ser elegível para todos
            os clientes da FS ou das operadoras anteriormente citadas, os quais,
            ao prosseguir no cadastramento e download do FS Ensina, declaram ser
            maiores de idade e capazes da prática de atos da vida civil nos
            termos da legislação em vigor.{" "}
          </p>

          <p>
            O serviço está disponível nas seguintes plataformas: (a) para
            smartphones: através de aplicativo nativo disponível aos USUÁRIOS
            nos diversos sites, canais de venda e nas lojas de aplicativos tais
            como Apple Store e Google Play; (b) para desktop: através de portal
            web (https://fsensina.com.br).
          </p>

          <p>
            <strong>O CADASTRO E CONDUTA ONLINE</strong>
          </p>

          <p>
            O USUÁRIO compromete-se a fornecer informações verídicas durante seu
            cadastramento no FS Ensina. Caso seja constatada a transmissão de
            informações falsas, sua operadora, de acordo com este Termo, poderá
            interromper a qualquer momento a prestação dos serviços. O USUÁRIO
            compromete-se desde já a manter seus dados atualizados sempre que
            houver modificações nos mesmos.
          </p>

          <p>
            O USUÁRIO concorda em utilizar o serviço apenas para finalidades não
            vedadas em lei. Concorda em não replicar e/ou criar qualquer
            conteúdo ou transmitir qualquer informação ou material, com base no
            aplicativo, que (i) seja falso ou leve a interpretações dúbias; (ii)
            invadindo a privacidade de outros USUÁRIOS; (iii) prejudique de
            alguma forma USUÁRIOS ou terceiros; (iv) promova, sob alguma forma,
            o racismo ou o preconceito contra grupo de minorias, ou qualquer
            forma de fanatismo político ou religioso, discriminando grupos de
            pessoas ou etnias; (v) seja obsceno ou ilegal; (vi) viole direitos
            de terceiros, incluindo, mas não se limitando a direitos de
            propriedade intelectual, e/ou a criação e envio de mensagens não
            solicitadas ('spam') ou infundadas ('hoax').
          </p>

          <p>
            Sem prejuízo das disposições deste Termo, as operadoras VIVO, CLARO,
            OI, TIM e a FS reservam-se o direito de impedir o uso e acesso do
            USUÁRIO aos serviços disponibilizados quando constatar o uso
            indevido do serviço, consideradas as hipóteses (i) a (vi) expostas
            acima, ou quaisquer outras que prejudiquem o objetivo ao qual se
            prestam ou prejudiquem a comunidade Internet em geral.
          </p>

          <p>
            O USUÁRIO reconhece que todo conteúdo que publicamente for exposto
            ou privativamente transmitido, inclusive através de e-mail,
            mensagens curtas de texto ('SMS') ou inseridas em formulários WAP, é
            de responsabilidade exclusiva do USUÁRIO que criou tal conteúdo,
            sendo o mesmo o único a responder por tais conteúdos, observadas as
            proibições e ressalvas constantes deste Termo.
          </p>

          <p>
            O USUÁRIO declara estar ciente que é o único responsável pelo uso do
            serviço/aplicativo quando habilitado em sua conta e que, portanto,
            deverá notificar a respectiva operadora sobre qualquer uso indevido
            do Aplicativo.
          </p>

          <p>
            O USUÁRIO está ciente e concorda que o Serviço é para uso pessoal
            estando vedada qualquer utilização comercial do mesmo seja a que
            título for.
          </p>

          <p>
            O USUÁRIO está ciente e declara que nenhum material enviado através
            de sua conta, ou que seja publicado ou transmitido, infringe
            direitos da operadora, da FS ou de terceiros, quaisquer que sejam,
            incluindo, mas não se limitando a direitos de propriedade, direitos
            autorais, materiais, morais, derivados, consequenciais etc.
          </p>

          <p>
            O USUÁRIO garante ainda, que se houver qualquer transmissão de
            informações ou conteúdos de propriedade de terceiros, que detém as
            autorizações e licenças necessárias, não constituindo qualquer
            violação a direitos pessoais, patrimoniais ou quaisquer de sejam
            pertencentes a outrem.
          </p>

          <p>
            <strong>
              DA PROPRIEDADE INTELECTUAL E LIMITAÇÃO DA LICENÇA DO APLICATIVO
            </strong>
          </p>

          <p>
            Todos os conteúdos e o aplicativo propriamente dito, bem como,
            clipes de áudio, clipes de imagens, fotografias, gráficos, conteúdo
            editorial, marcas e logotipos disponibilizados nos sites do produto,
            ou através de seus aplicativos, são protegidos pelas leis de
            propriedade intelectual aplicáveis e por outras leis, incluindo, sem
            limitação, Código Civil e Lei de Direitos Autorais. Estes direitos
            pertencem à FS, ou foram concedidos a elas por seus titulares, de
            tal forma que a FS, e somente esta e as operadoras anteriormente
            citadas, podem se utilizar deste material, conforme acordado com
            seus proprietários, não sendo qualquer direito estendido aos
            USUÁRIOS do aplicativo que deverão atender a todas as disposições
            legais no respeito ao uso adequado e conforme limites impostos do
            aplicativo e dos sites.
          </p>

          <p>
            Ao acessar o aplicativo ou qualquer um dos sites (seja da operadora,
            seja do FS Ensina), o USUÁRIO declara que irá respeitar todos os
            direitos de propriedade intelectual e industrial, os decorrentes da
            proteção de marcas registradas da mesma, bem como de todos os
            direitos autorais referentes a terceiros que por ventura estejam, ou
            estiveram, de alguma forma disponíveis no Portal. Ao USUÁRIO não é
            conferido qualquer direito de uso dos nomes, títulos, palavras,
            frases, marcas, patentes, obras literárias, artísticas, musicais,
            dentre outras, que nele estejam, ou estiveram, disponíveis.
          </p>

          <p>
            O USUÁRIO está ciente e concorda que não poderá utilizar tais
            materiais ou informações exclusivas sob nenhuma forma, salvo para a
            utilização do serviço aqui tratado em conformidade com as condições
            do presente Termo. É proibida a reprodução de qualquer parte do
            serviço aqui tratado sob qualquer forma ou meio, salvo conforme
            expressamente permitido sob o presente instrumento, incluindo, mas,
            não se limitando à apropriação ou sobrecarga da capacidade de rede
            do Serviço.
          </p>

          <p>
            O USUÁRIO concorda em não modificar, alugar, arrendar, emprestar,
            vender, distribuir ou criar obras derivadas ou baseadas no serviço
            prestado pelo aplicativo sob qualquer forma, bem como em não
            explorar o serviço sob qualquer forma não autorizada.
          </p>

          <p>
            É vedado o uso do FS Ensina para finalidades comerciais,
            publicitárias ou qualquer outra que contrarie a realidade para o
            qual foi concebido, conforme definido neste Termo.
          </p>

          <p>
            A utilização do aplicativo ou de qualquer outra parte do serviço
            aqui tratado, salvo para fins de utilização na forma permitida pelo
            presente Termo, é expressamente proibida e viola os direitos de
            propriedade intelectual da FS e das operadoras anteriormente
            citadas, e/ou de terceiros, podendo o USUÁRIO sujeitar-se a
            indenizações pecuniárias por violação de direito autoral, além das
            demais penas cabíveis.
          </p>

          <p>
            O USUÁRIO assume toda e qualquer responsabilidade, de caráter civil
            e/ou criminal, pela utilização indevida das informações, textos,
            gráficos, marcas, obras, enfim, todo e qualquer direito de
            propriedade intelectual ou industrial deste Portal.
          </p>

          <p>
            <strong>RESPONSABILIDADE LIMITADA</strong>
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            pelas ofertas publicitárias e pelo conteúdo disponível nos banners,
            pop-ups, janelas, links e outras formas de publicidade, sites etc.,
            caso tais ofertas sejam feitas por terceiros que não as referidas
            operadoras, suas controladoras, subsidiárias, parceiras ou
            coligadas, respectivamente.
          </p>

          <p>
            As operadoras anteriormente citadas e a FS tomarão todas as
            providências, dentro do possível, para prevenir a introdução de
            vírus ou outros materiais destrutivos. Também não garantem o acesso
            ininterrupto ou livre de erros, decorrentes de interrupção por
            vírus, ataques de hackers, erros de script, corrupção de arquivos,
            pirataria, quebra de segurança, programas incompatíveis ou outros
            quaisquer que impeçam a utilização dos serviços, não assumindo
            qualquer responsabilidade por danos diretos ou indiretos causados em
            virtude do acesso aos sites ou por impossibilidade de acessá-los.
          </p>

          <p>
            É de responsabilidade total das operadoras citadas e da FS, suas
            controladoras, controladas ou coligadas no tocante a todas as ações
            e reclamações relacionadas a este Termo ou a qualquer serviço
            fornecido sob este instrumento, independentemente do fundamento de
            referidas ações e reclamações, não excederá o valor do preço dos
            serviços contratados, não havendo qualquer vínculo na contratação do
            USUÁRIO com terceiros.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS, suas controladoras,
            controladas ou coligadas, respectivamente, não serão responsáveis
            solidariamente por reclamações ou procedimentos judiciais impetrados
            por qualquer USUÁRIO contra os USUÁRIOS que desrespeitarem as normas
            deste Termo ou as normas da Política de Privacidade do FS Ensina.
          </p>

          <p>
            Não obstante qualquer disposição em contrário contida neste Termo,
            em hipótese alguma as operadoras anteriormente citadas e a FS, suas
            controladoras, controladas e/ou coligadas serão responsáveis,
            independentemente do motivo ou razão, por qualquer espécie de perdas
            e danos especiais, consequências ou indiretos, incluindo, mas não se
            limitando a, lucros cessantes, danos morais, perda de economias
            previstas, perda de contratos, perda de negócios, perda de fundo de
            comércio ou ações de terceiros, ainda que referidas perdas e danos
            fossem razoavelmente previsíveis ou ainda que a FS tenha sido
            avisada da possibilidade de ocorrência de referidas perdas e danos.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            por danos aos USUÁRIOS que acessarem sites com endereços similares
            aos sites, mas que não sejam administrados pelas respectivas
            operadoras, nem que não sejam do FS Ensina, e não estejam sob as
            regras deste Termo.
          </p>

          <p>
            Ao prosseguir na utilização do serviço, o USUÁRIO concorda
            expressamente, de forma irrevogável e irretratável, bem como está
            ciente de que sua operadora e a FS não terão quaisquer
            responsabilidades, sejam contratual e/ou extracontratual, por
            quaisquer danos patrimoniais ou morais, incluindo, sem limitação,
            danos por lucros cessantes, perda de fundo de comércio ou de
            informações ou outras perdas intangíveis resultantes do: (i) uso ou
            incapacidade de usar o serviço; (ii) custo de aquisição de bens ou
            serviços, informações e dados pelo ou através deste serviço, ou
            mensagens de recebimento, ou transações estabelecidas com o uso
            deste serviço; (iii) acesso não autorizado às transmissões ou
            informações do usuário, bem como da alteração destes; (iv)
            orientações ou condutas de terceiros sobre o serviço; (v) por
            motivos de força maior ou caso fortuito e atos praticados pelo
            próprio usuário.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI, TIM e a FS não se responsabilizam em
            qualquer hipótese por devolução de valores eventualmente cobrados
            que resultem: (i) de incompatibilidade entre elementos de hardware e
            software empregados pelo usuário com os serviços prestados (ii) do
            equipamento do usuário não atender aos requisitos mínimos
            computacionais e técnicos para uso do serviço; (iii) de erro
            atribuível somente ao usuário quanto as suas expectativas do
            serviço; (iv) da não observação, por parte do usuário, dos
            procedimentos estabelecidos no presente Termo e nas demais
            instruções fornecidas neste portal; (v) de atos de má-fé e/ou
            fraude; (vi) pela desistência do serviço quando este já se houver
            concluído;
          </p>

          <p>
            As operadoras anteriormente citadas e a FS não se responsabilizam
            pela conduta de seus USUÁRIOS no uso do serviço ou aplicativo, nem
            por conteúdos divulgados por terceiros.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI, TIM e a FS não se responsabilizam por
            erros na aplicação ou no serviço, omissões, interrupções, perdas de
            dados e/ou informações trafegadas na rede, através do aplicativo ou
            não, defeitos, atrasos de envio de dados ou mensagens, falhas de
            transmissão e/ou comunicação, avaria e/ou danos em equipamentos dos
            USUÁRIOS, acesso não autorizado, problemas de rede,
            congestionamento, roubo de dados ou informações pessoais, ou
            qualquer outra ocorrência ou dano, qualquer que seja, relacionada ao
            uso do aplicativo e acesso ao Serviço.
          </p>

          <p>
            O Serviço prestado através do website e/ou do aplicativo e qualquer
            conteúdo destes se apresenta tal qual disponibilizado ao USUÁRIO,
            não sendo as operadoras ou a FS responsáveis por quaisquer
            declarações de garantia, implícitas ou explicitas, que não tenham
            sido prestadas direta e expressamente. Ou seja, as operadoras VIVO,
            CLARO, OI, TIM e a FS não garantem nenhuma informação ou declaração
            prestada por terceiros sobre o serviço, suas qualidades,
            características, resultados eventualmente prometidos ou expectativas
            de uso, que não as constantes do presente documento.
          </p>

          <p>
            As operadoras citadas e a FS não fornecem nenhuma garantia,
            declaração ou aval de que o uso dos seus serviços será ininterrupto
            ou que estará livre de erros. O USUÁRIO concorda que,
            periodicamente, sua operadora ou a FS poderá remover o serviço por
            períodos indefinidos de tempo ou ainda, cancelar o serviço a
            qualquer momento, sem ter de lhe avisar previamente.
          </p>

          <p>
            O USUÁRIO concorda expressamente que seu uso ou incapacidade de uso
            dos serviços corre por sua conta e risco. Todos os conteúdos e
            serviços são fornecidos 'no estado em que se encontram' e 'conforme
            disponíveis' para seu uso, sem garantias de qualquer tipo.
          </p>

          <p>
            Em nenhuma hipótese as operadoras VIVO, CLARO, OI, TIM e a FS, suas
            matrizes, subsidiárias, afiliadas, conselheiros, sócios, serão
            responsáveis por quaisquer danos diretos, indiretos, incidentais,
            punitivos, especiais ou decorrentes do seu uso ou de qualquer
            reclamação relacionada ao seu uso dos serviços, incluindo, mas, não
            se limitando a erros e/ou omissões em qualquer conteúdo.
          </p>

          <p>
            O USUÁRIO reconhece e concorda que o envio de informações
            relacionadas aos serviços é feito por sua conta e risco. Não
            obstante, as operadoras anteriormente citadas e a FS envidarão
            razoáveis esforços para proteger essas informações, todavia, não
            assume qualquer responsabilidade perante o USUÁRIO pela perda ou
            responsabilidade de qualquer forma relacionada a essas informações,
            uma vez que não declaram, tampouco, garantem que o serviço estará
            livre de perdas, corrupção, ataque, vírus, interferência, acesso
            ilegal ou outra invasão de segurança.
          </p>

          <p>
            O USUÁRIO concorda que deverá tomar todas as medidas necessárias
            para se prevenir quanto a infecção por vírus e outras ameaças
            virtuais, de modo a minimizar os riscos de invasão de privacidade no
            uso do aplicativo/serviço, pois será o único responsável pelos danos
            ocorridos, não remanescendo nenhuma responsabilidade às operadoras
            citadas e à FS.
          </p>

          <p>
            Não obstante, qualquer outra disposição do presente Termo, as
            operadoras, bem como a FS, reservam-se o direito de alterar,
            suspender, remover ou desabilitar o acesso a quaisquer, conteúdos ou
            outros materiais que sejam oferecidos pelo serviço, ou mesmo o
            próprio aplicativo, a qualquer tempo, sem aviso prévio. Em hipótese
            alguma as operadoras citadas e a FS serão responsáveis pela remoção
            ou desativação do acesso a qualquer um dos conteúdos ou materiais
            disponibilizados. Essas podem, igualmente, impor limites à
            utilização ou ao acesso a determinadas funcionalidades ou partes do
            Serviço, em qualquer caso e sem aviso prévio ou responsabilidade.
          </p>

          <p>
            <strong>MARCAS</strong>
          </p>

          <p>
            A marca “FS Ensina ” e outras nominativas ou figurativas, logotipos,
            desenhos, ícones etc. apresentados no acesso ao aplicativo/serviço
            são marcas registradas pela FS, por empresas do grupo ou por
            parceiros e, portanto, o uso não autorizado constitui violação as
            leis brasileiras e estrangeiras aplicáveis, não podendo o USUÁRIO se
            utilizar destas, no todo ou em parte, nem as copiar, ou imitá-las
            sem a expressa autorização, por escrito, da empresa detentora dos
            direitos sobre a marca.
          </p>

          <p>
            <strong>CONTEÚDOS DE TERCEIROS E SITES WEB</strong>
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            pelas ofertas publicitárias e pelo conteúdo disponível nos banners,
            pop-ups, janelas e outras formas de publicidade, disponibilizadas no
            aplicativo ou site, caso tais ofertas, publicações etc. sejam feitas
            por terceiros.
          </p>

          <p>
            O USUÁRIO concorda em usar o Serviço por sua própria conta e risco e
            que sua operadora não terá nenhuma responsabilidade perante o
            USUÁRIO por conteúdos que possam ser considerados inadequados,
            ficando sob a responsabilidade do USUÁRIO a fiscalização do conteúdo
            acessado pelos menores de idade indicados.
          </p>

          <p>
            As operadoras anteriormente citadas e a FS não verificam nenhuma
            informação trafegada ou enviada por terceiros e, portanto, não
            possuem qualquer responsabilidade sobre o conteúdo das informações e
            ofertas realizadas, bem como não se responsabiliza por quaisquer
            opiniões emitidas, ou conteúdo ofensivo de qualquer natureza, sua
            confiabilidade ou privacidade no acesso a sites de terceiros,
            através de links trafegados através do serviço.
          </p>

          <p>
            A inclusão de qualquer conteúdo de terceiros, a criação de links de
            acesso a sites de terceiros, ainda que autorizados, não significa
            que alguma das operadoras ou a própria FS tenha conhecimento de seu
            conteúdo e nem que exista qualquer vínculo com as operadoras VIVO,
            CLARO, OI e TIM e a FS, ou mesmo concordância com o que está sendo
            divulgado ou ofertado através deste, sendo mera locação de espaço
            publicitário.
          </p>

          <p>
            A responsabilidade pelo acesso, download e uso/acesso a qualquer
            site de terceiros é do USUÁRIO, exclusivamente, devendo este acessar
            as políticas destes sites, uma vez que os conteúdos de sites de
            terceiros estão sujeitos às políticas do FS Ensina.
          </p>

          <p>
            <strong>
              Sem prejuízo do disposto acima, o USUÁRIO, assinante do FS Ensina,
              conforme disposições deste Termo, incluindo-se, sem a isso
              limitar, do Preço e Tributação previstas abaixo, ganharão, por
              mera liberalidade da FS, o acesso gratuito ao conteúdo do
              Instituto Latino-Americano de Empreendedorismo, Inovação e
              Desenvolvimento, entidade de direito privado constituída sob a
              forma de associação, inscrita no CNPJ sob o no 33.638.027/0001,
              conforme acordado com esse último. O USUÁRIO está ciente e
              concorda que tal disponibilização poderá ser cessada, a qualquer
              tempo e a exclusivo critério da FS, que inclusive não terá
              qualquer responsabilidade pelo conteúdo de terceiros.
            </strong>
          </p>

          <p>
            <strong>PREÇOS E TRIBUTAÇÃO</strong>
          </p>

          <p>
            O FS Ensina será ofertado em duas plataformas, smartphone e desktop,
            através de uma assinatura semanal e/ou uma assinatura mensal e/ou
            outra oferta disponível para o público, dependendo da operadora ou
            do canal de aquisição, com possibilidade de previsão de renovação
            automática dependendo do canal, que disponibilizará aos assinantes
            um “log in” e senha para acesso integral a todos os conteúdos
            oferecidos pelo FS Ensina.
          </p>

          <p>
            A assinatura poderá ser realizada através do aplicativo, do website
            ou pelos diferentes canais de venda.
          </p>

          <p>
            Para uso do FS Ensina, ou seja, download do aplicativo ou acesso
            online, será cobrada a tarifa vigente de tráfego de dados da
            respectiva operadora ou outra conexão de internet.
          </p>

          <p>
            O USUÁRIO poderá ser tributado, quando da estipulação de valores
            para uso do serviço, dependendo da Lei Tributária em vigor no
            Brasil, Estado ou jurisdição local na qual o USUÁRIO resida. Neste
            caso, o USUÁRIO será responsável pelo pagamento de qualquer tributo
            incidente, em adição ao preço.
          </p>

          <p>
            O uso do serviço de telecomunicações, incluindo serviços de valor
            agregado, fora do aplicativo, terá sua tarifação aplicável
            normalmente, conforme tarifas vigentes, de acordo com o serviço
            utilizado de sua operadora.
          </p>

          <p>
            <strong>CONFLITOS COM OUTROS USUÁRIOS</strong>
          </p>

          <p>
            O USUÁRIO é o único responsável pela interação com outros usuários
            do aplicativo FS Ensina, não sendo as operadoras VIVO, CLARO, OI e
            TIM e a FS responsáveis por qualquer conflito gerado entre o USUÁRIO
            e terceiros.
          </p>

          <p>
            <strong>PRIVACIDADE</strong>
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não divulgam a terceiros
            as informações pessoais recebidas de USUÁRIOS e/ou visitantes dos
            sites relacionados ao serviço. Podem ser consideradas como
            informações pessoais todas aquelas fornecidas pelo visitante através
            dos sites relacionados ao serviço (nome, endereço, telefone, fax e
            e-mail). Tais informações poderão ser utilizadas somente para ações
            de marketing direto das próprias operadoras ou caso tenham sido
            divulgadas pelo próprio USUÁRIO.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS poderão fazer uso de
            informações genéricas, tais como idade, sexo, profissão,
            naturalidade e nacionalidade para melhorar a comunicação com os
            USUÁRIOS e/ou visitantes do site. Tais informações poderão ser
            obtidas através de pesquisas online. Da mesma forma que as
            informações de caráter pessoal, as informações de caráter genérico
            não serão divulgadas a terceiros sem consentimento do USUÁRIO e/ou
            visitante.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não utilizam os dados
            gravados nos aparelhos móveis de seus USUÁRIOS, tais como: número de
            serial, ESN e outros. A operadora poderá requisitar ao USUÁRIO que
            informe o número de sua linha telefônica móvel para fins de cadastro
            nos serviços oferecidos nos sites relacionados ao serviço ou para
            registro do aplicativo.
          </p>

          <p>
            O FS Ensina não utiliza cookies espiões, que permaneçam armazenados
            no computador do USUÁRIO e/ou visitante para obter informações
            confidenciais. O aplicativo poderá utilizar cookies que indiquem a
            região em que o USUÁRIO ou visitante resida, para lhe direcionar
            para a área dos sites relativos à região respectiva do USUÁRIO.
          </p>

          <p>
            O FS Ensina não permite o uso de cookies de terceiros nos sites
            relacionados ao serviço para quaisquer fins.
          </p>

          <p>
            Nenhuma pessoa que entre em contato com os USUÁRIOS está autorizada
            a solicitar-lhes a senha de atendimento/”log in” particular. A senha
            de atendimento só será solicitada para confirmação da identidade dos
            USUÁRIOS quando estes entrarem em contato com a Central de
            Atendimento do FS Ensina.
          </p>

          <p>
            Todos os dados que os USUÁRIOS e/ou visitantes fornecerem nos sites
            relacionados ao serviço através de protocolos de segurança estarão
            protegidos, afim de assegurar sua privacidade e autenticidade, à
            medida que trafegam pela Internet. Todavia, os sites relacionados ao
            serviço não asseguram a plena segurança das informações e/ou dados
            enviados pelos USUÁRIOS e/ou visitantes caso ocorram eventos que
            ocasionem casos fortuitos ou motivos de força maior, razão pela qual
            os USUÁRIOS e/ou visitantes expressamente renunciam a qualquer
            reivindicação contra os sites relacionados ao serviço em decorrência
            de perda de dados eventualmente sofridos em virtude de acessos não
            autorizados e/ou quebra de segurança dos sistemas que mantém os
            sites relacionados ao serviço.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            pelo envio de mensagens de texto para aparelhos celulares dos
            USUÁRIOS originadas de parceiros ou celulares de outros clientes,
            tendo em vista tratar-se de canal livre para contato com os
            USUÁRIOS. As operadoras citadas e a FS tomam medidas de segurança e
            precaução para evitar o envio indiscriminado de mensagens de texto
            aos USUÁRIOS.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS não se responsabilizam
            pelo envio de e-mails falsos ('hoaxes') com promessas, ofertas e
            formulários, nem mensagens neste contexto. Todas as comunicações do
            FS Ensina serão feitas com links para os sites relacionados ao
            serviço.
          </p>

          <p>
            <strong>CANCELAMENTO DO SERVIÇO</strong>
          </p>

          <p>
            Caso o USUÁRIO não cumpra, qualquer uma das disposições do presente
            Termo, incluindo, entre outros, a falta de pagamento das taxas
            devidas, a falha em fornecer ao FS Ensina dados de registro exatos e
            completos, a falha em proteger informações de sua conta ou a
            violação das regras de utilização ou de qualquer licença de
            software, ou a quebra ou violação de direitos de terceiros, a
            respectiva operadora e a FS, a seu exclusivo critério, sem aviso ao
            USUÁRIO , poderá: (i) cancelar a conta do USUÁRIO, permanecendo este
            como responsável por todos os valores devidos sob sua respectiva até
            data de cancelamento; e/ou (ii) rescindir a licença do software;
            e/ou (iii) impedir o acesso ao serviço (ou a qualquer parte do
            mesmo); e/ou (iv) modificar, suspender ou descontinuar o serviço (ou
            qualquer parte ou conteúdo do mesmo) a qualquer momento, com ou sem
            aviso prévio ao USUÁRIO, sendo que as operadoras citadas e a FS não
            serão responsáveis perante o USUÁRIO ou terceiros caso exerça esses
            direitos.
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS reservam-se o direito de
            retirar/cancelar o acesso ao ambiente Internet, integral ou
            parcialmente, o aplicativo FS Ensina a qualquer momento,
            independentemente de notificação prévia. Um aviso de término dos
            serviços poderá ser disponibilizado na página principal (homepage)
            do site caso ocorra. As operadoras poderão enviar, a seu exclusivo
            critério, uma notificação de cancelamento via mensagem de texto na
            linha do USUÁRIO. As operadoras VIVO, CLARO, OI e TIM e a FS não se
            responsabilizam por falhas em eventual notificação do cancelamento
            ao USUÁRIO.
          </p>

          <p>
            A qualquer momento, o USUÁRIO poderá solicitar o cancelamento de sua
            assinatura, sem ônus. O cancelamento será efetuado quando o cliente
            enviar “SAIR”. Neste momento, o conteúdo do FS Ensina tornar-se
            restrito e seu acesso é somente ao conteúdo aberto e recursos
            gratuitos.
          </p>

          <p>
            O cancelamento pode ser feito a qualquer momento, respeitando às
            políticas internas das operadoras e da FS.
          </p>

          <p>
            <strong>DISPOSIÇÕES GERAIS</strong>
          </p>

          <p>
            As operadoras VIVO, CLARO, OI e TIM e a FS reserva-se o direito de
            tomar as medidas que considere razoavelmente necessárias ou
            apropriadas para fazer cumprir e/ou garantir o cumprimento de
            qualquer parte do presente Termo (incluindo, sem limitação, o
            direito de as operadoras de cooperar em qualquer processo legal
            relativo ao uso do Serviço, e/ou em uma reclamação de terceiros no
            sentido de que o uso por você do Serviço é ilegal e/ou viola os
            direitos de tais terceiros).
          </p>

          <p>
            O USUÁRIO concorda que as operadoras possuem direito, sem
            responsabilidade perante este, de divulgar quaisquer dados de
            registro e/ou informação da conta do USUÁRIO às autoridades
            responsáveis pelo cumprimento da lei, a funcionários governamentais
            e/ou a terceiros, na medida que considerem razoavelmente necessárias
            ou adequadas para fazer cumprir e/ou verificar o cumprimento de
            qualquer parte do presente Termo.
          </p>

          <p>
            Este Termo constitui o acordo integral entre o USUÁRIO e sua
            respectiva operadora e regula o uso do serviço por parte do USUÁRIO,
            prevalecendo sobre quaisquer outros acordos anteriores, sendo que o
            USUÁRIO estará sujeito ainda as regras e normas do FS Ensina, por
            meio das operadoras VIVO, CLARO, OI e TIM e da FS ou sujeito a
            termos e condições adicionais que possam ser aplicáveis quando o
            USUÁRIO utilizar serviços de afiliadas, conteúdo de terceiros ou
            software de terceiros.
          </p>

          <p>
            Se qualquer parte deste Termo for considerada inválida ou
            inexequível, essa parte deverá ser interpretada de acordo com a lei
            aplicável de forma a refletir, o mais próximo possível, as intenções
            originais das partes, sendo que as condições remanescentes do Termo
            permanecerão em plena força e vigor. Se as operadoras VIVO, CLARO,
            OI e TIM ou a FS deixarem de exercer qualquer direito ou aplicar as
            disposições deste Termo, tal fato não constituirá uma renúncia a
            essa disposição ou qualquer outra disposição deste Termo. Se
            qualquer disposição deste Termo for considerada por um juízo com
            jurisdição competente como inválida, as outras disposições
            permanecerão em plena força e efeito. As operadoras citadas, bem
            como a FS, não são responsáveis pelo descumprimento de quaisquer
            obrigações em decorrência de causas fora do seu controle.
          </p>

          <p>
            O presente Termo poderá ser substituído ou alterado, em suas
            condições ou mecânica, a qualquer tempo, a critério único e
            exclusivo das operadoras VIVO, CLARO, OI e TIM e da FS, sem prévio
            aviso, e estas alterações alcançarão adesões passadas ao serviço.
          </p>

          <p>
            A conclusão do download do aplicativo FS Ensina e início do uso dos
            serviços neste implica na aceitação total das condições e normas
            descritas neste Termo, das políticas e procedimentos das operadoras
            VIVO, CLARO, OI e TIM e da FS.
          </p>

          <p>
            Na hipótese de ocorrência de fato superveniente, que exceda à
            vontade das operadoras anteriormente citadas e da FS, tal como,
            majoração de tributos inerentes à atividade de telecomunicações ou
            qualquer outro que incida sobre a atividade comercial por ela
            exercida, alteração dos preços de tarifas telefônicas por Autoridade
            Federal competente, mudança na política econômica, ou qualquer fato
            que torne excessivamente onerosa o valor do serviço, poderão as
            operadoras rever os valores cobrados no tráfego de dados ou
            eventuais outros valores cobrados pelo aplicativo ou serviço.
          </p>

          <p>
            Os casos omissos serão decididos a exclusivo critério das
            operadoras, sendo sua decisão soberana e irrecorrível.
          </p>

          <p>
            Qualquer decisão das operadoras em não exigir o cumprimento dos
            itens do presente Termo em um dado momento, não constitui novação ou
            renúncia, podendo as operadoras citadas exigir seu cumprimento a
            qualquer tempo.
          </p>

          <p>
            <strong>FORO</strong>
          </p>

          <p>
            O presente Termo obriga o USUÁRIO, bem como seus herdeiros e/ou
            sucessores, sendo eleito o foro de São Paulo/SP como o único
            competente para dirimir qualquer questão oriunda deste Termo, com
            exclusão a qualquer outro, por mais privilegiado que seja ou venha a
            ser.
          </p>
        </section>
      </section>
    </Layout>
  );
};

export default TermsConditions;
