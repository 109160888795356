import { makeRequest } from "../../../services/Graphql";
import {
  GET_CERTIFICATES,
  GET_CERTIFICATE_BY_COURSE,
  GET_PUBLIC_CERTIFICATE,
} from "./queries";

export async function getCertificates() {
  const response = await makeRequest(GET_CERTIFICATES, {});
  console.log("[success-get-certificates]: ", response.certificates);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    certificates: response.certificates,
  };
}

export async function getCertificateUserByCourse(courseId) {
  const response = await makeRequest(GET_CERTIFICATE_BY_COURSE, {
    courseId,
  });

  console.log("[success-getCertificateUserByCourse]: ", response.certificates);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    certificates: response.certificates,
  };
}

export async function getExternalCertificate(id) {
  const response = await makeRequest(
    GET_PUBLIC_CERTIFICATE,
    {
      id,
    },
    false,
    false
  );

  console.log("[success-getExternalCertificate]: ", response);

  if (response.status === false) {
    return {
      status: response.status,
      message: response.message,
    };
  }

  return {
    status: true,
    certificates: response.certificates,
  };
}
