import styled from "styled-components";

export const Header = styled.header`
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  grid-area: header;
  padding: 20px 0;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  width: 100vw;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0 14px;
  }
`;

export const Content = styled.div``;
