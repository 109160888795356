import React from "react";
import {
  URL_DOWNLOAD_APP_ANDROID,
  URL_DOWNLOAD_APP_IOS,
} from "../../../../../constants";

import IMAGE2 from "./images/playstore-brazil@3x.png";
import IMAGE3 from "./images/appstore-brazil@3x.png";

export default (props) => {
  return (
    <div>
      <p
        style={{
          lineHeight: 1,
          color: "#37b3a7",
          textAlign: "center",
          marginBottom: 20,
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        Sua conta foi criada <br /> e ativada com sucesso!
      </p>
      <p style={{ marginBottom: 20 }}>
        Agora é só baixar o <strong>Ensina</strong> e aproveitar todos os cursos
        que temos disponíveis pra você!
        <br />
        Clique abaixo para fazer o download.
      </p>
      <section className="logo-local-app d-flex">
        <figure>
          <a
            href={URL_DOWNLOAD_APP_ANDROID}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width="180" src={IMAGE2} alt="Download Android" />
          </a>
        </figure>
        <figure>
          <a
            href={URL_DOWNLOAD_APP_IOS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width="180" src={IMAGE3} alt="Download Apple" />
          </a>
        </figure>
      </section>
    </div>
  );
};
