import React from "react";
import * as Styled from "./styled";
import IMAGE1 from "./images/button-apple-store.svg";
import IMAGE2 from "./images/button-google-play.svg";

import {
  URL_DOWNLOAD_APP_ANDROID,
  URL_DOWNLOAD_APP_IOS,
} from "../../../../constants";

const system = {
  ios: {
    title: "Disponivel na",
    market: "Apple Store",
    href: URL_DOWNLOAD_APP_IOS,
    image: IMAGE1,
  },
  android: {
    title: "Disponivel no",
    market: "Google Play",
    href: URL_DOWNLOAD_APP_ANDROID,
    image: IMAGE2,
  },
};

const DownloadButton = (props) => (
  <Styled.Card href={system[props.system].href} target="_blank">
    <img src={system[props.system].image} alt={system[props.system].title} />
  </Styled.Card>
);

export default DownloadButton;
