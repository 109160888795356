import React from "react";
import { PROJECT_NAME } from "../../../constants";
import { maskToTime, maskDate } from "../../../utils";
import IMAGE1 from "./images/logo-ensina-by-fs-black.png";
import IMAGE3 from "./images/logo-fs.png";
import * as Styled from "./styled";

const Certificate = ({
  name,
  surName,
  courseName,
  completionDate,
  courseDuration,
  certificateNumber,
}) => {
  return (
    <Styled.Content>
      <Styled.Image4 />
      <Styled.Image2 src={IMAGE1} alt={PROJECT_NAME} />
      <Styled.CertificateTitle>CERTIFICADO</Styled.CertificateTitle>
      <Styled.Paragraph>O Ensina by FS reconhece que</Styled.Paragraph>
      <Styled.CertificateName>
        {name} {surName}
      </Styled.CertificateName>
      <Styled.Paragraph>
        concluiu com sucesso o curso de <strong>{courseName}</strong>, no{" "}
        {PROJECT_NAME}, <br /> em {maskDate(completionDate)}, totalizando{" "}
        {maskToTime(courseDuration)}.
      </Styled.Paragraph>
      <Styled.Paragraph>Certificado por</Styled.Paragraph>
      <Styled.Image3 src={IMAGE3} alt="logo" />
      <p>
        Código do certificado <br />
        {certificateNumber}
      </p>
      <Styled.Image4 />
    </Styled.Content>
  );
};

export default Certificate;
