import { GraphQLClient } from "graphql-request";
import { getNewToken } from "../Firebase";
import { URL_GRAPH_QL } from "../../constants";
import { validateToken, sleep } from "../../utils";
import { translateMessage, ERRORS_MESSAGE } from "../../utils/TranslateMessage";

function makeHeaders(idToken) {
  return {
    Authorization: idToken || validateToken("ensina:sessionToken"),
  };
}

export async function makeRequest(
  query,
  payload = {},
  useToken = true,
  idToken = null
) {
  let headers = {};
  let remainingRetries = 3;
  let waitTime = [300, 100, 50];

  while (remainingRetries) {
    try {
      if (useToken) {
        headers = makeHeaders(idToken);
      }

      const client = new GraphQLClient(URL_GRAPH_QL);
      const response = await client.request(query, payload, headers);
      return response;
    } catch (error) {
      const errorMessage = error.response?.errors?.[0]?.message;

      if (ERRORS_MESSAGE.TOKEN.includes(errorMessage)) {
        const refreshToken = localStorage.getItem("ensina:refreshToken");

        const response = await getNewToken(refreshToken);

        localStorage.setItem("ensina:sessionToken", response.idToken);
        localStorage.setItem("ensina:refreshToken", response.refreshToken);
      } else if (ERRORS_MESSAGE.NOT_FOUND.includes(errorMessage)) {
        return {
          status: false,
          message: translateMessage(errorMessage),
        };
      }

      remainingRetries -= 1;
      await sleep(waitTime[remainingRetries]);
    }
  }

  return {
    status: false,
    message: translateMessage(""),
  };
}
