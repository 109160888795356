import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  margin: 20px;
`;

export const Content = styled.div`
  margin-top: 12px;
  text-align: center;
  margin-bottom: 16px;

  button {
    margin-top: 15px;
    width: 100%;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    margin-right: 8px;
  }

  p {
    font-family: var(--font-lexend-medium);
    color: var(--color-text);
    font-size: 12px;
  }
`;
