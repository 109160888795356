import { gql } from "graphql-request";

export const GET_PROFILE_BY_EMAIL = gql`
  query {
    profiles {
      id
      name
      surname
      email
      imageUrl
      phoneNumber
      campaign {
        skin {
          color
          logoUrl
          title
          transparentLogoUrl
        }
      }
    }
  }
`;

export const UPDATE_COURSE_PROGRESS = gql`
  mutation (
    $courseId: ID!
    $chapterId: ID!
    $lessonId: ID
    $homeworkId: ID
    $currentTime: Int
    $finished: Boolean!
  ) {
    updateCourseProgress(
      data: {
        courseId: $courseId
        chapterId: $chapterId
        lessonId: $lessonId
        homeworkId: $homeworkId
        currentTime: $currentTime
        finished: $finished
      }
    ) {
      id
    }
  }
`;

export const GET_USER_COURSE_PROGRESS = gql`
  query ($courseId: ID!) {
    courseProgress(courseId: $courseId) {
      finished
      chapters {
        id
        lessons {
          id
          finished
        }
        homeworks {
          id
          finished
        }
      }
    }
  }
`;

export const GET_ALL_USER_COURSE_PROGRESS = gql`
  query {
    courseProgress {
      id
      finished
      finishedAt
      course {
        id
        title
        imageUrl
        duration
        categories {
          id
          title
        }
      }
      profile {
        name
        surname
      }
    }
  }
`;

export const GET_ALL_USER_COURSE_FAVORITE = gql`
  query {
    favorites {
      courses {
        id
        title
        imageUrl
        duration
        categories {
          id
          title
        }
      }
    }
  }
`;

export const SAVE_FAVORITE_USER_COURSE = gql`
  mutation ($courseId: ID!) {
    addCourseToFavorites(courseId: $courseId) {
      courses {
        title
      }
    }
  }
`;

export const REMOVE_FAVORITE_USER_COURSE = gql`
  mutation ($courseId: ID!) {
    removeCourseFromFavorites(courseId: $courseId) {
      courses {
        title
      }
    }
  }
`;

export const GET_USER_COURSE_FAVORITE_BY_COURSE = gql`
  query ($courseId: ID!) {
    favorites(courseId: $courseId) {
      courses {
        title
      }
    }
  }
`;

export const SEND_CREATE_PROFILE = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $acceptedTermsOfUse: Boolean
    $imageUrl: String
    $phone: String
  ) {
    createProfile(
      data: {
        name: $firstName
        surname: $lastName
        email: $email
        acceptedTermsOfUse: $acceptedTermsOfUse
        imageUrl: $imageUrl
        phoneNumber: $phone
      }
    ) {
      id
    }
  }
`;

export const GET_ALL_USER_ACHIEVEMENTS = gql`
  query {
    profiles {
      achievements {
        id
        title
        description
        imageUrl
        imageUrlDisabled
      }
    }
  }
`;
