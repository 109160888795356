import styled from "styled-components";

export const Tabs = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: hsla(0, 0%, 94%, 1);
  border-radius: 6px;
  padding: 10px 0;
  margin: 30px 0;
`;

export const Button = styled.button`
  flex: 1;
  margin: 0 10px;
  font-family: var(--font-lexend-medium, Verdana, Geneva, Tahoma);
  color: var(--color-text, hsla(0, 0%, 100%, 1));
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: hsla(0, 0%, 100%, 1) !important;
  }

  &.active {
    background-color: ${(props) => props.theme.color};
    color: #fff;
  }
`;
