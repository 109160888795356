import React from "react";
import * as Styled from "./styled";

const Modal = ({ children, header, isClose }) => {
  return (
    <Styled.Modal>
      <Styled.Content>
        <Styled.Header display={header ? false : true}>
          <Styled.Span onClick={isClose}>
            <i className="fa fa-times" />
          </Styled.Span>
        </Styled.Header>
        <Styled.Container>{children}</Styled.Container>
      </Styled.Content>
    </Styled.Modal>
  );
};

export default Modal;
