import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../Button/Primary";

import * as Styled from "./styled";

export default ({ question, options, handleChange, handleSubmit }) => {
  const history = useHistory();

  return (
    <Styled.Container>
      <form onSubmit={handleSubmit}>
        <p>{question}</p>
        {options.map((response, key) => (
          <div key={key} style={{ marginTop: 15, marginBottom: 15 }}>
            <input
              value={response}
              onChange={handleChange}
              type="radio"
              name="answer"
              required
              disabled={false}
            />{" "}
            {response}
            <br />
          </div>
        ))}
        <Styled.Paragraph>
          Precisa de ajuda?{" "}
          <Styled.ButtonBack onClick={() => history.goBack()}>
            Volte para o capitulo
          </Styled.ButtonBack>
        </Styled.Paragraph>
        <Styled.Button>
          <Button width>Confirmar</Button>
        </Styled.Button>
      </form>
    </Styled.Container>
  );
};
