import styled from "styled-components";

export const Certificates = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
`;

export const Container = styled.div`
  background-color: #fefefe;
  margin: 50px auto;
  padding: 14px;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
  width: 50%;
  border-radius: 0px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  border-radius: 2px;
  border-bottom: none;

  span {
    float: right;
  }
`;
